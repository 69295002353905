import React, { useState, useEffect } from 'react';
import { Row, Col, Form, PageHeader, Divider, Icon } from 'antd/lib';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Maps } from '../Maps/Maps';
import {
  StyledImage,
  StyledButton,
  StyledButtonsCancelSubmit,
  StyledAddButton,
  StyledRemoveButton,
  StyledAddCircleButton,
  StyledUndoButton,
  StyledCommonImage,
  StyledButtonsSaveDraAnother
} from './Maps.styles';

import subtractPolygonDrawing from '../../public/subtractPolygonDrawing.svg';
import subtractCircleDrawing from '../../public/subtractCircleDrawing.svg';
import { PageLayout } from '../Layout/page-layout';
import { ErrorModal } from '../common/Modal/ErrorModal';
import { getAreaByUnit, getUnitText, convertAcreToHectare } from '../../utils/getAreaByUnit';
import { getOrgDetailsByOrgId } from '../../selectors/organizationSelectors';
import { SvgIcon } from '../common/IconComponent';
import { ConfirmModal } from '../common/ConfirmModal';

import {
  FarmContainer,
  StyledHeader,
  StyledDrawingTools,
  StyledAdjustedSize,
  StyledInputFildName
} from '../../components/landingFarm/CreateEditFarmComponent.style';
import { getAreaInHectareByGeoJson } from '../../utils/getAreaInAcreByGeoJson';
import { getLocationFromBrowser } from '../../utils/location';
import { iff } from '../../utils/iff';
import { getFieldsByPropertyId, deleteFieldByFieldId, editFieldById, createFieldByPropIdPromisify } from '../../actions/fields';
import { getPropertyDetailsById, fetchOrgs } from '../../actions';
import { AppConstants } from '../../utils/app.constants';
import { getErrorMessageByCode } from '../../utils/getErrorMessageByCode';
import { URL_CONSTANTS } from '../../utils/history';
import { Loader } from '../Loader/Loader';
import { AmplitudeInstance } from '../../utils/amplitude';
import { ConfirmationModal } from '../common/Modal/ConfirmationModal';


const DEFAULT_LAT_LONG = 10;
let unit_system;
const DrawPolygon = (props) => {
  const [lat, setLat] = useState(DEFAULT_LAT_LONG);
  const [long, setLong] = useState(DEFAULT_LAT_LONG);
  const [isDrawcircle, setIsDrawcircle] = useState({ circle: false });
  const [isDrawPolygon, setIsDrawPolygon] = useState({ polygon: false });
  const [isDrawStart, setIsDrawStart] = useState(false);
  const [clearDrawedElement, setClearDrawedElement] = useState({ polygon: false, circle: false });
  const [fieldName, setFieldName] = useState('');
  const [fieldSize, setFieldSize] = useState();
  const [adjustedfieldSize, setAdjustedfieldSize] = useState();
  const [property, setProperty] = useState({});
  const [geometry, setGeometry] = useState({});
  const [fields, setFields] = useState([]);
  const [disableDrawPolygonAndCircle, setDisableDrawPolygonAndCircle] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [showFieldError, setShowFieldError] = useState({ show: false, message: null });
  const [isLoading, setIsLoading] = useState(false);
  const [isElementDrawed, setIsElementDrawed] = useState(false);
  const [focusBounds, setFocusBounds] = useState(null);

  const [drawGeometryBackupObj, setDrawGeometryBackupObj] = useState({
    draw: false,
    geometry: null
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isDrawNext, setIsDrawNext] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isSubtractPolygon, setIsSubtractPolygon] = useState({ polygon: false });
  const [isSubtractCircle, setIsSubtractCircle] = useState({ circle: false });
  const [isDeleteLastVertex, setIsDeleteLastVertex] = useState(null)
  const [isDeleteLastDrawnPolygon, setIsDeleteLastDrawnPolygon] = useState(null)
  const [visible, setVisible] = useState(false);


  let { fieldId } = props.match.params;
  const { t } = useTranslation();

  useEffect(() => {
    unit_system = props.currentOrg.unit_system;
    return () => {
      setIsDeleteLastVertex(null)
      setIsDeleteLastDrawnPolygon(null)
    }
  }, [props.currentOrg]);

  const getMapBounds = (obj) => {
    return L.geoJson({ geometry: obj, type: 'Feature' }).getBounds();
  };

  useEffect(() => {
    const propId = props.match.params.farmId;
    const getPropDetails = async () => {
      const propertyRes = await getPropertyDetailsById(propId);
      const coordinates = get(propertyRes.data, 'reference_point.coordinates');
      if (isEmpty(coordinates)) {
        (async () => {
          try {
            const location = await getLocationFromBrowser();
            setLong(location.longitude);
            setLat(location.latitude);
          } catch (err) {
            console.error('err', err);
          }
        })();
      } else {
        setLat(coordinates[0] || DEFAULT_LAT_LONG);
        setLong(coordinates[1] || DEFAULT_LAT_LONG);
      }
      setProperty(propertyRes.data);
    };
    const getFieldDetails = async (allfields) => {
      const fieldDetails = allfields.find((field) => field.id === fieldId);
      setFieldName(fieldDetails.name);
      const fieldValue = getAreaByUnit(fieldDetails.declared_area, unit_system);
      setFieldSize(fieldValue);
      setAdjustedfieldSize(fieldValue);
      const filteredGeoJsons = allfields.filter((field) => field.id !== fieldId);
      setFields(filteredGeoJsons);
      setGeometry(fieldDetails.geometry);
      setDrawGeometryBackupObj({ geometry: fieldDetails.geometry, draw: true });
      setFocusBounds(getMapBounds(fieldDetails.geometry));
      setIsElementDrawed(true);
      setDisableDrawPolygonAndCircle(true);
      setIsDataLoading(false);
    };

    const getFieldFromRouteState = async () => {
      const { existingFields, newFields, currentField } = props.location.state;
      let allFields = existingFields.concat(newFields);
      allFields = allFields
        .map((field) => {
          const area = field.adjustedSize || field.declared_area;
          const declared_area = getAreaByUnit(area, unit_system);
          return {
            ...field,
            declared_area
          };
        })
        .filter((field) => field.id !== currentField.id);

      setIsDataLoading(false);
      const fieldDetails = currentField;
      const area = fieldDetails.adjustedSize || fieldDetails.declared_area;
      const declared_area = getAreaByUnit(area, unit_system);
      setFieldName(fieldDetails.name);
      setFieldSize(declared_area);
      setAdjustedfieldSize(declared_area);
      setGeometry(fieldDetails.geometry);
      const filteredGeoJsons = allFields.filter((field) => field.id !== fieldId);
      setFields(filteredGeoJsons);
      setDrawGeometryBackupObj({ geometry: fieldDetails.geometry, draw: true });
      setFocusBounds(getMapBounds(fieldDetails.geometry));
      setIsElementDrawed(true);
      setDisableDrawPolygonAndCircle(true);
    };

    const getDetails = async () => {
      if (fieldId && fieldId === 'upload') {
        setTimeout(getFieldFromRouteState, 200);
        return;
      }
      const propertyRes = await getFieldsByPropertyId(propId);
      let allFieldsRes = propertyRes.data.content || [];
      if (fieldId) {
        getFieldDetails(allFieldsRes);
      } else {
        setIsDrawPolygon({ polygon: true });
        setIsDataLoading(false);
        setFields(allFieldsRes);
      }
    };
    props.fetchOrgs().then((response) => {
      setIsDataLoading(true);
      getDetails();
    });
    getPropDetails();
  }, [props.match]);

  const onDrawClick = (type) => {
    if (type === 'polygon') {
      setIsDrawcircle({ circle: false });
      return setIsDrawPolygon({ polygon: true });
    }
    setIsDrawPolygon({ polygon: false });
    setIsDrawcircle({ circle: true });
    setIsSubtractPolygon({ polygon: false });
  };

  const onSubtract = (type) => {
    if (type === 'polygon') {
      setIsSubtractPolygon({ polygon: true });
    } else {
      setIsSubtractCircle({ circle: true })
    }
  };

  const resetAll = (newlyAddedField) => {
    setIsDrawcircle({ circle: false });
    setIsDrawPolygon({ polygon: false });
    setIsDrawStart(false);
    setClearDrawedElement({ polygon: true, circle: true });
    setFieldName('');
    setFieldSize();
    setAdjustedfieldSize();
    setGeometry({});
    setFields([...fields, newlyAddedField]);
    setDisableDrawPolygonAndCircle(false);
    setShowConfirmModal(false);
    setIsDirty(false);
    setIsDataLoading(false);
    setShowFieldError({ show: false, message: null });
    setIsLoading(false);
    setIsElementDrawed(false);
    setDrawGeometryBackupObj({
      draw: false,
      geometry: null
    });
    setErrorMessage('');
    setIsDrawNext(false);
    setIsSave(false);
  };

  const onSaveFieldClick = async (drawAnotherValue) => {
    const area = adjustedfieldSize || fieldSize;
    let declared_area = getAreaByUnit(area, unit_system);
    if (unit_system === 'IMPERIAL') {
      declared_area = convertAcreToHectare(area);
    }
    setIsLoading(true);
    if (drawAnotherValue !== undefined && drawAnotherValue.length > 0) {
      AmplitudeInstance.onDrawAnotherInAddNewFieldUsingBoundary();
      setIsSubtractPolygon({ polygon: false });
      setIsDrawNext(true);
    } else {
      setIsSave(true);
      let shape = isDrawPolygon.polygon ? 'polygon' : isDrawcircle.circle ? 'circle' : '';
      AmplitudeInstance.onSaveInAddNewFieldUsingBoundary(shape);
    }
    try {
      if (isEmpty(fieldId)) {
        createFieldByPropIdPromisify({
          property,
          name: fieldName,
          declared_area,
          geometry
        }).then((res) => {
          if (drawAnotherValue !== undefined && drawAnotherValue.length > 0) {
            resetAll(res.data);
          } else {
            if (props.location.state && props.location.state.addNewFieldFrom === 'MapsContainer') {
              props.history.push(
                URL_CONSTANTS.LANDING_FARM({
                  orgId: props.match.params.id,
                  farmId: props.match.params.farmId
                })
              );
            } else {
              props.history.push(
                URL_CONSTANTS.ALL_FIELDS({ orgId: props.match.params.id, farmId: property.id })
              );
            }
          }
        });
      } else if (fieldId === 'upload') {
        const { newFields, currentField } = props.location.state;
        const allFields = newFields.filter((field) => field.name !== currentField.name);
        const updatedField = {
          ...currentField,
          geometry,
          declared_area,
          name: fieldName
        };
        return props.history.push({
          pathname: URL_CONSTANTS.CREATE_FIELD_SHAPE_FILE_LIST({
            orgId: props.match.params.id,
            farmId: props.match.params.farmId
          }),
          state: {
            geoJsons: [...allFields, updatedField]
          }
        });
      } else {
        await editFieldById({
          property,
          name: fieldName,
          declared_area,
          geometry,
          orgid: props.match.params.id,
          fieldId,
          farmId: props.match.params.farmId
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(getErrorMessageByCode(get(error, 'response.data.code')));
    }
  };

  const onDrawComplete = (geoJSON) => {
    setIsDrawStart(false);
    setIsDirty(true);
    const areaInHectare = getAreaInHectareByGeoJson(geoJSON);
    setDisableDrawPolygonAndCircle(true);
    setGeometry(geoJSON);
    setDrawGeometryBackupObj({ geometry: geoJSON, draw: false });
    setIsElementDrawed(true);
    const size = getAreaByUnit(areaInHectare, unit_system);
    setAdjustedfieldSize(size);
    setFieldSize(size);
  };
  const handleRedirectionForCancel = () => {
    if (isEmpty(fieldId)) {
      if (props.location.state && props.location.state.addNewFieldFrom === 'MapsContainer') {
        return props.history.push(
          URL_CONSTANTS.LANDING_FARM({
            orgId: props.match.params.id,
            farmId: props.match.params.farmId
          })
        );
      }
      return props.history.push(
        URL_CONSTANTS.CREATE_FIELDS({
          orgId: props.match.params.id,
          farmId: props.match.params.farmId
        })
      );
    }
    if (fieldId === 'upload') {
      let { newFields } = props.location.state;
      return props.history.push({
        pathname: URL_CONSTANTS.CREATE_FIELD_SHAPE_FILE_LIST({
          orgId: props.match.params.id,
          farmId: props.match.params.farmId
        }),
        state: {
          geoJsons: newFields
        }
      });
    }
    if (props.location.state && props.location.state.editFieldFrom === 'MapsContainer') {
      return props.history.push(
        URL_CONSTANTS.LANDING_FARM({
          orgId: props.match.params.id,
          farmId: props.match.params.farmId
        })
      );
    }
    props.history.push(
      URL_CONSTANTS.ALL_FIELDS({ orgId: props.match.params.id, farmId: props.match.params.farmId })
    );
  };
  const onCancelClick = () => {
    if (isDirty) {
      setShowConfirmModal(true);
    } else {
      handleRedirectionForCancel();
    }
  };

  const handleUndoClick = () => {
    setIsDrawcircle({ circle: false });
    setIsDrawPolygon({ polygon: false });
    setIsDirty(true);
    if (isDrawStart) {
      setIsDeleteLastVertex(prevValue => prevValue + 1)
      setIsDrawStart(false);
    } else if (isElementDrawed) {
      setIsDeleteLastDrawnPolygon(prevValue => prevValue + 1)
    } else if (drawGeometryBackupObj.geometry) {
      const areaInHectare = getAreaInHectareByGeoJson(drawGeometryBackupObj.geometry);
      const size = getAreaByUnit(areaInHectare, unit_system);
      setAdjustedfieldSize(size);
      setFieldSize(size);
      setIsElementDrawed(true);
      setDisableDrawPolygonAndCircle(true);
      setGeometry(drawGeometryBackupObj.geometry);
      setDrawGeometryBackupObj({ geometry: drawGeometryBackupObj.geometry, draw: true });
    }
  };

  const handleRemoveDrawing = () => {
    setIsDrawcircle({ circle: false });
    setIsDrawPolygon({ polygon: false });
    setIsDirty(true);
    setIsDrawStart(false);
    setIsElementDrawed(false);
    setDisableDrawPolygonAndCircle(false);
    setGeometry(null);
    setFieldSize(undefined);
    setAdjustedfieldSize(undefined);
    setClearDrawedElement({ polygon: true, circle: true });
    setIsSubtractPolygon({ polygon: false });
  };

  const handleDrawStart = () => {
    setIsDirty(true);
    setIsDrawStart(true);
  };

  const onDeleteClick = () => {
    setVisible(true);
  };

  const onCancelDeletePopup = () => {
    setVisible(false);
  };
  const handleDelete = async () => {
    try {
      setIsDataLoading(true);
      await deleteFieldByFieldId(fieldId);
      setIsDataLoading(false);
      return props.history.push(
        URL_CONSTANTS.LANDING_FARM({
          orgId: props.match.params.id,
          farmId: props.match.params.farmId
        })
      );
    } catch (error) {
      setIsDataLoading(false);
      setVisible(false);
      const errorMessageByCode = getErrorMessageByCode(error.response.data.code);
      setErrorMessage(errorMessageByCode);
    }
  }

  return PageLayout({
    content: (
      <div>
        <ErrorModal
          visible={!!errorMessage}
          message={errorMessage}
          okText={t('Ok')}
          onOk={() => {
            setErrorMessage('');
          }}
        />
        {iff(
          visible === true,
          <ConfirmationModal
            visible={visible}
            deleteProperty={true}
            onCancel={() => onCancelDeletePopup()}
            onDelete={() => handleDelete()}
            name={fieldName}
            title={t('Delete area')}
          />
        )}
        {iff(isDataLoading, <Loader />)}
        {iff(
          showConfirmModal === true,
          <ConfirmModal
            visible={showConfirmModal}
            title={t('Cancel Draw your fields')}
            onOk={() => {
              setShowConfirmModal(false);
              handleRedirectionForCancel();
            }}
            onCancel={() => setShowConfirmModal(false)}>
            <p>{t('All of your progress will be lost.')}</p>
            <p>{t('Are you sure you want to cancel?')}</p>
          </ConfirmModal>
        )}
        <Row>
          <Col span={8}>
            <StyledHeader>
              <Icon
                onClick={onCancelClick}
                className='back-button'
                type='left'
                style={{ fontSize: '14px' }}
              />
              <PageHeader style={{ paddingTop: '0px', color: '#2F3031' }}>
                <p data-testid="draw-field-page-title" style={{ color: 'grey', display: 'inline' }}>
                  {iff(isEmpty(fieldId), t('Create fields'), t('Edit fields'))}
                </p>
                {` • `}
                {t('Draw your fields')}
              </PageHeader>
            </StyledHeader>
            <FarmContainer>
              <Form layout={'vertical'} className='login-form'>
                <Form.Item label={t('Field name')}>
                  <StyledInputFildName
                    size='large'
                    style={{ color: 'black', marginBottom: '20px' }}
                    value={fieldName}
                    placeholder='Enter field name'
                    onChange={(event) => {
                      setIsDirty(true);
                      let value = event.target.value;
                      setFieldName(value);
                    }}
                    autoFocus
                    maxLength={AppConstants.VALIDATIONS.FIELD_NAME_MAX_LENGTH}
                    data-testid="field-name-input"
                  />
                </Form.Item>
                <Row>
                  <Col span={12}>
                    <Form.Item label={t('Field size')} data-testid="field-size-input">
                      <span style={{ marginTop: '6px', display: 'inline-block' }}>
                        {iff(
                          isUndefined(fieldSize),
                          '  --',
                          `${fieldSize} ${getUnitText(unit_system)}`
                        )}
                      </span>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={t('Adjusted size')}
                      {...(showFieldError.show && {
                        validateStatus: 'error',
                        help: t('not a valid format')
                      })}>
                      <StyledAdjustedSize
                        addonAfter={getUnitText(unit_system)}
                        placeholder='--'
                        type='text'
                        onChange={(event) => {
                          setIsDirty(true);
                          let value = event.target.value;
                          let pattern = new RegExp(/^\d{1,4}(\.\d{1,2})?$/);
                          setAdjustedfieldSize(value);
                          if (!pattern.test(value)) {
                            setShowFieldError({ show: true, message: t('not valid format') });
                          } else {
                            setShowFieldError({ show: false, message: null });
                          }
                        }}
                        value={adjustedfieldSize}
                        data-testid="adjusted-size-input"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider style={{ margin: '0px', marginBottom: '15px' }} />
                <Form.Item data-testid="drawing-tools-title" label={<StyledDrawingTools>{t('Drawing tools')}</StyledDrawingTools>}>
                  {' '}
                </Form.Item>
                <Row style={{ marginBottom: '3%' }}>
                  <Col span={12}>
                    <StyledAddButton
                      disabled={disableDrawPolygonAndCircle}
                      className={iff(
                        isDrawPolygon.polygon === true && disableDrawPolygonAndCircle === false,
                        'selected'
                      )}
                      style={{ width: 'calc(100% - 12px)' }}
                      onClick={() => {
                        onDrawClick('polygon');
                      }}
                      data-testid="draw-polygon-btn">
                      <StyledCommonImage>
                        <Icon
                          className='draw-polygon-icon'
                          component={() => <SvgIcon name='addDrawing' />}
                        />
                      </StyledCommonImage>
                      {t('Draw polygon')}
                    </StyledAddButton>
                  </Col>

                  <Col span={12}>
                    <StyledAddCircleButton
                      disabled={disableDrawPolygonAndCircle}
                      onClick={() => {
                        onDrawClick('circle');
                      }}
                      className={iff(
                        isDrawcircle.circle === true && disableDrawPolygonAndCircle === false,
                        'selected'
                      )}
                      data-testid="draw-circle-btn">
                      <StyledCommonImage>
                        <Icon
                          className='draw-circle-icon'
                          component={() => <SvgIcon name='addCircleDrawing' />}
                        />
                      </StyledCommonImage>
                      {t('Draw circle')}
                    </StyledAddCircleButton>
                  </Col>
                </Row>

                <Row style={{ marginBottom: '3%' }}>
                  <Col span={12}>
                    <StyledButton
                      disabled={!disableDrawPolygonAndCircle}
                      style={{ width: 'calc(100% - 12px)' }}
                      onClick={() => {
                        onSubtract('polygon');
                      }}
                      data-testid="substract-polygon-btn">
                      <StyledImage width='20px' height='20px' src={subtractPolygonDrawing} />
                      {t('Subtract polygon')}
                    </StyledButton>
                  </Col>

                  <Col span={12}>
                    <StyledButton
                      disabled={!disableDrawPolygonAndCircle}
                      onClick={() => {
                        onSubtract('circle');
                      }}
                      data-testid="sustract-circle-btn">
                      <StyledImage width='20px' height='20px' src={subtractCircleDrawing} />
                      {t('Subtract circle')}
                    </StyledButton>
                  </Col>
                </Row>

                <Row style={{ marginBottom: '20%' }}>
                  <Col span={12}>
                    <StyledRemoveButton
                      style={{ width: 'calc(100% - 12px)' }}
                      onClick={handleRemoveDrawing}
                      data-testid="remove-drawing-btn">
                      <StyledCommonImage>
                        <Icon
                          className='remove-icon'
                          component={() => <SvgIcon name='bin-outline' />}
                        />
                      </StyledCommonImage>
                      {t('Remove drawing')}
                    </StyledRemoveButton>
                  </Col>

                  <Col span={12}>
                    <StyledUndoButton onClick={handleUndoClick} data-testid="undo-action-btn">
                      <StyledCommonImage>
                        <Icon className='undo-icon' component={() => <SvgIcon name='undo' />} />
                      </StyledCommonImage>
                      {t('Undo last action')}
                    </StyledUndoButton>
                  </Col>
                </Row>
                <Row
                  type='flex'
                  justify='start'
                  align='bottom'
                  style={{ marginRight: '0px' }}
                  gutter={['8']}>
                  <Col span={7}>
                    <StyledButtonsCancelSubmit type='secondary' onClick={onCancelClick} data-testid="cancel-btn">
                      {t('Cancel')}
                    </StyledButtonsCancelSubmit>
                  </Col>
                  <Col span={8}>
                    <StyledButtonsSaveDraAnother
                      onClick={onSaveFieldClick}
                      loading={isSave && isLoading}
                      disabled={isEmpty(geometry) || isEmpty(fieldName) || showFieldError.show}
                      data-testid="save-btn">
                      {t('Save')}
                    </StyledButtonsSaveDraAnother>
                  </Col>
                  {iff(
                    !isEmpty(fieldId),
                    <Col span={8}>
                      <StyledButtonsSaveDraAnother
                        onClick={onDeleteClick}
                        disabled={isEmpty(geometry) || isEmpty(fieldName) || showFieldError.show}
                        data-testid="delete-btn">
                        {t('Delete')}
                      </StyledButtonsSaveDraAnother>
                    </Col>
                  )}

                  {iff(
                    isEmpty(fieldId),
                    <Col>
                      <StyledButtonsSaveDraAnother
                        type='primary'
                        onClick={() => onSaveFieldClick('drawNext')}
                        loading={isDrawNext && isLoading}
                        style={{ marginTop: '5px' }}
                        disabled={isEmpty(geometry) || isEmpty(fieldName) || showFieldError.show}
                        data-testid="draw-another-btn">
                        {t('Draw Another')}
                      </StyledButtonsSaveDraAnother>
                    </Col>
                  )}
                </Row>
              </Form>
            </FarmContainer>
          </Col>
          <Col span={16}>
            <Maps
              geoJsons={fields}
              focusBounds={focusBounds}
              hasFocusBounds={true}
              reload={true}
              isDrawcircle={isDrawcircle}
              isDrawPolygon={isDrawPolygon}
              isSubtractPolygon={isSubtractPolygon}
              isSubtractCircle={isSubtractCircle}
              onDrawComplete={onDrawComplete}
              drawGeometryBackupObj={drawGeometryBackupObj}
              clearDrawedElement={clearDrawedElement}
              focusLat={+lat}
              focusLong={+long}
              handleDrawStart={handleDrawStart}
              isDeleteLastVertex={isDeleteLastVertex}
              isDeleteLastDrawnPolygon={isDeleteLastDrawnPolygon}
              setDisableDrawPolygonAndCircle={setDisableDrawPolygonAndCircle}
              setAdjustedfieldSize={setAdjustedfieldSize}
              setFieldSize={setFieldSize}
              setClearDrawedElement={setClearDrawedElement}
              setIsElementDrawed={setIsElementDrawed}
              setGeometry={setGeometry}

            />
          </Col>
        </Row>
      </div>
    )
  });
};

DrawPolygon.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      farmId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired
    })
  }),
  history: PropTypes.object,
  fetchOrgs: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  currentOrg: getOrgDetailsByOrgId(state, { orgId: get(ownProps, 'match.params.id', null) })
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrgs: () => dispatch(fetchOrgs())
});

export const DrawPolygonComponent = connect(mapStateToProps, mapDispatchToProps)(DrawPolygon);
