import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { SkeletonCard } from './SkeletonCard';
import { Container, StyledEditButton } from './PropertiesCard.styles';
import EditIcon from '../../../public/edit-icon.svg';
import AreaIcon from '../../../public/area-icon.svg';
import NoAreaIcon from '../../../public/no-area-icon.svg';
import { iff } from '../../../utils/iff';
import { DeleteOutlined } from '@ant-design/icons';
import { getUnitText } from '../../../utils/getAreaByUnit';
import '../../../components/settings/Component.css';

const PropertiesCard = (props) => {
  const { t } = useTranslation();
  return iff(
    props.isLoading === true,
    <SkeletonCard width='310px' height='30vh' />,
    <Container onClick={() => props.onCardClick(props.id)}>
      <div
        className='top-section'>
        <div className='rectangle' />
        <DeleteOutlined data-testid={`delete-prop-${props.name.replaceAll(" ", "-")}`} className='delete_icon' onClick={(eve) => props.onDelete(eve, props)} />
        <div className='title-bar' title={props.name} data-testid={`property-${props.name.replaceAll(" ", "-")}`}>
          {props.name}{' '}
          <span className='left-caret'>{`>`}</span>
        </div>
        <div className='details'>
          <div>
            {iff(props.totalArea > 0, <img src={AreaIcon} alt="AreaIcon" />, <img src={NoAreaIcon} alt="NoAreaIcon" />)}
            <span className={iff(props.totalArea > 0, 'area-icon', 'no-area-icon')}>
              {' '}
              {`${props.totalArea} ${getUnitText(props.unit)}`}
            </span>
          </div>
        </div>
      </div>
      <StyledEditButton
        className='bottom-section'
        onClick={(event) => {
          event.stopPropagation();
          props.editCardClick(props.id);
        }}
        data-testid={`edit-prop-${props.name.replaceAll(" ", "-")}`}>
        <span className='edit-section'>
          <img src={EditIcon} alt="EditIcon" />
          {t('Edit')}
        </span>
      </StyledEditButton>
    </Container>
  );
};

PropertiesCard.propTypes = {
  totalArea: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  unit: PropTypes.string,
  onCardClick: PropTypes.func,
  editCardClick: PropTypes.func
};

PropertiesCard.defaultProps = {
  unit: 'METRIC',
  // onCardClick: () => null,
  // editCardClick: () => null,
};
export const PropertiesCardComponent = PropertiesCard;
