export const AppConstants = {
  TERM_AND_CONDITIONS: '',
  DATE_FORMATS: {
    YYYY_DASH_MM_DASH_DD_WITH_TIME: `yyyy-MM-dd'T'HH:mm:ss'Z'`,
    MMM_SPACE_YEAR: `MMM yyyy`
  },
  DATES: {
    START_DATE: '2013-07-01',
    END_DATE: '2023-05-31'
  },
  VALIDATIONS: {
    MOBILE_DIGITS_LENGTH: 20,
    MIN_MOBILE_DIGITS_LENGTH: 8,
    NAME_MAX_LENGTH: 50,
    ZIP_CODE_MAX_LENGTH: 15,
    CITY_MAX_LENGTH: 50,
    COUNTRY_MAX_LENGTH: 25,
    STATE_MAX_LENGTH: 50,
    FIELD_NAME_MAX_LENGTH: 25,
    EIN_MAX_LENGTH: 20,
    ADDRESS_MAX_LENGTH: 100
  },
  EMAIL_MAX_LENGTH: 352,
  PASSWORD_MAX_LENGTH: 50,
  ADD_FIELDS: {
    SEARCH_NAME_MAX_LENGTH: 25
  },
  WHITELISTED_EMAIL_IDS: [
    'rafaela.ramos@syngenta.com',
    'agustina.yrizarre@syngenta.com',
    'alfredo.garcia@syngenta.com',
    'facundo.gatti@syngenta.com',
    'facundo.vigneau@syngenta.com',
    'shantanu_eknath.patil@syngenta.com',
    'ashish.kumar@syngenta.com',
    'kedar.pethe@syngenta.com',
    'pedro.pellegrini@syngenta.com',
    'rafaela.ramos@mz.com',
    'facundo.gatti@mz.com',
    'alfredo.garcia@mz.com',
    'lucas.guerrero@syngenta.com',
    'lucas.guerrero@mz.com',
    'agustina.yrizarre@mz.com',
    'facundo.vigneau@mz.com',
    'expo@mz.com',
    'shantanu_eknath.patil@mz.com',
    'kedar.pethe@mz.com',
    'pedro.pellegrini@mz.com',
    'expo@nideraseeds.com'
  ],
  STAGING_CONTRACT_PLAN_ID: {
    NIDERA_PLAN_ID: 'c1cb97eb-3cd7-4530-ae72-62f9ebefb245',
    REMOTE_SENSING_PLAN_ID: 'b4a38211-4bda-4565-95a1-82ac8bba0804',
    IMAGERY_PLAN_ID: '3cbcb025-1bb3-47b5-a306-22f78cda38b9'
  },
  PRODUCTION_CONTRACT_PLAN_ID: {
    NIDERA_PLAN_ID: 'a6b0584e-3c4f-481b-873f-7636114591ec',
    REMOTE_SENSING_PLAN_ID: '3018a40a-b848-47de-83a4-32119e088e32'
  },
  SIGN_UP_PASSWORD: 'testingbase123'
};
