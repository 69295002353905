import styled from 'styled-components';
import {iff} from '../../../utils/iff';
import get from 'lodash/get';

const StyledBackgroundImage = styled.img`
  display: block;
  height: ${props => iff (props.isEmptyCardForSettings === true, '180px', '262px')};
  width: ${props => iff (props.isEmptyCardForSettings === true, ' 40vw', ' 55vw')};
  position: absolute;
  left: ${props => iff (props.isEmptyCardForSettings === true, ' 40px', ' 78px')};
`;
const Label = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #2f3031;
  margin-right: 5px;
`;
const StyledCount = styled.span`
  color: #c6cacc;
  font-weight: normal;
  font-style: normal;
`;
const StyledDescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: #2f3031;
  position: relative;
  left: ${props => iff (props.isEmptyCardForSettings === true, '25em', 'auto')};
  top: ${props => iff (props.isEmptyCardForSettings === true, '40px', '94px')};
  max-width: 300px;
  label {
    white-space: ${props => iff (props.isEmptyCardForSettings === true, 'nowrap', 'initial')};
  }
`;
const StyledButton = styled.div`
  border-radius: 4px;
  height: 40px;
  width: 185px;
  text-align: center;
  line-height: 40px;
  font-weight: normal;
  font-size: 14px;
  margin-top: 19px;
  font-family: ${props => get (props, 'theme.font.family')};
  color: #fff;
  background-color: ${props => get (props, 'theme.colors.saveAndAddFildBgColor')};
  border: 1px solid ${props => get (props, 'theme.colors.saveAndAddFildBgColor')};
  &:hover {
    background-color: ${props => get (props, 'theme.colors.saveAndAddFildBgHoverColor')};
    border: 1px solid ${props => get (props, 'theme.colors.saveAndAddFildBgHoverColor')};
  }
  &:active {
    border-color: ${props => get (props, 'theme.colors.buttonHoverBgColor')};
  }
  &:focus {
    border-color: ${props => get (props, 'theme.colors.buttonHoverBgColor')};
  }
`;
const StyledLabelCount = styled.div`
  position: absolute;
  left: 24px;
  top: 7px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

const CreatePropertyButton = styled.span`
  font-weight: normal;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  right: 30px;
  top: 13px;
  display: block;
  text-align: center;
  position: absolute;
  cursor: pointer;
  color: #2f3031;
`;
export {
  StyledBackgroundImage,
  Label,
  StyledCount,
  StyledDescriptionSection,
  StyledButton,
  StyledLabelCount,
  CreatePropertyButton,
};
