import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import { ErrorBoundary } from '../ErrorBoundary';
import farmLogo from '../../public/side_layout.png';
import CentralLayoutImg from '../../public/central_layout.png';
import brandline from '../../public/brandline.png';
import syngentaLogoImg from '../../public/syngenta_logo.svg';
import { BaseLogo } from '../common/BaseLogo';
import { SyngentaLogo } from '../common/SyngentaLogo';
import { iff } from '../../utils/iff';

const Brandline = styled.img`
  width: 100%;
  height: 100vh;
  /* min-height: 850px; */
  /* min-width: 850px; */
`;

const StyledImg = styled.img`
  height: 100vh;
  width: 125%;
  float: right;
`;

const StyledContainer = styled.div`
  background-color: ${(props) => get(props, 'theme.colors.inputBackground')};
  display: grid;
  grid-template-columns: 50% 50%;
  overflow: auto;
`;
const SubContainer = styled.div`
  position: relative;
  height: 100vh;
`;

const CentralLayout = styled.div`
  background: url(${CentralLayoutImg}) no-repeat center fixed;
  height: 100%;
  background-size: 97%;
  background-color: #f5f8fc;
`;
const CustomLayout = styled.div`
  text-align: center;
  height: 100%;
`;
const LayoutBody = styled.div`
  display: inline-block;
  width: 23vw;
  position: absolute;
  top: 23vh;
  left: 4vw;
`;

export const Layout = (props) => {
  return (
    <ErrorBoundary>
      {iff(
        props.type === 'default',
        <StyledContainer>
          <SubContainer>
            <Brandline src={brandline} />
            <BaseLogo />
            <LayoutBody>{props.children}</LayoutBody>
          </SubContainer>
          <SubContainer>
            <SyngentaLogo src={syngentaLogoImg} />
            <StyledImg src={farmLogo} />
          </SubContainer>
        </StyledContainer>,
        <CentralLayout>
          <CustomLayout>
            <BaseLogo style={{ position: 'relative', top: '3vh', left: '0' }} />
            {props.children}
          </CustomLayout>
        </CentralLayout>
      )}
    </ErrorBoundary>
  );
};

Layout.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.object
};
