import axios from 'axios';
import { isWebUri } from 'valid-url';

export const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
  authorization: 'Basic c3RyaXgtdWk6'
};

const urlToOverride = localStorage.getItem('baseURL');
let baseURL = process.env.API_URL;

if (isWebUri(urlToOverride)) {
  baseURL = urlToOverride;
}

export const request = axios.create({
  baseURL
});
