import {
  CREATE_PROPERTY_SUCCESS,
  CREATE_PROPERTY_ERROR,
  EDIT_PROPERTY_SUCCESS,
  EDIT_PROPERTY_ERROR,
  PROPERTY_LOADING
} from '../actions/types';

const initialState = {
  loading: false,
  error: null,
  success: false
};

export const properties = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PROPERTY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true
      };
    case CREATE_PROPERTY_ERROR:
      return {
        ...state,
        error: action.error,
        success: false,
        loading: false
      };
    case EDIT_PROPERTY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true
      };
    case EDIT_PROPERTY_ERROR:
      return {
        ...state,
        error: action.error,
        success: false,
        loading: false
      };
    case PROPERTY_LOADING:
      return {
        ...state,
        loading: action.payload,
        success: false
      };

    default:
      return state;
  }
};
