import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Icons from '../../public/icons.json';
import { setPath, get } from '../../utils/svg-json-loader';
import { iff } from '../../utils/iff';

const IconComponent = ({ size, name }) => {
  const styles = {
    crop: 'wheat',
    size: '1em'
  };

  setPath(Icons);

  return (
    <>
      {iff(
        name !== null && name !== undefined && name !== '',
        <svg
          fill='currentColor'
          style={styles.svg}
          viewBox='0 0 1024 1024'
          width={`${size || styles.size}`}
          height={`${size || styles.size}`}>
          <path d={get(name) || get(styles.crop)} />
        </svg>,
        <svg
          fill='currentColor'
          style={styles.svg}
          viewBox='0 0 1024 1024'
          width={`${size || styles.size}`}
          height={`${size || styles.size}`}>
          <path d={get(styles.crop)} />
        </svg>
      )}
    </>
  );
};

IconComponent.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number
};

export const SvgIcon = memo(IconComponent);
