import React, { useState } from 'react';
import styled from 'styled-components';
import { Progress, Tooltip } from 'antd/lib';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { iff } from '../../utils/iff';
import { InputPassword } from './InputPassword';
import { Label } from './Label';
import {
  getStrength,
  getPasswordText,
  getPasswordErrorMessage
} from '../../utils/passwordValidation';
import { PasswordContainerStyled } from './commonStyle';

const InputPasswordProgress = (props) => {
  const { t } = useTranslation();
  let o = getPasswordErrorMessage();
  const [passwordText, setPasswordText] = useState(
    `${o.minLength}\n${o.upper}\n${o.lower}\n${o.number}`
  );
  const [progress, setProgress] = useState(0);
  const [strength, setStrength] = useState('');
  const [color, setColor] = useState('#8E9293');

  const strengthMap = {
    0: {
      color: '#8E9293',
      progress: 0
    },
    1: {
      color: '#EB8205',
      progress: 25,
      label: t('Weak')
    },
    2: {
      color: '#F2CD08',
      progress: 50,
      label: t('Medium')
    },
    3: {
      color: '#749204',
      progress: 75,
      label: t('Strong')
    },
    4: {
      color: '#749204',
      progress: 100,
      label: t('Very Strong')
    }
  };
  const ProgressBarStyle = styled(Progress)`
    .ant-progress-inner {
      vertical-align: super;
      height: 5px;
      border-radius: 10px;
    }
  `;
  const handlePasswordProgress = (e) => {
    let pass = e.target.value;
    pass = pass.replace(/ /g, '');
    const res = getStrength(pass);
    const p = strengthMap[res.score];
    setProgress(p.progress);
    setStrength(p.label);
    setColor(p.color);
    props.onChange(pass, p.progress);
    setPasswordText(getPasswordText(res.criteria));
  };
  return (
    <div>
      <PasswordContainerStyled>
        <Label
          style={{ float: 'left', margin: '0px 0 0 6px', color: '#707374', fontWeight: 'normal' }}
          data-testid="new-password-label">
          {iff(
            props.label !== undefined,
            iff(props.label === 'Password', t('Password')),
            t('New Password')
          )}
        </Label>
        <Label data-testid="password-progress-streangth-label" style={{ float: 'right', color: color }}>{strength}</Label>
        <Tooltip
          overlayStyle={{
            whiteSpace: 'pre'
          }}
          data-testid="password-progress-tooltip"
          title={passwordText}
          placement='right'
          trigger='click'>
          <InputPassword
            {...props}
            id='progresspassword'
            name='progresspassword'
            type='password'
            onChange={handlePasswordProgress}
            placeholder={iff(props.label !== undefined, '', t('Password'))}
            data-testid="password-progress-input"
          />
        </Tooltip>
        <ProgressBarStyle data-testid="password-progress-bar" percent={progress} showInfo={false} strokeColor={color} />
      </PasswordContainerStyled>
    </div>
  );
};

InputPasswordProgress.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};
export { InputPasswordProgress };
