import React from 'react';
import { Typography, Button } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import get from 'lodash/get';

import { history, URL_CONSTANTS } from '../../utils/history';
import RightMarkInCircle from '../../public/rightMarkInCircle.png';

const GreenButton = styled(Button)`
  border-radius: 4px;
  padding: 0px 48px;
  height: 40px;
  margin: 10px 0;
  background-color: #aab400;
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => get(props, 'theme.fontSizes.medium')};
  line-height: 17px;
  &.ant-btn-primary[disabled] {
    background-color: #aab400;
    color: #e8eaed;
  }
`;

const StyledRightMarkImage = styled.img`
  display: block;
  width: 124px;
  height: 124px;
  margin: 0 auto;
`;
export const PasswordChangedSuccessFully = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Typography.Text
        strong
        style={{
          fontSize: '28px',
          lineHeight: '38px',
          display: 'inherit',
          color: '#707374',
          width: '280px',
          margin: '40px auto'
        }}>
        {t('Your new password has been created')}
      </Typography.Text>
      <StyledRightMarkImage src={RightMarkInCircle} />
      <GreenButton
        onClick={() => {
          history.push(URL_CONSTANTS.ROOT());
        }}>
        {t('Login')}
      </GreenButton>
    </div>
  );
};
