import React from 'react';
import { Modal } from 'antd/lib';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { iff } from '../../../utils/iff';
import '../../../components/settings/Component.css';

const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    border-bottom: 3px solid #f74141;
    border-radius: 4px 4px 0 0;
  }
`;
export const ConfirmationModal = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <StyledModal
        title={`${props.title}`}
        visible={props.visible}
        onOk={props.onDelete}
        onCancel={props.onCancel}
        deleteProperty={props.deleteProperty}
        okText={iff(props.deleteProperty === true, t(props.title), t('Delete'))}
        cancelText={t('Cancel')}
        centered={true}
        closable={false}
        width='384px'
        okButtonProps={props.okButtonProps || { type: 'danger' }}
        cancelButtonProps={{ color: '#2F3031' }}>
        <div className='confirmation-modal-you-are'>
          {iff(
            props.deleteProperty === true,
            <>
              {' '}
              <p>
                {t('Are you sure you want to delete')} <b>{`${props.name}`}</b> ?
              </p>
            </>,
            <>
              {' '}
              <p>
                {t('You are deleting')}{' '}
                <span className='confirmation-modal-name'>{`${props.name}.`}</span>
              </p>
              <p>{t('Are you sure you want to delete')}?</p>
            </>
          )}
        </div>
      </StyledModal>
    </div>
  );
};

ConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  deleteProperty: PropTypes.bool,
  name: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  okButtonProps: PropTypes.object
};

ConfirmationModal.defaultProps = {
  name: ''
};
