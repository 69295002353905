import styled from 'styled-components';
import { Button } from 'antd/lib';
import get from 'lodash/get';

export const StyledMap = styled.div`
  height: '800px';
  width: '1200px';
`;

export const StyledImage = styled.img`
  height: ${(props) => props.height || '30px'};
  width: ${(props) => props.width || '30px'};
  padding: ${(props) => props.padding || '0px'};
  margin-bottom: '20px';
  cursor: ${(props) => props.cursor || ''};
  margin-right: 13px;

  &:hover {
    background: #fff !important;
  }
`;
export const StyledCommonImage = styled.span`
  height: ${(props) => props.height || '30px'};
  width: ${(props) => props.width || '30px'};
  padding: ${(props) => props.padding || '0px'};
  margin-botton: '20px';
  cursor: ${(props) => props.cursor || ''};
  margin-right: 13px;
  }
`;
export const StyledButton = styled(Button)`
  border: 1px solid #e8eaed;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  text-align: left;
  background-color: #fff;
  &:hover {
    background-color: #f9c43d;
    color: #ffffff;
    border-color: #fff;
  }
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 90%;
    margin-bottom: -6px;
  }
`;
export const StyledAddCircleButton = styled(Button)`
  border: 1px solid #e8eaed;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  text-align: left;
  background-color: #fff;
  .draw-circle-icon {
    color: #f9c43d;
    font-size: 20px;
  }
  &:hover {
    color: #f9c43d;
    border-color: #f9c43d;
  }
  &:active {
    color: #fff;
    background: #f9c43d;
    .draw-circle-icon {
      color: white;
      font-size: 20px;
    }
  }
  &.selected {
    color: blue;
  }
  span {
    text-overflow: ellipsis;
    width: 20px;
    margin-bottom: -6px;
  }
`;
export const StyledAddButton = styled(Button)`
  border: 1px solid #e8eaed;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  text-align: left;
  background-color: #fff;
  .draw-polygon-icon {
    color: #f9c43d;
    font-size: 20px;
  }
  &:hover {
    color: #f9c43d;
    border-color: #f9c43d;
  }
  &:active {
    color: #fff;
    background: #f9c43d;
    .draw-polygon-icon {
      color: white;
      font-size: 20px;
    }
  }
  &.selected {
    color: blue;
  }
  span {
    text-overflow: ellipsis;
    width: 20px;
    margin-bottom: -6px;
  }
`;
export const StyledRemoveButton = styled(Button)`
  border: 1px solid #e8eaed;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  text-align: left;
  background-color: #fff;
  .remove-icon {
    color: red;
    font-size: 20px;
  }
  &:hover {
    border-color: #f74141;
    color: #f74141;
  }
  &:active {
    color: #fff;
    background: #f74141;
    .remove-icon {
      color: white;
      font-size: 20px;
    }
  }
  span {
    text-overflow: ellipsis;
    width: 20px;
    margin-bottom: -6px;
  }
`;
export const StyledUndoButton = styled(Button)`
  border: 1px solid #e8eaed;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  text-align: left;
  background-color: #fff;
  .undo-icon {
    color: #00a0be;
    font-size: 20px;
  }
  &:hover {
    border-color: #00a0be;
    color: #00a0be;
  }
  &:active {
    color: #fff;
    background: #00a0be;
    .undo-icon {
      color: white;
      font-size: 20px;
    }
  }
  span {
    text-overflow: ellipsis;
    width: 20px;
    margin-bottom: -6px;
  }
`;
export const StyledButtonsCancelSubmit = styled(Button)`
  margin-left: 15px;
  width: 100%;
  height: 40px;
  text-align: center;
  color: black;
  border-color: #fff;
  &:hover {
    color: black;
    border-color: #fff;
  }
`;
export const StyledButtonsSaveDraAnother = styled(Button)`
  margin-left: 15px;
  width: 100%;
  height: 40px;
  text-align: center;
  color: black;
  border: none;
  font-family: ${(props) => get(props, 'theme.font.family')};
  background: ${(props) => get(props, 'theme.colors.buttonBackground')};
  color: ${(props) => get(props, 'theme.colors.butonColor')};
  &:hover {
    background: ${(props) => get(props, 'theme.colors.buttonHoverBgColor')};
    color: ${(props) => get(props, 'theme.colors.buttonHoverColor')};
    border: ${(props) => get(props, 'theme.colors.buttonHoverBorderColor')};
  }
  &:active {
    color: ${(props) => get(props, 'theme.colors.buttonBackground')};
  }
  &:focus {
    color: ${(props) => get(props, 'theme.colors.buttonHoverColor')};
  }
`;