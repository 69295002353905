import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import { iff } from '../../../utils/iff';

import {
  StyledBackgroundImage,
  StyledDescriptionSection,
  StyledButton
} from './EmptyCard.styles';

export const EmptyCard = (props) => {
  const { t } = useTranslation();
  const bottomBorder = iff(
    props.isEmptyCardForSettings === true,
    'transparent',
    '1px solid #C1C5C8'
  );

  const Styledbg = styled.div`
    /* background: url(${props.backgroundImageUrl}); */
    height: 96%;
    border-bottom: ${bottomBorder};
    margin: ${iff(props.isEmptyCardForSettings === true, '0px', 'auto 15px')}
  `;

  return (
    <Styledbg>
      <Row>
        <Col span={18}>
          <StyledBackgroundImage
            isEmptyCardForSettings={props.isEmptyCardForSettings}
            src={props.backgroundImageUrl}
            alt={t('Image not found')}
            data-testid="empty-card-img"
          />
        </Col>
        <Col span={6}>
          <StyledDescriptionSection isEmptyCardForSettings={props.isEmptyCardForSettings}>
            <label data-testid="empty-card-label">{t(`No ${props.label.toLocaleLowerCase()} created`)}</label>
            <StyledButton data-testid="empty-card-btn" className='btn-green' onClick={props.onButtonClick}>
              + {t('Create new property')}
            </StyledButton>
          </StyledDescriptionSection>
        </Col>
      </Row>
    </Styledbg>
  );
};

EmptyCard.defaultProps = {
  backgroundImageUrl: '',
  label: '',
  onButtonClick: () => null
};
EmptyCard.propTypes = {
  label: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  onButtonClick: PropTypes.func,
  isEmptyCardForSettings: PropTypes.bool
};
