import React from 'react';
import { Modal } from 'antd/lib';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { StyledSpan } from '../../utils/styles.theming';

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom-color: red;
  }
  .ant-modal-footer {
    border-top: none;
  }
  .ant-btn-danger {
    font-family: ${(props) => get(props, 'theme.font.family')};
  }
  .ant-btn-default {
    font-family: ${(props) => get(props, 'theme.font.family')};
    &:hover {
      color: ${(props) => get(props, 'theme.colors.buttonHoverColor')};
      border-color: ${(props) => get(props, 'theme.colors.buttonHoverBorderColor')};
    }
    &:active {
      color: ${(props) => get(props, 'theme.colors.buttonBackground')};
      border-color: ${(props) => get(props, 'theme.colors.buttonHoverBorderColor')};
    }
    &:focus {
      color: ${(props) => get(props, 'theme.colors.buttonHoverColor')};
      border-color: ${(props) => get(props, 'theme.colors.buttonHoverBorderColor')};
    }
  }
`;
export const ConfirmModal = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <StyledModal
        title={
          <StyledSpan style={{ fontWeight: '500', fontSize: '20px', color: 'red' }}>
            {props.title}
          </StyledSpan>
        }
        visible={props.visible}
        closable={false}
        width={320}
        onOk={props.onOk}
        onCancel={props.onCancel}
        okButtonProps={{ type: 'danger' }}
        okText={t('Confirm')}
        cancelText={t('Cancel')}
        cancelButtonProps={{ type: 'default' }}>
        {props.children}
      </StyledModal>
    </div>
  );
};
ConfirmModal.propTypes = {
  visible: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string
};
