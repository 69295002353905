import { createSelector } from 'reselect';

export const getOrgs = (state) => state.orgs || {};

export const getOrgsData = (state, { id } = {}) => {
  const data = getOrgs(state).data || [];
  if (data.length && id) {
    return data.filter((d) => d.id === id);
  }
  return data;
};

export const getNoOfOrgs = createSelector(
  getOrgs,
  (orgs) => (orgs.data && Array.isArray(orgs.data) && orgs.data.length) || 0
);

export const getOrgById = createSelector(getOrgsData, (data) => data[0] || {});

export const getOrgDetailsByOrgId = (state, { orgId } = {}) => {
  const data = getOrgs(state).data || [];
  return data.find((d) => d.id === orgId) || {};
};

export const getSelectedOrg = (state) => {
  return getOrgs(state).selectedOrg;
};

export const getSelectedProperty = (state) => {
  return getOrgs(state).selectedProperty;
};

export const isLoading = (state) => {
  return getOrgs(state).loading;
};

export const getError = (state) => {
  return getOrgs(state).error;
};

export const isError = createSelector(
  getError,
  (err) => err !== null && typeof err === 'object' && Object.keys(err) > 0
);

export const getOrganizations = createSelector(
  getOrgs,
  (organizations) => organizations.data || []
);

export const getPropertiesByOrgId = createSelector(
  getOrganizations,
  (_, props) => props.orgId,
  (organizations, orgId) => {
    const organization = organizations.find((o) => o.id === orgId);
    return organization?.properties || [];
  }
);
