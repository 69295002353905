import styled from 'styled-components';
import get from 'lodash/get';

export const AnchorLink = styled.a`
  font-size: ${(props) => get(props, 'theme.fontSizes.small')};
  line-height: 20px;
  margin-bottom: 20px;
  color: ${(props) => get(props, 'theme.colors.fontColor')};
  text-decoration: underline;
  margin-left: 0.2vw;
  &:focus {
    text-decoration: none;
    outline: 0;
  }
  &:hover {
    color: ${(props) => get(props, 'theme.colors.fontColor')};
  }
`;
