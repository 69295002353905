import styled from 'styled-components';
import get from 'lodash/get';

export const UploadContainer = styled.div`
  min-height: 91.7vh;
  padding: 24px;
  background-color: #fff;
  .header {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #2f3031;
  }
  .search {
    border: 1px solid #e8eaed;
    border-radius: 4px;
    margin-top: 12px 0 6px;
  }

  .ant-list-item {
    display: flex;
    align-items: center;
    padding: 12px 10px 0 0;
  }
  .ant-upload-drag {
    border-bottom: 1px solid #e8eaed;
  }
  .ant-upload-text {
    text-align: center;
    margin: 12px;
    padding: 12px;
    img {
      margin-bottom: 13px;
    }
    .title {
      margin: 8px;
      font-size: 14px;
      color: #515253;
    }
    .desc {
      color: #8e9293;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
    }
  }
  .file-uploader {
    cursor: pointer;
    &:hover {
      border: 1px solid #f9c43d;
      .title,
      .desc {
        color: #f9c43d;
      }
    }
  }
`;

export const FileListContainer = styled.div`
  overflow: auto;
  height: 200px;
  .icon-container {
    width: 50px;
    height: 50px;
    border: 1px solid;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 18px;
      height: 14px;
    }
    &.success {
      border-color: #749204;
    }
    &.error {
      border-color: #f74141;
    }
  }

  .file-name {
    color: #707374;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-left: 12px;
  }
  .file-ext {
    color: #c1c5c8;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    div {
      margin: 0 6px;
    }
  }

  .delete-icon {
    font-weight: 700;
    padding: 10px;
    color: #515253;
    cursor: pointer;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => get(props, 'theme.colors.scrollbarThumbBgColor')};
    -webkit-box-shadow: inset 0 3px 2px 6px
      ${(props) => get(props, 'theme.colors.scrollbarThumbBgColor')} !important;
    cursor: pointer;
  }
`;

export const ButtonContainer = styled.div`
  text-align: end;
  padding: 24px 0;
`;
