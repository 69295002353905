let iconRawPath = null;
let mappedIcon = [];

export const setPath = (path) => {
  iconRawPath = path;
  mappedIcon = iconRawPath.icons.map((icon) => ({
    key: icon.tags[0],
    value: icon.paths[0]
  }));
};

export const get = (name) => {
  const selectedIcon = mappedIcon.find((icon) => icon.key === name);
  return selectedIcon?.value || null;
};
