import styled from 'styled-components';
import { Button } from 'antd/lib';
import get from 'lodash/get';

export const FieldListPopupContainer = styled.div`
  position: fixed;
  z-index: 999;
  top: 22%;
  left: 8%;
  transform: translateX(-50%);
  max-height: 410px;
  width: 130px;
  max-width: 150px;
  border-radius: 4px;
  overflow: hidden;
  background-color: white;
  .active {
    color: white;
    background: ${(props) => get(props, 'theme.colors.buttonBackground')};
    margin-left: -1px;
    .edit_icon {
      img {
        height: 22px;
        width: 22px;
        background: #686868;
        border-radius: 50%;
      }
    }
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => get(props, 'theme.colors.scrollbarThumbBgColor')};
    -webkit-box-shadow: inset 0 3px 2px 6px
      ${(props) => get(props, 'theme.colors.scrollbarThumbBgColor')} !important;
    cursor: pointer;
  }
  .slick-track {
    height: 40px !important;
    max-height: 410px !important;
  }
  .ant-carousel {
    line-height: 0.5;
  }
`;

export const StyledFieldListItem = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  padding: 0px 10px;
  position: relative;
  cursor: pointer;
  .filedList_innerSection {
    padding: 10px;
    border-bottom: 1px solid #e8eaed;
    width: 102px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
  }
  .border_bottom {
    border-bottom: none;
  }
  .edit_icon {
    position: absolute;
    right: 4px;
    top: 6px;
    padding: 2px;
    height: 24px;
    width: 24px;
    img {
      height: 22px;
      width: 22px;
    }
  }
  .edit_hover {
    display: none;
  }
  &:hover {
    color: white;
    background: ${(props) => get(props, 'theme.colors.primary')};
    .edit_hover {
      display: block;
      z-index: 1;
      background: ${(props) => get(props, 'theme.colors.primary')};
      img {
        background: transparent;
      }
    }
  }
`;
export const StyledButton = styled(Button)`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-left: 35%;
  .anticon {
    font-size: 12px;
    margin-left: -6px;
  }
`;
export const EditStyledButton = styled(Button)`
  height: 30px;
  width: 30px;
  margin-left: 35%;
  border: none;
  .anticon {
    font-size: 12px;
    margin-left: -6px;
  }
`;
