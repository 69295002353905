import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import './Signup.css';
import { Input } from '../common/Input';
import { Label } from '../common/Label';
import { TitleBar } from '../common/TitleBar';
import { SigninRectangleArrowIcon } from '../common/RectangleFocusIcon';
import { ErrorMessage } from '../common/ErrorMessage';
import { history, URL_CONSTANTS } from '../../utils/history';
import { iff } from '../../utils/iff';
import { Loader } from '../Loader/Loader';
import { Layout } from '../Layout/Layout';
import { isEmptyStr } from '../../utils/common-methods';
import { AppConstants } from '../../utils/app.constants';
import SigninRectangleArrow from '../../public/signin-rect.svg';
import { SignupBtnStyle } from './signupStyle';
import { RenderGoogleButtonAndLoginLink } from '../common/RenderGoogleButtonAndLoginLink';

export const SignupStep1 = () => {
  const [loader] = useState(false);
  const inputRef = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <Layout type='default'>
      {iff(loader, <Loader />)}
      <Formik
        initialValues={{
          email: '',
          userName: ''
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .max(
              AppConstants.EMAIL_MAX_LENGTH,
              t('You have exceeded maximum number of characters allowed.')
            )
            .email(t('Please enter valid email address'))
            .required(t('Please Enter Email address')),
          userName: Yup.string().required(t('Please Enter your name'))
        })}
        onSubmit={(values) => {
          history.push({
            pathname: URL_CONSTANTS.SIGNUP_2(),
            state: { email: values.email, userName: values.userName }
          });
        }}>
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <TitleBar data-testid="signup-page-title" id="signup-page-title">{t('Create your account')}</TitleBar>
            <SigninRectangleArrowIcon>
              <img src={SigninRectangleArrow} alt="SigninRectangleArrow" />
            </SigninRectangleArrowIcon>
            <Label data-testid='label'>{t('Your Name')}</Label>
            <Input
              style={{ margin: '5px 0 0 0' }}
              ref={inputRef}
              data-testid='userName'
              id='userName'
              name='userName'
              type='primary'
              value={formik.values.userName}
              onChange={formik.handleChange}
              onKeyDown={() => formik.setFieldTouched('userName', true)}
              placeholder={t('Your Name')}
              error={formik.touched.userName && isEmptyStr(formik.errors.userName)}
            />
            {iff(
              isEmptyStr(formik.touched.userName && formik.errors.userName),
              <ErrorMessage>{formik.errors.userName}</ErrorMessage>
            )}
            <Label style={{ margin: '2em 0 0 0' }} data-testid='label'>
              {t('Email')}
            </Label>
            <Input
              style={{ margin: '5px 0 0 0' }}
              data-testid='email'
              id='email'
              name='email'
              type='primary'
              value={formik.values.email.replace(/ /g, '')}
              onChange={formik.handleChange}
              onKeyDown={() => formik.setFieldTouched('email', true)}
              placeholder={t('Email')}
              error={formik.touched.email && isEmptyStr(formik.errors.email)}
              onKeyPress={(e) => {
                if (e.charCode === 32) {
                  e.preventDefault();
                }
              }}
            />
            {iff(
              formik.touched.email && isEmptyStr(formik.errors.email),
              <ErrorMessage>{formik.errors.email}</ErrorMessage>
            )}
            <SignupBtnStyle
              data-testid='sign-up-btn'
              type='primary'
              htmlType='submit'
              disabled={
                formik.values.userName.trim() === '' ||
                formik.values.email.trim() === '' ||
                (formik.touched.userName && isEmptyStr(formik.errors.userName)) ||
                (formik.touched.email && isEmptyStr(formik.errors.email))
              }>
              {t('Sign up')}
            </SignupBtnStyle>

            <div className='login-other-option'>
              <RenderGoogleButtonAndLoginLink isLogin={true} />
            </div>
          </form>
        )}
      </Formik>
    </Layout>
  );
};
