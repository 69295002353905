import get from 'lodash/get';
import { request } from '../utils/axios';
import { getHeaders } from '../utils/common-methods';
import {
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATIONS_ERROR,
  FETCH_ORGANIZATIONS_LOADING,
  CREATE_PROPERTY_SUCCESS,
  CREATE_PROPERTY_ERROR,
  EDIT_PROPERTY_SUCCESS,
  EDIT_PROPERTY_ERROR,
  PROPERTY_LOADING,
  TOGGLE_HEADER,
} from './types';

export function fetchOrganizationsLoading() {
  return {
    type: FETCH_ORGANIZATIONS_LOADING
  };
}

export function fetchOrgsSuccess(orgs) {
  return {
    type: FETCH_ORGANIZATIONS_SUCCESS,
    payload: orgs
  };
}
export function fetchOrgsError(error) {
  return {
    type: FETCH_ORGANIZATIONS_ERROR,
    error
  };
}

export const fetchOrgs = () => {
  return async (dispatch) => {
    dispatch(fetchOrganizationsLoading());
    const fetchOrgsRes = await request({
      method: 'get',
      url: `/v2/orgs`,
      headers: getHeaders()
    });
    const orgs = get(fetchOrgsRes, 'data.content') || [];
    return request
      .get(`/v2/orgs/${orgs[0].id}/properties?attributes=total_area`, {
        headers: getHeaders()
      })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        fetchOrgsRes.data.content[0].properties = res.data.content;
        dispatch(fetchOrgsSuccess(fetchOrgsRes.data.content));
        return fetchOrgsRes;
      })
      .catch((error) => {
        dispatch(fetchOrgsError(error));
      });
  };
};

export const toggleHeader = (status) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_HEADER, payload: status });
  };
};

export function propertyLoading(loading) {
  return {
    type: PROPERTY_LOADING,
    payload: loading
  };
}
export const saveCreatedPropertySuccess = (properties) => {
  return (dispatch) => {
    dispatch({ type: CREATE_PROPERTY_SUCCESS, payload: properties });
  };
};

export const saveEditedPropertySuccess = (properties) => {
  return (dispatch) => {
    dispatch({ type: EDIT_PROPERTY_SUCCESS, payload: properties });
  };
};

export const saveCreatedPropertyError = (error) => {
  return {
    type: CREATE_PROPERTY_ERROR,
    error
  };
};

export const saveEditedPropertyError = (error) => {
  return {
    type: EDIT_PROPERTY_ERROR,
    error
  };
};

export const saveCreatedProperty = (property) => {
  return (dispatch) => {
    dispatch(propertyLoading(true));
    return request({
      method: 'post',
      url: `/v2/properties`,
      headers: getHeaders(),
      data: property
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(saveCreatedPropertySuccess(res.data));
        dispatch(propertyLoading(false));
        return res;
      })
      .catch((error) => {
        dispatch(saveCreatedPropertyError(error));
        return Promise.reject(error);
      });
  };
};

export const saveEditedProperty = (property) => {
  return (dispatch) => {
    dispatch(propertyLoading(true));
    return request({
      method: 'put',
      url: `/v2/properties/${property.id}`,
      headers: getHeaders(),
      data: property
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(saveEditedPropertySuccess(res.data));
        dispatch(propertyLoading(false));
        return res;
      })
      .catch((error) => {
        dispatch(saveEditedPropertyError(error));
        return Promise.reject(error);
      });
  };
};

export const getPropertyDetailsById = async (propId) => {
  const propertyRes = await request.get(`/v2/properties/${propId}`, {
    headers: getHeaders()
  });
  return propertyRes;
};
