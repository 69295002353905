import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { LoginComponent } from '../Login/Login';
import { SignupStep1 } from '../Signup/SignupStep1';
import { SignupStep2 } from '../Signup/SignupStep2';
import { ResetPassword } from '../ForgotPassword/ResetPassword';
import { history } from '../../utils/history';
import { TermsAndConditions } from '../TermAndCondition/TermsAndConditions';
import { RecoveryPassword } from '../ForgotPassword/RecoveryPassword';
import { LandingOrganizationContainerComponent } from '../LandingOrganization/LandingOrganizationContainer';
import { WrapperComponent } from '../landingFarm/LandingFarmContainer';
import { MapsContainerComponent } from '../Maps/MapsContainer';
import { UploadShapeFilesComponent } from '../uploadFields/uploadShapeFiles';
import { ShapeFilesListComponent } from '../uploadFields/shapeFilesList';
import { FieldsCard } from '../../components/fields/FieldsCard';
import { DrawPolygonComponent } from '../Maps/DrawPolygon';
import { YourFields } from '../../components/fields/YourFields';
import { LanguageDropdown } from '../../localization/LanguageDropdown';
import { DetectFieldsComponent } from '../../components/DetectFields/detectFields';

export const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          path='/landing-organization/:id'
          exact={true}
          component={LandingOrganizationContainerComponent}
        />
        <Route path='/components/langdropdown' exact={true} component={LanguageDropdown} />
        <Route path='/' exact={true} component={LoginComponent} />
        <Route path='/login' exact={true} component={LoginComponent} />
        <Route
          path='/landing-organization/:id/farm/:farmId/create-field/draw-field'
          exact={true}
          component={DrawPolygonComponent}
        />
        <Route
          path='/landing-organization/:id/farm/:farmId/create-field/detect-fields'
          exact={true}
          component={DetectFieldsComponent}
        />
        <Route
          path='/landing-organization/:id/farm/:farmId/edit-field/:fieldId'
          exact={true}
          component={DrawPolygonComponent}
        />
        <Route path='/draw-polygon' exact={true} component={DrawPolygonComponent} />
        <Route path='/sign-up-1' exact={true} component={SignupStep1} />
        <Route path='/sign-up-2' exact={true} component={SignupStep2} />
        <Route path='/reset-password' exact={true} component={ResetPassword} />
        <Route path='/recovery-password' exact={true} component={RecoveryPassword} />
        <Route path='/terms-and-conditions' exact={true} component={TermsAndConditions} />
        <Route
          path='/landing-organization/:id/farm/:farmId/create-fields'
          exact={true}
          component={FieldsCard}
        />
        <Route
          path='/landing-organization/:id/farm/:farmId/all-fields'
          exact={true}
          component={YourFields}
        />
        <Route path='/' exact={true} component={LandingOrganizationContainerComponent} />
        <Route
          path='/landing-organization/:id/create-farm'
          exact={true}
          component={WrapperComponent}
        />
        <Route
          path='/landing-organization/:id/edit-farm/:farmId'
          exact={true}
          component={WrapperComponent}
        />
        <Route
          path='/landing-organization/:id/farm/:farmId'
          exact={true}
          component={MapsContainerComponent}
        />
        <Route
          path='/landing-organization/:id/farm/:farmId/create-field/uploader'
          exact={true}
          component={UploadShapeFilesComponent}
        />
        <Route
          path='/landing-organization/:id/farm/:farmId/create-field/uploaded-list'
          exact={true}
          component={ShapeFilesListComponent}
        />
      </Switch>
    </Router>
  );
};

export const MemoizeRoutes = React.memo(Routes);
