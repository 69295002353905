import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import format from 'date-fns/format';
import get from 'lodash/get';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { request } from '../../utils/axios';
import { iff } from '../../utils/iff';
import { getProgressByValues, isEmptyStr } from '../../utils/common-methods';
import { ProgressBar } from '../common/ProgressBar';
import { Layout } from '../Layout/Layout';
import { ErrorMessage } from '../common/ErrorMessage';
import { Loader } from '../Loader/Loader';
import { Button } from '../common/Button';
import { TitleBar } from '../common/TitleBar';
import { Checkbox } from '../common/Checkbox';
import { AnchorLink } from '../common/AnchorLink';
import { history, URL_CONSTANTS } from '../../utils/history';
import { PhoneInputBox } from '../common/PhoneInputBox';
import { AppConstants } from '../../utils/app.constants';
import { getLocationFromBrowser } from '../../utils/location';
import { getParamValue } from '../../utils/getUrlParamsByName';
import { ErrorModal } from '../common/Modal/ErrorModal';
import { getErrorMessageByCode } from '../../utils/getErrorMessageByCode';
import { MobileLabel, FormBody, CheckBoxdivStyle } from './signupStyle';
import { SuccessModal } from '../common/Modal/successModal';
import './Signup.css';

const SecondaryButton = styled(Button)`
  margin-right: 12px;
  width: 80px;
  cursor: pointer;
`;

const SignupStep2 = (props) => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [termsCheckbox, setTermsCheckbox] = useState(
    get(props, 'location.state.termsCheckbox', false)
  );

  const [termsAccepetedOn, setTermsAccepetedOn] = useState(
    get(props, 'location.state.termsAccepetedOn')
  );
  const isPasswordValid = get(props, 'location.state.isPasswordValid', false);
  const [emailCheckbox, setEmailCheckbox] = useState(
    get(props, 'location.state.emailCheckbox', true)
  );
  const [mobileCheckbox, setMobileCheckbox] = useState(
    get(props, 'location.state.mobileCheckbox', true)
  );
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const [defaultCountry, setDefaultCountry] = useState('us');
  const [errorMessage, setErrorMessage] = useState('');
  const [mobileNumberValue, setMobileNumberValue] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (!props?.location?.state?.email || !props?.location?.state?.userName) {
      const queryUserEmail = getParamValue('userEmail');
      const queryUserName = getParamValue('userName');
      const querInviteId = getParamValue('inviteId');
      if (!queryUserEmail || !queryUserName || !querInviteId) {
        history.replace('/sign-up-1');
        return;
      }
      setUserName(queryUserName);
      setUserEmail(queryUserEmail);
      setCountryCode();
      return;
    }
    if (props.location.state.mobileNumber !== undefined) {
      setMobileNumberValue(props.location.state.mobileNumber);
    }
    setUserName(props.location.state.userName);
    setUserEmail(props.location.state.email);
    setCountryCode();
  }, []);

  const onChange = (e) => {
    switch (e.target.value) {
      case 'terms':
        setTermsCheckbox(e.target.checked);
        setTermsAccepetedOn(
          format(new Date(), AppConstants.DATE_FORMATS.YYYY_DASH_MM_DASH_DD_WITH_TIME)
        );
        break;
      case 'email':
        setEmailCheckbox(e.target.checked);

        break;
      case 'mobile':
        setMobileCheckbox(e.target.checked);
        break;
    }
    setLoader(false);
  };

  const setCountryCode = async () => {
    if (get(props, 'location.state.mobileNumber')) {
      return;
    }
    if (window.google) {
      let geocoder = new window.google.maps.Geocoder();
      let latlngLocation = await getLocationFromBrowser();

      const latlng = {
        lat: latlngLocation.latitude,
        lng: latlngLocation.longitude
      };
      if (latlng) {
        geocoder.geocode({ location: latlng }, function (results, status) {
          if (status === 'OK') {
            let shortName = getCountry(get(results, '[0].address_components'));
            setDefaultCountry(shortName.toLowerCase());
          }
        });
      } else {
        setDefaultCountry('us');
      }
    }
  };

  function getCountry(addrComponents) {
    for (let i = 0; i < addrComponents.length; i++) {
      if (get(addrComponents[i], 'types[0]') === 'country') {
        return addrComponents[i].short_name;
      }
    }
    return false;
  }

  return (
    <Layout type='custom'>
      {iff(loader === true, <Loader />)}
      <Formik
        initialValues={{
          mobileNumber: get(props, 'location.state.mobileNumber', ''),
          password: get(props, 'location.state.password', '')
        }}
        validationSchema={Yup.object({
          mobileNumber: Yup.string()
            .max(
              AppConstants.VALIDATIONS.MOBILE_DIGITS_LENGTH,
              t('Please enter a valid mobile number')
            )
            .min(
              AppConstants.VALIDATIONS.MIN_MOBILE_DIGITS_LENGTH,
              t('Please enter a valid mobile number')
            ),
        })}
        onSubmit={(values) => {
          setLoader(true);
          const config = {
            headers: {
              'Content-Type': 'application/json'
            }
          };
          let details = {
            opt_ins: [
              {
                "type": "EMAIL_OFFERS"
              },
              {
                "type": "CELLPHONE_OFFERS"
              },
              {
                "type": "DATA_SHARING"
              }
            ],
            termsAcceptedOn: termsAccepetedOn,
            org_creation_strategy: "auto",
            name: userName,
            password: AppConstants.SIGN_UP_PASSWORD,
            phone: values.mobileNumber,
            locale: "en",
            email: userEmail.substring(0, userEmail.indexOf('@')) + '@mz.com',
            type: 'signup_request',
            country_code: "AR"
          };
          request
            .post('/v2/accounts/signup', details, config)
            .then((res) => {
              setLoader(false);
            })
            .catch((error) => {
              setLoader(false);
              setErrorMessage(getErrorMessageByCode(get(error, 'response.data.code')));
            });
        }}>
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <FormBody>
              <ErrorModal
                visible={!!errorMessage}
                message={errorMessage}
                okText={t('Ok')}
                onOk={() => {
                  setErrorMessage('');
                }}
              />
              <SuccessModal
                visible={!!successMessage}
                message={successMessage}
                okText={t('Ok')}
                onOk={() => {
                  setSuccessMessage('');
                  history.push(URL_CONSTANTS.LOGIN());
                }}
              />
              <TitleBar id="signup-step-2-title" style={{ fontWeight: 'normal', marginBottom: '0px', textAlign: 'center' }}>
                {t('Hi')} {userName},
              </TitleBar>
              <TitleBar
                style={{
                  fontWeight: '500',
                  marginBottom: '1em',
                  fontSize: '24px',
                  textAlign: 'center'
                }}
                data-testid="signup-step-2-note">
                {t(`let's create your account`)}
              </TitleBar>
              <MobileLabel data-testid='data-testid="signup-step-2-mobile-label'>{t('Mobile number')}</MobileLabel>
              <PhoneInputBox
                data-testid='data-testid="signup-step-2-mobile-input'
                name='mobileNumber'
                onKeyDown={() => formik.setFieldTouched('mobileNumber', true)}
                country={defaultCountry}
                value={formik.values.mobileNumber}
                onChange={(res) => {
                  formik.setFieldValue('mobileNumber', res);
                  setMobileNumberValue(res);
                }}
                error={formik.touched.mobileNumber && isEmptyStr(formik.errors.mobileNumber)}
                autoFocus={true}
              />
              {iff(
                formik.touched.mobileNumber && isEmptyStr(formik.errors.mobileNumber),
                <ErrorMessage>{formik.errors.mobileNumber}</ErrorMessage>
              )}
              <CheckBoxdivStyle>
                <Checkbox
                  data-testid="signup-step-2-checkbox-terms"
                  className='signup-checkbox'
                  onChange={onChange}
                  checked={termsCheckbox}
                  value='terms'>
                  {t('I have read and agree with the')}{' '}
                  <AnchorLink
                    style={{
                      float: 'inherit'
                    }}
                    onClick={() => {
                      history.push({
                        pathname: URL_CONSTANTS.TERMS_AND_CONDITIONS(),
                        state: {
                          ...props.location.state,
                          mobileNumber: formik.values.mobileNumber,
                          password: formik.values.password,
                          mobileCheckbox,
                          emailCheckbox,
                          termsCheckbox,
                          termsAccepetedOn,
                          isPasswordValid
                        }
                      });
                    }}
                    tabIndex="-1"
                    data-testid="terms-and-conditions-link">
                    {t('Privacy Policy, Terms and Conditions')}
                  </AnchorLink>
                </Checkbox>
                <Checkbox
                  data-testid="signup-step-2-checkbox-offers-email"
                  className='signup-checkboxEmail'
                  onChange={onChange}
                  checked={emailCheckbox}
                  value='email'>
                  {t('Allow Base to send offers to my email')}
                </Checkbox>
                <Checkbox
                  data-testid="signup-step-2-checkbox-offers-mobile"
                  className='signup-checkboxMobile'
                  onChange={onChange}
                  checked={mobileCheckbox}
                  value='mobile'>
                  {t('Allow Base to send offers to my mobile number')}
                </Checkbox>
              </CheckBoxdivStyle>
              <div className='login-signup-button'>
                <Button
                  disabled={
                    (formik.touched.mobileNumber && isEmptyStr(formik.errors.mobileNumber)) ||
                    !termsCheckbox ||
                    mobileNumberValue.length < AppConstants.VALIDATIONS.MIN_MOBILE_DIGITS_LENGTH
                  }
                  className='btn-green'
                  type='btnGreen'
                  htmlType='submit'
                  style={{ width: '137px' }}
                  tabIndex={-1}
                  data-testid="signup-step-2-create-account-btn">
                  {t('Create Account')}
                </Button>
                <SecondaryButton
                  data-testid="signup-step-2-cancel-btn"
                  type='secondary'
                  onClick={() => history.push(URL_CONSTANTS.SIGNUP_1())}
                  tabIndex={-1}>
                  {t('Cancel')}
                </SecondaryButton>
              </div>
            </FormBody>
            <ProgressBar
              progress={getProgressByValues(
                formik.values.mobileNumber && !isEmptyStr(formik.errors.mobileNumber),
                termsCheckbox
              )}
            />
          </form>
        )}
      </Formik>
    </Layout >
  );
};

SignupStep2.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      userName: PropTypes.string,
      email: PropTypes.string,
      mobileNumber: PropTypes.string
    })
  }).isRequired
};

export { SignupStep2 };
