import React, { useState } from 'react';
import { Typography } from 'antd/lib';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

import { Layout } from '../Layout/Layout';
import { InputPasswordProgress } from '../common/InputPasswordProgress';
import { getParamValue } from '../../utils/getUrlParamsByName';
import { iff } from '../../utils/iff';
import { PasswordChangedSuccessFully } from './PasswordChangedSuccessFully';
import { request } from '../../utils/axios';
import { Button } from '../common/Button';
import { AppConstants } from '../../utils/app.constants';
import { ErrorModal } from '../common/Modal/ErrorModal';
import { getErrorMessageByCode } from '../../utils/getErrorMessageByCode';

const MAX_PROGRESS_LIMIT = 75;
const params = {
  newPassword: 'new_password'
};

export const ResetPassword = () => {
  const { t } = useTranslation();
  const email = getParamValue('email');
  const recoveryId = getParamValue('recovery_id');
  const [user, setUser] = useState({ email, recoveryId, password: '' });
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const changePassword = () => {
    setLoader(true);
    let userDetails = {
      email: user.email,
      [params.newPassword]: user.password
    };
    request
      .post(`/v2/accounts/recovery/${recoveryId}`, userDetails)
      .then(() => {
        setLoader(false);
        setShowSuccessPage(true);
      })
      .catch((error) => {
        setLoader(false);
        setErrorMessage(getErrorMessageByCode(get(error, 'response.data.code')));
      });
  };

  return (
    <Layout type='central'>
      {iff(showSuccessPage, <PasswordChangedSuccessFully />)}
      {iff(
        !showSuccessPage,
        <>
          <ErrorModal
            visible={!!errorMessage}
            message={errorMessage}
            okText={t('Ok')}
            onOk={() => {
              setErrorMessage('');
            }}
          />
          <Typography.Text
            style={{
              fontSize: '28px',
              lineHeight: '38px',
              display: 'inherit',
              marginTop: '6vh',
              color: '#707374'
            }}
            data-testid="reset-pwd-title">
            {t('Hey')} {email},
          </Typography.Text>
          <Typography.Text
            strong
            style={{
              fontSize: '28px',
              lineHeight: '38px',
              display: 'inherit',
              color: '#707374',
              marginBottom: '45px'
            }}
            data-testid="reset-pwd-note">
            {t('type a new password below')}
          </Typography.Text>
          <div style={{ width: '25%', margin: '0 auto' }}>
            <InputPasswordProgress
              data-testid='inputId'
              id='password'
              name='password'
              type='password'
              onChange={(pass, progress) => {
                let password = pass;
                password = password.replace(/ /g, '');
                setUser({ password, email, recoveryId });
                setIsPasswordValid(progress >= MAX_PROGRESS_LIMIT);
              }}
              value={user.password}
              autoFocus
              onKeyPress={(e) => e.charCode === 32 && e.preventDefault()}
              maxLength={AppConstants.PASSWORD_MAX_LENGTH}
            />
            <Button
              className='btn-green'
              type='btnGreen'
              style={{ margin: '10% 0' }}
              disabled={!isPasswordValid}
              onClick={changePassword}
              loading={loader}
              data-testid="reset-pwd-confirm-btn">
              {t('Confirm')}
            </Button>
          </div>
        </>
      )}
    </Layout>
  );
};
