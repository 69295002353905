import React from 'react';
import PropTypes from 'prop-types';
import { Card, Skeleton } from 'antd/lib';
import { Container } from './SkeletonCard.style';

export const SkeletonCard = (props) => {
  return (
    <Container margin={props.margin} borderRadius={props.borderRadius}>
      <Card style={{ width: props.width, height: props.height, borderRadius: props.borderRadius }}>
        <Skeleton active width={50} />
      </Card>
    </Container>
  );
};

SkeletonCard.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  borderRadius: PropTypes.string
};

SkeletonCard.defaultProps = {
  width: '200px',
  height: '200px',
  margin: '20px',
  borderRadius: '16px'
};
