import React, { useState, useEffect } from 'react';
import { Modal } from 'antd/lib';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../../components/settings/Component.css';
import 'react-datepicker/dist/react-datepicker.css';
import './filterModel.css';
import get from 'lodash/get';
import _ from 'lodash';
import 'react-rangeslider/lib/index.css';
import "./es"
import "moment/locale/en-gb";
import Plot from 'react-plotly.js';
import isEmpty from 'lodash/isEmpty';
import { ErrorModal } from './ErrorModal';
import { iff } from '../../../utils/iff';

const StyledModal = styled(Modal)`
  .ant-input-number {
    font-family: ${(props) => get(props, 'theme.font.family')};
    color: ${(props) => get(props, 'theme.colors.buttonHoverColor')};
  }
  .ant-input-number:focus {
    border-color: ${(props) => get(props, 'theme.colors.buttonHoverBgColor')} !important;
    box-shadow: 0 0 0 2px ${(props) => get(props, 'theme.colors.selectBoxshadowColor')} !important;
  }
  .ant-input-number:hover {
    border-color: ${(props) => get(props, 'theme.colors.buttonHoverBgColor')} !important;
  }
  .ant-input-number-focused {
    border-color: ${(props) => get(props, 'theme.colors.buttonHoverBgColor')} !important;
    box-shadow: 0 0 0 2px ${(props) => get(props, 'theme.colors.selectBoxshadowColor')} !important;
  }
  .ant-input-number-input{
    background-color: #f5f8fc;
  }
  .percentag {
    color: ${(props) => get(props, 'theme.colors.buttonHoverColor')};
    background: #f5f8fc;
  }

  .rangeslider {
    display: block;
    box-shadow: none;
    font-family: ${(props) => get(props, 'theme.font.family')};
  }
  .rangeslider-horizontal .rangeslider__handle {
    width: 25px;
    height: 25px;
    border-color: ${(props) => get(props, 'theme.colors.primary')};
    box-shadow: 0 0 0 2px ${(props) => get(props, 'theme.colors.selectBoxshadowColor')};
    border: 3px solid;
  }
  .rangeslider-horizontal .rangeslider__handle:focus {
    border-color: ${(props) => get(props, 'theme.colors.primary')} !important;
    box-shadow: 0 0 0 2px ${(props) => get(props, 'theme.colors.selectBoxshadowColor')} !important;
  }
  .rangeslider-horizontal .rangeslider__handle:after {
    background-color: transparent;
    box-shadow: none;
  }
  .rangeslider__handle:focus {
    outline: none;
  }
  .rangeslider-horizontal .rangeslider__fill {
    background-color: ${(props) => get(props, 'theme.colors.primary')};
  }

  .rangeslider .rangeslider__handle {
    box-shadow: none;
    border: 2px solid ${(props) => get(props, 'theme.colors.primary')}; !important;
  }
  .rangeslider .rangeslider__fill {
    box-shadow: none;
  }
  .rangeslider-horizontal {
    height: 4px;
  }
  .ant-modal-title {
    font-size: 20px;
    color: #f9c43d;
    font-family: ${(props) => get(props, 'theme.font.family')};
    font-style: normal;
  }

  .ant-modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    border-bottom: 3px solid #f74141;
    border-radius: 8px 4px 0 0;
    background:#F2F4F6;
  }

  .ant-modal-header {
    border-bottom: 2px solid #faad14db;
  }
  .ant-modal-body {
    padding-top:5px !important;
  }
  .cloudCover_value{
    color: ${(props) => get(props, 'theme.colors.primary')} !important;
  }
  .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: #CCD0D7;
`;

export const BoxPlot = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [boxPlotView, setBoxPlotView] = useState(true);

  useEffect(() => {
    if (!isEmpty(props.productivityZonesAPIResponse)) {
      let percentValue = props.productivityZonesAPIResponse.data.content.features;
      let data = []
      if (percentValue && percentValue.length > 0) {
        percentValue?.map((value) => {
          let payload = {
            y: Object.values(value.properties.percentiles),
            type: 'box',
            name: `Zone-${(value.properties.group)}`,
            marker: { color: getFarmZoneColor(Math.round(props.colorMultiplier * (value.properties.group - 1))) },
            group: value.properties.group
          }
          data.push(payload);
        })
        const sortData = _.sortBy(data, 'group');
        setData(sortData);
      } else {
        setErrorMessage('Something went wrong');
      }
    } else {
      setBoxPlotView(false);
      setErrorMessage('Something went wrong');
    }
  }, [props.productivityZones])

  const getFarmZoneColor = (zone) => {
    switch (zone) {
      case 0:
        return '#FF0000';
      case 1:
        return '#FF2300';
      case 2:
        return '#FF4600';
      case 3:
        return '#FF6900';
      case 4:
        return '#FF8C00';
      case 5:
        return '#FFAF00';
      case 6:
        return '#FFD300';
      case 7:
        return '#FFF600';
      case 8:
        return '#E5FF00';
      case 9:
        return '#C2FF00';
      case 10:
        return '#9FFF00';
      case 11:
        return '#7CFF00';
      case 12:
        return '#58FF00';
      case 13:
        return '#35FF00';
      case 14:
        return '#00FF00';
      default:
        return '#00FF00';
    }
  };

  const layout = {
    showlegend: true,
    yaxis: { range: [0, 1], title: 'NDVI' },
    width: 475,
    height: 380
  }
  return (
    <>
      {iff(
        errorMessage ? true : false,
        <ErrorModal
          data-testid='boxplot-error-msg'
          visible={errorMessage ? true : false}
          message={t(errorMessage)}
          okText={t('Ok')}
          onOk={() => {
            setErrorMessage(null);
            setBoxPlotView(true)
          }}
        />,
        <div data-testid='Box-Plot-View'>
          <StyledModal
            title={t('Management Zone')}
            style={{ top: '15%', color: '#F9C43D' }}
            visible={boxPlotView}
            onCancel={props.onCancel}
            closable={true}
            okButtonProps={{
              hidden: true
            }}
            cancelButtonProps={{
              hidden: true
            }}
            width={540}
            height={500}
          >
            <Plot
              data-testid='plotViewBox'
              data={data}
              layout={layout}
            />
          </StyledModal>
        </div>
      )}
    </>
  )
}

BoxPlot.propTypes = {
  onCancel: PropTypes.func,
  productivityZonesAPIResponse: PropTypes.object,
  colorMultiplier: PropTypes.number,
  productivityZones: PropTypes.object,

};
