import amplitude from 'amplitude-js';

class Amplitude {
  constructor() {
    const AMPLITUDE_API_KEY = process.env.AMPLITUDE_API_KEY;
    amplitude.getInstance().init(AMPLITUDE_API_KEY);
    this.amplitudeInstance = amplitude.getInstance();
  }

  onLogin(userData) {
    this.loggedInUserData = userData;
    this.amplitudeInstance.setUserId(userData.email);
    this.amplitudeInstance.logEvent('onLogin', userData);
  }

  onLoginFromNidera(userData) {
    this.loggedInUserData = userData;
    this.amplitudeInstance.setUserId(userData.email);
    this.amplitudeInstance.logEvent('onLoginFromNidera', userData);
  }

  onApplyFilter(filterPayload) {
    const _filterData = { field_id: filterPayload.field_id, filter: filterPayload.filter };
    this.amplitudeInstance.logEvent('onApplyFilter', _filterData);
  }

  onModifyFilter() {
    this.amplitudeInstance.logEvent('onModifyFilter');
  }

  onImageSelection(image_id) {
    const image = { image_id: image_id };
    this.amplitudeInstance.logEvent('onImageSelection', image);
  }

  onImageDeselection(image_id) {
    const image = { image_id: image_id };
    this.amplitudeInstance.logEvent('onImageDeselection', image);
  }

  onNDVIandRGBImageSelect(image_id) {
    const image = { image_id: image_id };
    this.amplitudeInstance.logEvent('onNDVIandRGBImageSelect', image);
  }

  onCreateCluster(clusterData) {
    const _clusterData = {
      image_ids: clusterData.image_ids,
      zone: clusterData.zone
    };
    this.amplitudeInstance.logEvent('onCreateCluster', _clusterData);
  }

  onRemoveOutlier(removeOutlierData) {
    const _removeOutlierData = {
      image_ids: removeOutlierData.image_ids,
      zone: removeOutlierData.zone,
      threshold_in_px: removeOutlierData.threshold_in_px
    };
    this.amplitudeInstance.logEvent('onRemoveOutlier', _removeOutlierData);
  }

  onAddNewFieldUsingBoundary() {
    this.amplitudeInstance.logEvent('onAddNewFieldUsingBoundary');
  }

  onDrawAnotherInAddNewFieldUsingBoundary() {
    this.amplitudeInstance.logEvent('onDrawAnotherInAddNewFieldUsingBoundary');
  }

  onSaveInAddNewFieldUsingBoundary(shape) {
    const _shape = { shape: shape };
    this.amplitudeInstance.logEvent('onSaveInAddNewFieldUsingBoundary', _shape);
  }

  onAddNewFieldUsingShapeFile() {
    this.amplitudeInstance.logEvent('onAddNewFieldUsingShapeFile');
  }

  onSaveInAddNewFieldUsingShapeFile() {
    this.amplitudeInstance.logEvent('onSaveInAddNewFieldUsingShapeFile');
  }

  detectFieldEvents(eventData) {
    this.amplitudeInstance.logEvent('detectFieldEvents', eventData);
  }

  onExport() {
    this.amplitudeInstance.logEvent('onExport');
  }

  onDownload() {
    this.amplitudeInstance.logEvent('onDownload');
  }

  onLogout() {
    this.amplitudeInstance.logEvent('onLogout');
    this.amplitudeInstance.setUserId(null);
    this.amplitudeInstance.regenerateDeviceId();
  }
}

export const AmplitudeInstance = new Amplitude();
