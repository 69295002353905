export const drawingControls = {
  draw: {
    polygon: false,
    polyline: false,
    rectangle: false,
    circle: false,
    marker: false,
    circlemarker: false
  }
};
