import { request } from '../utils/axios';
import { getHeaders } from '../utils/common-methods';
import {
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_ERROR,
  FETCH_USER_PROFILE_LOADING
} from './types';

export function fetchUserProfileLoading() {
  return {
    type: FETCH_USER_PROFILE_LOADING
  };
}

export function fetchUserProfileError(error) {
  return {
    type: FETCH_USER_PROFILE_ERROR,
    payload: error
  };
}

export function fetchUserProfileSuccess(user) {
  return {
    type: FETCH_USER_PROFILE_SUCCESS,
    payload: user
  };
}

export const fetchUserProfile = () => {
  return (dispatch) => {
    dispatch(fetchUserProfileLoading());
    request({
      method: 'get',
      url: `/v2/accounts/me`,
      headers: getHeaders()
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(fetchUserProfileSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchUserProfileError(error));
      });
  };
};
