import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { ThemedApp } from './components/App/App';
import { store } from './reducer/store';
import './components/Maps/mapInit';
import { AppLoader } from './AppLoader';
import { Loader } from '../src/components/Loader/Loader';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: process.env.SENTRY_DSN });
}

(async function loadAppLoader() {
  const myapp = new AppLoader();
  const isRedirecting = await myapp.load();
  if (!isRedirecting) {
    ReactDOM.render(
      <Provider store={store}>
        <ThemedApp />
      </Provider>,
      document.getElementById('root')
    );
  }
  if (location.href.indexOf('session_id') > 0 || localStorage.getItem('session_id')) {
    let faviconLink = document.getElementById('favicon');
    faviconLink.href = 'https://www.experiencianidera.com/apps/smv/QA/favicon.ico';
  } else {
    let faviconLink = document.getElementById('favicon');
    faviconLink.href = './favicon-cropwise.ico';
  }
})();

ReactDOM.render(
  <div>
    <Loader />
  </div>,
  document.getElementById('root')
);
