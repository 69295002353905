const geojsonCordinateToGooglemap = (coordinates) => {
  const cd = JSON.parse(JSON.stringify(coordinates));
  return cd.map((v) => v.reverse());
};

const geojsonCordinatePtsToGooglemap = (coordinates) => {
  const cd = JSON.parse(JSON.stringify(coordinates));
  return cd.map((v) => v.map((c) => c.reverse()).reverse());
};
export { geojsonCordinateToGooglemap, geojsonCordinatePtsToGooglemap };
