import axios from 'axios';

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
};
const key = process.env.CROPIO_KEY;

const cropioAPI = 'https://shapes-api.cropio.com/api/v1/shape/extent/';

export const getAllFields = async (lngMin, lngMax, latMin, latMax) => {
  let apiUrl = `${cropioAPI + lngMin}/${lngMax}/${latMin}/${latMax}?key=${key}`;
  return await axios.get(apiUrl, {
    headers
  });
};
