import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Icon, Menu, Popover } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import MenuStartChange from '../../public/menu-start-change-inactive.svg';
import OrganizationTabImg from '../../public/organization-tab.svg';
import LogoIcon from '../../public/cropwise-logo-border.svg';
import NideraLogo from '../../public/nidera_logo.png';
import HomeIcon from '../../public/Home-icon.svg';
import HelpIcon from '../../public/help-icon.svg';
import { HeaderDivider } from './divider';
import { iff } from '../../utils/iff';
import { LanguageDropdown } from '../../localization/LanguageDropdown';
import {
  StyledHeader,
  StyledImageContainer,
  StyledImage,
  StyledMenu,
  StyledInitialName,
  StyledMenuItem,
  StyledColHeader,
} from './styles';
import { getInitialName } from '../../utils/common-methods';
import styled from 'styled-components';

const StyledSpan = styled.span`
  font-family: ${props => get(props, 'theme.font.family')};
`;

const StyledAnchorTag = styled.a`
  color: #000;
  &:hover {
    color: ${props => get(props, 'theme.colors.backArrowBgColor')} !important;
  }
`;

let isSessionId;

export const CollapsedHeader = props => {
  const { t } = useTranslation();

  React.useEffect(() => {
    isSessionId = localStorage.getItem('session_id');
  }, []);
  const selectedOrg = props.orgs.find(o => o.id === props.orgId);
  const selectedProperty = props.properties.find(
    p => p.id === props.propertyId
  );
  const Usermenu = (
    <Menu
      style={{ background: '#F5F8FC', fontWeight: 500 }}
      className="popupmenuList"
    >
      <Menu.Item key="2" title="Supported Language">
        <LanguageDropdown type="FLAT" />
      </Menu.Item>
      <Menu.Item data-testid="logout-btn" key="3" title="Logout" onClick={() => props.handleLogout()}>
        <StyledAnchorTag className="logoutLink">
          <Icon type="poweroff" />
          <StyledSpan>{t('Logout')}</StyledSpan>
        </StyledAnchorTag>
      </Menu.Item>
    </Menu>
  );

  const emptyHeader = (
    <StyledHeader>
      <Row gutter={[8, 0]}>
        <StyledImageContainer span={2}>
          <StyledImage
            width="60px"
            height="38px"
            src={isSessionId ? NideraLogo : LogoIcon}
            cursor="pointer"
            onClick={() => props.onLogoClick()}
          />
        </StyledImageContainer>
        <Col>
          <Row
            type="flex"
            justify="space-around"
            align="middle"
            style={{
              paddingRight: '12px',
              paddingTop: '8px',

              paddingBottom: '3px',
              float: 'right',
            }}
          >
            <StyledImage
              height="20px"
              width="20px"
              src={HelpIcon}
              style={{ marginRight: '20px' }}
            />
            <Popover
              className="rightMenuPopoverbtn"
              content={Usermenu}
              placement="bottomRight"
              style={{ marginRight: '20px' }}
            >
              <StyledInitialName>
                {getInitialName(props.userProfile.name)}
              </StyledInitialName>
            </Popover>
          </Row>
        </Col>
      </Row>
    </StyledHeader>
  );

  return iff(
    props.header === null,
    emptyHeader,
    <StyledHeader collapsed={true}>
      <Row gutter={[8, 0]}>
        <StyledColHeader span={20}>
          <StyledImage
            data-testid="cropwise-logo"
            width="60px"
            height="38px"
            src={isSessionId ? NideraLogo : LogoIcon}
            cursor="pointer"
            onClick={() => props.onLogoClick()}
            style={{
              marginRight: '8px',
              marginTop: '4px',
            }}
          />
          <StyledMenu
            type="flex"
            justify="start"
            align="top"
            className="content"
          >
            {iff(
              !!selectedOrg && !!selectedProperty,
              <StyledMenuItem
                onClick={() =>
                  props.navigateByPropertyId(props.orgId, props.propertyId)}
                image={MenuStartChange}
                middle={'true'}
              >
                <StyledImage
                  width="40px"
                  height="40px"
                  src={isSessionId ? HomeIcon : OrganizationTabImg}
                />
                <div className="title_container">
                  <span className="title">{t('Property')}</span>
                  <span className="subTitle">
                    {selectedProperty?.name}
                  </span>
                </div>
              </StyledMenuItem>
            )}
          </StyledMenu>
        </StyledColHeader>

        <Col span={3} push={1}>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{
              paddingRight: '12px',
              paddingTop: '4px',
              paddingBottom: '3px',
              float: 'right',
            }}
          >
            <Popover
              className="rightMenuPopoverbtn"
              content={Usermenu}
              placement="bottomRight"
            >
              <StyledInitialName>
                {getInitialName(props.userProfile.name)}
              </StyledInitialName>
            </Popover>
          </Row>
          {process.env.envName !== 'production' &&
            <span
              style={{ position: 'absolute', right: '-13px', fontSize: '12px' }}
            >
              V0.20
            </span>}
        </Col>
      </Row>
      <HeaderDivider handleClick={() => props.toggleHeader(false)} />
    </StyledHeader>
  );
};

CollapsedHeader.propTypes = {
  orgs: PropTypes.array.isRequired,
  properties: PropTypes.array.isRequired,
  orgId: PropTypes.string,
  propertyId: PropTypes.string,
  navigateByOrgId: PropTypes.func,
  navigateByPropertyId: PropTypes.func,
  toggleHeader: PropTypes.func,
  onLogoClick: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  handleLogout: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
};
