import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { request } from '../utils/axios';
import { getHeaders } from '../utils/common-methods';
import { history, URL_CONSTANTS } from '../utils/history';

export const getFieldsByPropertyId = async (propId) => {
  const propertyRes = await request.get(`/v2/properties/${propId}/fields?attributes=geometry`, {
    headers: getHeaders()
  });
  return propertyRes;
};

export const createFieldByPropIdPromisify = async ({ property, name, geometry, declared_area }) => {
  const data = {
    name,
    property_id: property.id,
    parent_region_id: property.root_region_id,
    geometry,
    declared_area
  };

  return request.post(`${process.env.API_URL}/v2/fields`, data, {
    headers: getHeaders()
  });
};

export const createBulkFieldsByPropId = async ({
  parentId,
  newFields,
  orgId,
  farmId,
  addNewFieldFrom
}) => {
  try {
    const data = {
      version: 'v1',
      strict: true,
      relative_root: parentId,
      format: 'geojson',
      region_set: {
        type: 'FeatureCollection',
        features: newFields.map(({ id, name, geometry, adjustedSize }) => {
          return {
            type: 'Feature',
            id,
            geometry,
            properties: {
              name,
              parent_id: parentId,
              operation: 'create',
              id,
              declared_area: adjustedSize
            }
          };
        })
      }
    };

    const propertyRes = await request.post(`/v2/properties/${farmId}/fields/updates`, data, {
      headers: getHeaders()
    });
    if (!isEmpty(propertyRes) && propertyRes.status === 201) {
      if (propertyRes.data.status === 'REJECTED') {
        throw propertyRes.data.errors;
      }
      if (addNewFieldFrom === 'MapsContainer') {
        return history.push(URL_CONSTANTS.LANDING_FARM({ orgId, farmId }));
      }
      history.push(URL_CONSTANTS.ALL_FIELDS({ orgId, farmId }));
    }
  } catch (ex) {
    const response = ex.response;
    if (isEmpty(get(response, 'data.errors'))) {
      throw ex;
    }
    throw get(response, 'data.errors');
  }
};

export const editFieldById = async ({
  property,
  name,
  geometry,
  declared_area,
  orgid,
  fieldId,
  farmId
}) => {
  const data = {
    name,
    property_id: property.id,
    parent_region_id: property.root_region_id,
    geometry,
    declared_area,
    id: fieldId
  };
  const propertyRes = await request.put(`/v2/fields/${fieldId}`, data, {
    headers: getHeaders()
  });
  if (!isEmpty(propertyRes) && propertyRes.status === 200) {
    if (history.location.state && history.location.state.editFieldFrom === 'MapsContainer') {
      history.push(
        URL_CONSTANTS.LANDING_FARM({
          orgId: orgid,
          farmId: farmId
        })
      );
      return;
    }
    history.push(URL_CONSTANTS.ALL_FIELDS({ orgId: orgid, farmId: property.id }));
  }
};

export const getFieldById = async (id) => {
  const propertyRes = await request.get(`/v2/fields/${id}`, {
    headers: getHeaders()
  });
  return propertyRes;
};

export const deleteFieldByFieldId = async (fieldId) => {
  await request.delete(`/v2/fields/${fieldId}`, {
    headers: getHeaders()
  });
};

export const createBulkFieldsByPropIdForDetectedFields = async ({
  parentId,
  newFields,
  farmId
}) => {
  try {
    const data = {
      version: 'v1',
      strict: false,
      relative_root: parentId,
      format: 'geojson',
      region_set: {
        type: 'FeatureCollection',
        features: newFields.map(({ id, name, geometry, adjustedSize }) => {
          return {
            type: 'Feature',
            id,
            geometry,
            properties: {
              name,
              parent_id: parentId,
              operation: 'create',
              id,
              declared_area: adjustedSize
            }
          };
        })
      }
    };

    const propertyRes = await request.post(
      `${process.env.API_URL}/v2/properties/${farmId}/fields/updates`,
      data,
      {
        headers: getHeaders()
      }
    );
    if (!isEmpty(propertyRes) && propertyRes.status === 201) {
      if (propertyRes.data.status === 'REJECTED') {
        throw propertyRes.data.errors;
      }
    }
    return propertyRes;
  } catch (ex) {
    const response = ex.response;
    if (isEmpty(get(response, 'data.errors'))) {
      throw ex;
    }
    throw get(response, 'data.errors');
  }
};
