import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Layout } from 'antd/lib';
import { useSelector } from 'react-redux';
import { HeaderContainerComponent } from '../header/container';
import { isHeaderCollapsed } from '../../selectors/headerSelectors';
import { iff } from '../../utils/iff';

const { Content } = Layout;

const StyledLayout = styled(Layout)`
  height: 100%;
  min-height: 100%;
`;

const StyledContent = styled(Content)`
  background-color: #EFF1F5;
  overflow-y: hidden;
  overflow-x: hidden;
  height: calc(100vh - 55px);
`;

export const PageLayout = props => {
  const isCollapsed = useSelector(isHeaderCollapsed);

  return (
    <StyledLayout>
      <HeaderContainerComponent
        isCollapsed={isCollapsed}
        header={props.header}
        setSelectedImges={props.setSelectedImges}
      />
      <StyledContent>{iff(!!props.content, props.content)}</StyledContent>
    </StyledLayout>
  );
};

PageLayout.propTypes = {
  header: PropTypes.node,
  content: PropTypes.node.isRequired,
  setSelectedImges: PropTypes.func,
};
