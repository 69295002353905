import styled from 'styled-components';
import { Button as AntdButton } from 'antd/lib';
import get from 'lodash/get';
import { iff } from '../../utils/iff';

export const Button = styled(AntdButton)`
  border-radius: 4px;
  width: 214px;
  height: 40px;
  margin: 0px 0;
  border: ${(props) => `1px solid ${get(props, `theme.colors.${props.type || 'primary'}`)}`};
  background-color: ${(props) => get(props, `theme.colors.${props.type || 'primary'}`)};
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => get(props, 'theme.fontSizes.medium')};
  line-height: 17px;
  color: ${(props) => {
    return iff(
      props.type === 'secondary',
      get(props, 'theme.colors.fontSecondaryColor'),
      get(props, 'theme.colors.pageBackgroundPrimary')
    );
  }};
  float: right;
  &.ant-btn-primary[disabled] {
    background-color: #c1c5c8;
    color: #e8eaed;
  }
  &:hover {
    background-color: ${(props) => get(props, 'theme.colors.fontColor')};
    border: 1px solid ${(props) => get(props, 'theme.colors.fontColor')};
  }
`;
