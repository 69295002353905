import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import qs from 'querystring';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Modal } from 'antd/lib';

import { fetchOrgs } from '../../actions/index';

import './Login.css';
import { request, headers } from '../../utils/axios';
import { Checkbox } from '../common/Checkbox';
import { Input } from '../common/Input';
import { SigninRectangleArrowIcon } from '../common/RectangleFocusIcon';
import { Label } from '../common/Label';
import { InputPassword } from '../common/InputPassword';
import { Button } from '../common/Button';
import { TitleBar } from '../common/TitleBar';
import { AnchorLink } from '../common/AnchorLink';
import { ErrorMessage } from '../common/ErrorMessage';
import { ErrorModal } from '../common/Modal/ErrorModal';
import { history, URL_CONSTANTS } from '../../utils/history';
import { Layout } from '../Layout/Layout';
import { iff } from '../../utils/iff';
import { isEmptyStr } from '../../utils/common-methods';
import { getErrorMessageByCode } from '../../utils/getErrorMessageByCode';
import { getParamValue } from '../../utils/getUrlParamsByName';
import { AppConstants } from '../../utils/app.constants';
import SigninRectangleArrow from '../../public/signin-rect.svg';
import { RenderGoogleButtonAndLoginLink } from '../common/RenderGoogleButtonAndLoginLink';
import { AmplitudeInstance } from '../../utils/amplitude';

const params = {
  clientId: 'client_id',
  grantType: 'grant_type',
  accessToken: 'access_token',
  refreshToken: 'refresh_token'
};
const Login = ({ fetchOrg }) => {
  const [isContinue, setIsContinue] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isChromeBrowser, setIsChromeBrowser] = useState(false);
  const [disableInputFields, setDisableInputFields] = useState({ email: false, pwd: false });

  useEffect(() => {
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if (!isChrome) {
      setIsChromeBrowser(true);
    }
  }, []);
  const { t } = useTranslation();
  const renderPassword = (formik) => {
    return (
      <>
        <Label>{t('Password')}</Label>
        <InputPassword
          disabled={disableInputFields.pwd}
          data-testid='passwordInput'
          id='password'
          name='password'
          value={formik.values.password.replace(/ /g, '')}
          type='password'
          onChange={formik.handleChange}
          placeholder={t('Password')}
          onKeyDown={() => formik.setFieldTouched('password', true)}
          onKeyPress={(e) => e.charCode === 32 && e.preventDefault()}
          autoFocus
          maxLength={AppConstants.PASSWORD_MAX_LENGTH}
        />
        {iff(
          formik.touched.password && isEmptyStr(formik.errors.password),
          <ErrorMessage>{formik.errors.password}</ErrorMessage>
        )}
      </>
    );
  };
  return (
    <Layout type='default'>
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        onSubmit={(values, formikBag) => {
          setDisableInputFields({ email: true, pwd: true });
          setDisableBtn(true);
          let userDetails = {
            [params.grantType]: 'password',
            username: values.email,
            password: values.password
          };
          const config = {
            headers
          };

          request
            .post(`/oauth/token`, qs.stringify(userDetails), config)
            .then((res) => {
              formikBag.setSubmitting(false);
              let tokens = {
                [params.accessToken]: res.data.access_token,
                [params.refreshToken]: res.data.refresh_token
              };
              AmplitudeInstance.onLogin({ email: values.email });
              localStorage.setItem('tokens', JSON.stringify(tokens));
              fetchOrg()
                .then((response) => {
                  const orgs = get(response, 'data.content') || [];
                  const refUrl = getParamValue('ref');
                  if (refUrl) {
                    return history.push(atob(refUrl));
                  } else if (orgs.length > 1 || isEmpty(orgs)) {
                    return history.push(URL_CONSTANTS.LANDING_ORGANIZATION({ orgId: orgs[0].id }));
                  } else {
                    return history.push(URL_CONSTANTS.LANDING_ORGANIZATION({ orgId: orgs[0].id }));
                  }
                })
                .catch((error) => {
                  formikBag.setSubmitting(false);
                  if (get(error, 'response.data.error')) {
                    setErrorMessage(
                      getErrorMessageByCode(get(error, 'response.data.error_description'))
                    );
                  }
                });
            })
            .catch((error) => {
              formikBag.setSubmitting(false);
              if (error.response) {
                let errorText = get(error, 'response.data.error_description');
                setErrorMessage(t(errorText));
              } else {
                setErrorMessage(t('Request time out, Please try again.'));
              }
              setDisableBtn(false);
              setDisableInputFields({ email: false, pwd: false });
            });
        }}>
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <TitleBar>{t('Sign in to Base')} </TitleBar>
            <ErrorModal
              visible={!!errorMessage}
              message={errorMessage}
              okText={t('Ok')}
              onOk={() => {
                setErrorMessage('');
              }}
            />

            <SigninRectangleArrowIcon>
              <img src={SigninRectangleArrow} alt="SigninRectangleArrow" />
            </SigninRectangleArrowIcon>
            <Label data-testid='label'>{t('Email')}</Label>
            <Input
              disabled={disableInputFields.email}
              data-testid='inputId'
              id='email'
              name='email'
              type='primary'
              value={formik.values.email.replace(/ /g, '')}
              onChange={formik.handleChange}
              onKeyDown={() => formik.setFieldTouched('email', true)}
              onKeyPress={(event) => {
                if (event.charCode === 32) {
                  event.preventDefault();
                } else if (
                  event.key === 'Enter' &&
                  isContinue === false &&
                  formik.touched.email &&
                  !isEmptyStr(formik.errors.email)
                ) {
                  setIsContinue(true);
                }
              }}
              placeholder={t('Email')}
              autoFocus
              maxLength={AppConstants.EMAIL_MAX_LENGTH}
            />
            {iff(
              isContinue === true,
              <>
                {renderPassword(formik)}
                <div className='login-checkbox'>
                  <AnchorLink
                    style={{ float: 'right' }}
                    onClick={() =>
                      history.push({
                        pathname: URL_CONSTANTS.RECOVERY_PASSWORD(),
                        state: { email: formik.values.email }
                      })
                    }
                    data-testid="forgot-password-link">
                    {t('Oh no, I forgot my password')}
                  </AnchorLink>
                </div>
                <div className='login-form-bottom-section'>
                  <div className='login-signin-button' style={{ padding: '5% 0' }}>
                    <Checkbox style={{ margin: '10px 0' }} className='login-form-keep-loggedIn'>
                      {t('Keep me logged in')}
                    </Checkbox>
                    <Button
                      style={{ width: 147 }}
                      data-testid='signIn'
                      type='primary'
                      htmlType='submit'
                      disabled={
                        (formik.touched.email && isEmptyStr(formik.errors.email)) ||
                        !formik.touched.email ||
                        (formik.touched.password && isEmptyStr(formik.errors.password)) ||
                        !formik.touched.password ||
                        disableBtn
                      }
                      loading={formik.isSubmitting}>
                      {t('Sign in')}
                    </Button>
                  </div>
                </div>
              </>,
              <Button
                type='primary'
                data-testid='continueButton'
                disabled={
                  (formik.touched.email && isEmptyStr(formik.errors.email)) || !formik.touched.email
                }
                style={{ margin: '10px 0 118px 0' }}
                onClick={() => setIsContinue(true)}>
                {t('Continue')}
              </Button>
            )}

            <div className='login-other-option'>
              <RenderGoogleButtonAndLoginLink isLogin={false} />
            </div>
          </form>
        )}
      </Formik>
      {iff(
        !localStorage.getItem('BrowserNotification'),
        <Modal
          width={350}
          visible={isChromeBrowser}
          title={'Browser Notification'}
          onOk={() => {
            setIsChromeBrowser(false);
            localStorage.setItem('BrowserNotification', 'true');
          }}
          onCancel={() => setIsChromeBrowser(false)}>
          <p data-testid="chrome-notification" id="chrome-notification">{'our application runs smooth on chrome'}</p>
        </Modal>
      )}
    </Layout>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  fetchOrg: () => dispatch(fetchOrgs())
});

Login.propTypes = {
  fetchOrg: PropTypes.func
};

export const LoginComponent = withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
