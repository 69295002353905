import last from 'lodash/last';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';

export const isAccessTokenAvailable = () => !!localStorage.getItem('tokens');
export const isSessionIDAvailable = () => !!localStorage.getItem('session_id');
export const setAccessToken = (token) => localStorage.setItem('tokens', token);
export const getAccessToken = () => localStorage.getItem('tokens');
export const clearAccessToken = () => localStorage.removeItem('tokens');

export const getProgressByValues = (isPasswordValid, isMobileNumberValid, termsCheckbox) => {
  let count = 1;
  if (isPasswordValid) {
    count++;
  }
  if (isMobileNumberValid) {
    count++;
  }
  if (termsCheckbox) {
    count++;
  }
  return count;
};

export const isEmptyStr = (str) => {
  return typeof str === 'string' && str.length > 0;
};

export const getHeaders = () => ({
  common: {
    Authorization: `Bearer ${JSON.parse(getAccessToken()).access_token}`
  }
});

export const getInitialName = (name) => {
  if (isEmpty(name)) {
    return '';
  }
  const splitedNames = name.split(' ');
  const firstName = first(splitedNames) || '';
  const lastName = last(splitedNames) || '';
  if (splitedNames.length >= 2) {
    return `${firstName.charAt(0).toUpperCase()} ${lastName.charAt(0).toUpperCase()}`;
  }
  return firstName.charAt(0).toUpperCase();
};

export const dataDecode = (data) => {
  let s1 = data.replace(/%5B/g, '[');
  let s2 = s1.replace(/%7B/g, '{');
  let s3 = s2.replace(/%22/g, '"');
  let s4 = s3.replace(/%3A/g, ':');
  let s5 = s4.replace(/%2C/g, ',');
  let s6 = s5.replace(/%23/g, '#');
  let s7 = s6.replace(/%5D/g, ']');
  let s8 = s7.replace(/%7D/g, '}');
  return JSON.parse(s8);
};

export const dataEncode = (decodedData) => {
  let data = JSON.stringify(decodedData);
  let s1 = data.replace(/\[/g, '%5B');
  let s2 = s1.replace(/{/g, '%7B');
  let s3 = s2.replace(/"/g, '%22');
  let s4 = s3.replace(/:/g, '%3A');
  let s5 = s4.replace(/,/g, '%2C');
  let s6 = s5.replace(/#/g, '%23');
  let s7 = s6.replace(/]/g, '%5D');
  let s8 = s7.replace(/}/g, '%7D');
  return s8;
};
