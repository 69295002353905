import React from 'react';
import { Modal } from 'antd/lib';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { iff } from '../../../utils/iff';
import get from 'lodash/get';
import { ButtonFontFamily } from '../../../utils/styles.theming'

const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    border-bottom: 3px solid #f74141;
    border-radius: 4px 4px 0 0;
  }
  .ant-modal-footer {
    border-top: none;
  }
  .ant-modal-title {
    font-family: ${(props) => get(props, 'theme.font.family')};
  }
`;

const Styledp = styled.p`
  font-family: ${(props) => get(props, 'theme.font.family')};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #2f3031;
`;

export const ErrorModal = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <StyledModal
        title={t('Error')}
        visible={props.visible}
        closable={false}
        width={320}
        footer={[
          <>
            {iff(
              props.cancelText !== undefined,
              <ButtonFontFamily key={1} type='danger' onClick={props.onCancelClick}>
                {props.cancelText}
              </ButtonFontFamily>
            )}
            <ButtonFontFamily key={1} type='danger' onClick={props.onOk}>
              {props.okText}
            </ButtonFontFamily>
          </>
        ]}>
        <Styledp>{props.message}</Styledp>
      </StyledModal>
    </div>
  );
};
ErrorModal.propTypes = {
  visible: PropTypes.bool,
  message: PropTypes.string,
  okText: PropTypes.string,
  onOk: PropTypes.func,
  cancelText: PropTypes.string,
  onCancelClick: PropTypes.func
};

ErrorModal.defaultProps = {
  visible: false,
  message: 'Something went wrong',
  okText: 'Ok',
  cancelText: undefined,
  onOk: () => null,
  onCancelClick: () => null
};
