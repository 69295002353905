import React from 'react';
import { Carousel } from 'antd/lib';
import PropTypes from 'prop-types';
import {
  FieldListPopupContainer,
  StyledFieldListItem,
  StyledButton,
  EditStyledButton
} from './FieldListPopup.styles';
import { iff } from '../../../utils/iff';
import edit_withoutcircle from '../../../public/edit_withoutcircle.svg';
import { UpOutlined, DownOutlined } from '@ant-design/icons';

export const FieldListPopup = ({
  fields = [],
  selectedFieldId,
  handleFieldClick,
  onEditClick,
}) => {
  const carouselRef = React.useRef();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const getActiveIndex = index => {
    setActiveIndex(index);
  };
  const handleNext = () => carouselRef.current.next();
  const handlePrev = () => carouselRef.current.prev();

  return (
    <FieldListPopupContainer data-testid="field-list-popup-container">
      {iff(
        fields.length > 8,
        <StyledButton
          data-testid="field-list-popup-nextBtn"
          disabled={(activeIndex + 1) === (Math.ceil(fields.length / 8))}
          onClick={handleNext}
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <UpOutlined />
        </StyledButton>
      )}
      <Carousel
        dots={false}
        ref={carouselRef}
        rows={8}
        infinite={false}
        dotPosition="left"
        afterChange={getActiveIndex}
      >
        {fields?.map(item => {
          return (
            <StyledFieldListItem
              key={item.name}
              className={iff(selectedFieldId === item.id, 'active')}
              onClick={e => {
                e.stopPropagation();
                handleFieldClick(item.id);
              }}
              style={{
                background: '#f2f4f6',
                color: 'white',
              }}
              data-testid="field-list-item"
            >
              <div data-testid={`field-list-item-${item.name}`} className={selectedFieldId === item.id ? "filedList_innerSection border_bottom" : "filedList_innerSection"}>
                {item.name}{' '}
              </div>
              <EditStyledButton
                className="edit_icon edit_hover"
                onClick={() => onEditClick(item.id)}
                data-testid={`edit-1-field-list-item-${item.name}`}>
                <img src={edit_withoutcircle} alt="edit_withoutcircle" />
              </EditStyledButton>
            </StyledFieldListItem>
          );
        })}
      </Carousel>
      {iff(
        fields.length > 8,
        <StyledButton
          data-testid="field-list-popup-prevBtn"
          disabled={activeIndex === 0}
          onClick={handlePrev}
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <DownOutlined />
        </StyledButton>
      )}
    </FieldListPopupContainer>
  );
};

FieldListPopup.propTypes = {
  fields: PropTypes.array,
  selectedFieldId: PropTypes.string,
  handleFieldClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func,
};
