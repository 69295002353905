import { createSelector } from 'reselect';

export const getHeader = (state) => state.header || {};

export const isHeaderCollapsed = createSelector(getHeader, (header) => header.collapsed);

export const getSelectedOrganization = createSelector(
  getHeader,
  (header) => header.selectedOrganization
);

export const getSelectedProperty = createSelector(getHeader, (header) => header.selectedProperty);
