import React, { Component } from 'react';
import { Menu, Dropdown, Icon, Radio, Button } from 'antd/lib';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';

let langs = [
  { key: 'en', label: 'English (USA)', shortLabel: 'EN' },
  { key: 'es', label: 'Spanish', shortLabel: 'ES' }
];
const StyledMainDiv = styled.div`
  .ant-radio-group {
    display: flex;
  }
`;
const StyledDiv = styled.div`
  label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    color: ${(props) => get(props, 'theme.colors.sideBarSpinColor')};
    border-color: ${(props) => get(props, 'theme.colors.sideBarSpinColor')};
    box-shadow: -1px 0 0 0 ${(props) => get(props, 'theme.colors.sideBarSpinColor')};
    font-family: ${(props) => get(props, 'theme.font.family')} !important;
  }
  .ant-radio-button-wrapper {
    &:hover {
      color: ${(props) => get(props, 'theme.colors.buttonHoverColor')};
    }
  }
`;
function isLangCodeValid(key) {
  let A = langs.filter((v) => {
    return v.key === key;
  });
  return A.length === 1;
}
export class LanguageDropdown extends Component {
  constructor(props) {
    super(props);
    let sl = localStorage.getItem('i18nextLng') || 'en';
    if (isLangCodeValid(sl) === false) {
      sl = 'en';
    }
    this.state = {
      selected: sl
    };
    this.onClick = this.onClick.bind(this);
  }

  getSelectedLangCode() {
    const s = langs.filter((v) => {
      return v.key === this.state.selected;
    });
    if (s.length !== 0) {
      return s[0].key;
    } else {
      return undefined;
    }
  }

  getSelectedLangLabel() {
    const s = langs.filter((v) => {
      return v.key === this.state.selected;
    });
    if (s.length !== 0) {
      return s[0].label;
    } else {
      return undefined;
    }
  }
  onClick(event) {
    let key;
    if (this.props.type === 'DROPDOWN') {
      key = event.key;
    } else {
      key = event.target.value;
    }
    this.setState({
      selected: key
    });
    i18n.changeLanguage(key);
  }

  renderMultiLanguageOptions() {
    const { type } = this.props;
    const menu = (
      <Menu onClick={this.onClick} data-testid="sign-in-page-language-dd-menu">
        {langs.map((v) => {
          return <Menu.Item data-testid={`langauge-${v.key}`} key={v.key}>{v.label}</Menu.Item>;
        })}
      </Menu>
    );
    switch (type) {
      case 'DROPDOWN':
        return (
          <div style={{ textAlign: 'right' }}>
            <Dropdown overlay={menu} data-testid="sign-in-page-language-dd">
              <Button
                style={{ color: '#8E9293' }}
                className='ant-dropdown-link'
                onClick={(e) => e.preventDefault()}>
                {this.getSelectedLangLabel()} <Icon type='down' />
              </Button>
            </Dropdown>
          </div>
        );
      case 'FLAT':
        return (
          <StyledMainDiv>
            <Radio.Group onChange={this.onClick} defaultValue={this.getSelectedLangCode()}>
              {langs.map((v, i) => {
                return (
                  <StyledDiv key={v.key} data-testid={`langauge-${v.key}`}>
                    <Radio.Button key={v.key} value={v.key}>
                      {v.shortLabel}
                    </Radio.Button>
                  </StyledDiv>
                );
              })}
            </Radio.Group>
          </StyledMainDiv>
        );
      default:
        break;
    }
  }
  render() {
    return <div>{this.renderMultiLanguageOptions()}</div>;
  }
}

LanguageDropdown.propTypes = {
  type: PropTypes.string
};
