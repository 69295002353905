import { createBulkFieldsByPropIdForDetectedFields } from '../actions/fields';
import { getPropertyDetailsById } from '../actions/index';

export const addDetectedFields = async (fieldList, propId) => {
  return await getPropertyDetailsById(propId.toString().replace('detected', '')).then(
    (propertyRes) => {
      return createBulkFieldsByPropIdForDetectedFields({
        parentId: propertyRes.data.root_region_id,
        newFields: fieldList,
        farmId: propertyRes.data.id
      });
    }
  );
};
