{
    "en": {
        "translation": {
            "A message has been sent to your email.": "A message has been sent to your email.",
            "Account already exists": "This account already exists",
            "Account already registered": "Account already registered",
            "Account does not exist": "Email address not registered, please sign up",
            "Account not registered, please sign up": "Account not registered, please sign up",
            "Actions": "Actions",
            "Active seasons": "Active seasons",
            "Add managed user": "Add managed user",
            "Add New Field": "Add New Field",
            "Address": "Address",
            "Adjusted size": "Adjusted size",
            "All fields": "All fields",
            "All of your progress will be lost.": "All of your progress will be lost.",
            "Allow Cropwise to send offers to my email": "Allow Cropwise to send offers to my email",
            "Allow Cropwise to send offers to my mobile number": "Allow Cropwise to send offers to my mobile number.",
            "Already have an account?": "Already have an account?",
            "Already have an account": "Already have an account",
            "Analytics": "Analytics",
            "Apply": "Apply",
            "Apply to all selected fields": "Apply to all selected fields",
            "Apr ": "Apr ",
            "April": "April",
            "Are you sure you want to cancel?": "Are you sure you want to cancel?",
            "Are you sure you want to delete": "Are you sure you want to delete",
            "Area": "Area",
            "At least 8 characters": "At least 8 characters",
            "At least one season needs to be selected": "At least one season needs to be selected",
            "Aug": "Aug",
            "August": "August",
            "Automatic":"Automatic",
            "Back": "Back",
            "Basic info": "Basic info",
            "Box Plot":"Box Plot",
            "Cancel": "Cancel",
            "Cancel property creation": "Cancel property creation",
            "Cannot change crop if there are fields linked to it": "This crop change can't be done if there are fields linked it. Please review your season's field.",
            "Cannot create field. It already exist": "Field already exist. Please review your fields",
            "Change the information below to edit your season": "Change the information below to edit your season",
            "Choose a city": "Choose a city",
            "Choose a country": "Choose a country",
            "City": "City",
            "click here": "click here",
            "Click the link below to access the page where you can enter a new password of your choice.": "Click the link below to access the page where you can enter a new password of your choice.",
            "Confirm": "Confirm",
            "Confirm new password": "Confirm new password",
            "Continue": "Continue",
            "Country": "Country",
            "Create Account": "Create Account",
            "Create fields": "Create fields",
            "Create fields to set up your first farm": "Create fields to set up your first farm",
            "Create new field": "Create new field",
            "Create new password": "Create new password",
            "Create new property": "Create new property",
            "Create new season": "Create new season",
            "Create Organization": "Create Organization",
            "Create Property": "Create Property",
            "Create season": "Create Season",
            "Create user": "Create user",
            "Create your account": "Create your account",
            "Creaty boundary": "Creaty boundary",
            "crops": "crops",
            "Crops": "Crops",
            "Currency & System of units": "Currency & System of units",
            "Current and new password should be different": "Current and new password should be different. Please type a different one.",
            "Current password": "Current password",
            "Delete polygons or small areas.The entered value indicates the minimum surface area (in m2) accepted for a polygon or area": "Delete polygons or small areas.The entered value indicates the minimum surface area (in m2) accepted for a polygon or area.",
            "Data Not Found": "Data Not Found",
            "Dec": "Dec",
            "December": "December",
            "Delete": "Delete",
            "Delete property": "Delete property",
            "Discard": "Discard",
            "Don't have an account yet?": "Don't have an account yet?",
            "Done": "Done",
            "Drag and drop": "Drag and drop",
            "Drag and drop your files anywhere on the screen": "Drag and drop your files anywhere on the screen",
            "Draw circle": "Draw circle",
            "Draw polygon": "Draw polygon",
            "Draw your fields": "Draw your fields",
            "Draw your property`s fields with the drawing tools.": "Draw your propertie's fields with the drawing tools.",
            "Drawing tools": "Drawing tools",
            "Edit": "Edit",
            "Edit fields": "Edit fields",
            "Edit Organization": "Edit Organization",
            "Edit Property": "Edit Property",
            "Delete Property": "Delete Property",
            "Edit Season": "Edit Season",
            "Email": "Email",
            "Email address not registered, please sign up": "Email address not registered, please sign up",
            "Email address or password are incorrect": "Email address or password are incorrect",
            "Email already registered": "This email is already registered",
            "Email/login": "Email/login",
            "Employer Identification Number": "Employer Identification Number",
            "End": "End",
            "Enter a valid email address": "Enter a valid email address",
            "Enter a valid email or mobile number": "Enter a valid email or mobile number",
            "Enter a valid mobile number": "Enter a valid mobile number",
            "Enter your mobile number or email below.": "Enter your mobile number or email below.",
            "Explore the platform": "Explore the platform",
            "fair password": "fair password",
            "Feb": "Feb",
            "February": "February",
            "Field name": "Field name",
            "Field name already exists for this Property": "This field name already exist. Please choose another one",
            "Field size": "Field size",
            "Fill in the fields below to create your season": "Fill in the fields below to create your season",
            "Find file": "Find file",
            "Find the files on your computer": "Find the files on your computer",
            "Finish": "Finish",
            "For Internal Users Only":"For Internal Users Only",
            "Geometry Points regulator": "Geometry Points regulator",
            "Get 1200pts before December 31th to get the Silver level rewards": "Get 1200pts before December 31th to get the Silver level rewards",
            "Get started and discover what Base can do for you": "Get started and discover what Base can do for you",
            "Give your season a name": "Give your season a name",
            "Harvesting": "Harvesting",
            "Harvesting date is not between season interval": "The harvesting date isn't between season dates. Please review your dates",
            "Harvesting date must be after planting date": "The harvesting date must be after planting date. Please review your dates",
            "Hello": "Hello ",
            "Hey": "Hey ",
            "I have read and agree with the": "I have read and agree with the",
            "If you no longer want to change your password, just ignore this message.": "If you no longer want to change your password, just ignore this message.",
            "Image will be shown in many places of the platform.": "Image will be shown in many places of the platform.",
            "Inactive Seasons": "Inactive Seasons",
            "Incorrect password": "Incorrect password",
            "Invalid email": "Enter a valid email address",
            "Invalid phone number": "Informe um número de celular válido",
            "Invalid property Coordinates": "Invalid coordinates. Please type a valid one",
            "Invalid variety": "This variety doesn't macth with the season'crop. Please choose another one",
            "Invite this user": "Invite this user",
            "Invite user": "Invite user",
            "Jan": "Jan",
            "January": "January",
            "Jul": "Jul",
            "July": "July",
            "Jun": "Jun",
            "June": "June",
            "Keep me logged in": "Keep me logged in",
            "Language": "Language",
            "Latitude": "Latitude",
            "Let us know your name before you continue": "Let us know your name before you continue",
            "let's create your account": "let's create your account",
            "Location": "Location",
            "Login": "Login",
            "Login with google": "Login with google",
            "Logout": "Logout",
            "Longitude": "Longitude",
            "Managed users cannot create organization": "Managed users cannot create organization",
            "Please check with your admin.": "Please check with your admin.",
            "You're not allow to complete this action.": "You're not allow to complete this action.",
            "Mar": "Mar",
            "March": "March",
            "Max photo size 2MB.": "Max photo size 2MB.",
            "May": "May",
            "Missing field on request body": "Mandatory field",
            "Mobile number": "Mobile number",
            "Mobile number or email": "Mobile number or email",
            "more organizations": "more organizations",
            "My organizations": "My organizations",
            "My profile": "My profile",
            "Name": "Name",
            "new field": "new field",
            "New Organization": "New Organization",
            "New Password": "New Password",
            "New password": "New password",
            "No boundary creation": "No boundary creation",
            "No crops": "No crops",
            "No organization created": "No organization created",
            "No properties created": "No properties created",
            "No season created": "No season created",
            "No spaces": "No spaces",
            "Nov": "Nov",
            "November": "November",
            "Number in Square Meter":"Number in Square Meter",
            "Oct": "Oct",
            "October": "October",
            "Oh no, I forgot my password": "Oh no, I forgot my password",
            "One number": "One number",
            "One upper and lowercase letter": "One upper and lowercase letter",
            "One uppercase": "One uppercase",
            "One lowercase": "One lowercase",
            "(optional)": "(optional)",
            "optional": "optional",
            "Options": "Options",
            "Organization": "Organization",
            "Organization created successfully": "Organization created successfully",
            "Organization email": "Organization email",
            "Organization Image": "Organization Image",
            "Organization management": "Organization management",
            "Organization Name": "Organization Name",
            "Organization number": "Organization number",
            "Organization updated successfully": "Organization updated successfully",
            "Organizations": "Organizations",
            "Overlapping vectors": "Overlapping vectors",
            "Password": "Password",
            "Password Strength": "Password Strength",
            "Phone number": "Phone number",
            "Planting": "Planting",
            "Planting date is not between season interval": "The planting date isn't between season dates. Please review your dates",
            "Platform settings": "Platform settings",
            "Please check your inbox.": "Please check your inbox.",
            "Please enter a valid mobile number": "Please enter a valid mobile number",
            "Please enter City": "Please enter City",
            "Please enter Country": "Please enter Country",
            "Please Enter Email address": "Please Enter Email address",
            "Please enter Latitude": "Please enter Latitude",
            "Please enter Longitude": "Please enter Longitude",
            "Please enter Name": "Please enter Name",
            "Please enter State": "Please enter State",
            "Please enter valid email address": "Please enter valid email address",
            "Please Enter your name": "Please Enter your name",
            "Please Enter your password": "Please Enter your password",
            "Please select a currency.": "Please select a currency.",
            "Please select system of units.": "Please select system of units.",
            "Cancel create no boundary field.": "Cancel create no boundary field.",
            "This user can": "This user can",
            "Create new properties": "Create new properties",
            "Create and edit seasons": "Create and edit seasons",
            "Edit this organization": "Edit this organization",
            "fixed information": "fixed information",
            "User name": "User name",
            "Types of user": "Types of user",
            "No seasons created": "No seasons created",
            "Select a work role": "Select a work role",
            "not a valid format": "not a valid format",
            "Preview": "Preview",
            "Weak": "Weak",
            "Medium": "Medium",
            "Strong": "Strong",
            "Strong password": "Strong password",
            "Error": "Error",
            "Ok": "Ok",
            "Please enter valid email address.": "Please enter valid email address.",
            "Please try again": "Please try again",
            "Privacy Policy, Terms and Conditions": "Privacy Policy, Terms and Conditions",
            "Profile settings": "Profile settings",
            "properties": "properties",
            "Properties": "Properties",
            "Properties and seasons": "Properties and seasons",
            "Property": "Property",
            "Raise your level": "Raise your level",
            "Recover your password": "Recover your password",
            "Recovery link has already been used": "This link already been used. Please generate another one ",
            "Recovery link has expired": "This link has expired. Please generate another one",
            "Redefine password": "Redefine password",
            "Redefine your password": "Redefine your password",
            "Reference currency": "Reference currency",
            "Remove drawing": "Remove drawing",
            "Remove photo": "Remove photo",
            "ReGenerate":"ReGenerate",
            "Role": "Role",
            "Remove small zones in square meters between 100 and 1000":"Remove small zones in square meters between 100 and 1000",
            "Save": "Save",
            "Save and add fields": "Save and add fields",
            "Save changes": "Save changes",
            "Search by name": "Search by name",
            "Season duplicated": "The season name already exist. Please choose another one",
            "Season": "Season",
            "Seasons": "Seasons",
            "seasons": "seasons",
            "Select fields": "Select fields",
            "Select fields on the map": "Select fields on the map",
            "Select one crop": "Select one crop",
            "Select one variety": "Select one variety",
            "Send": "Send",
            "Sep": "Sep",
            "September": "September",
            "Set the start and end dates": "Set the start and end dates",
            "Set up your fields without boundaries.": "Set up your fields without boundaries.",
            "Settings": "Settings",
            "Sign in": "Sign in",
            "Sign in to Base": "Sign in to Base",
            "Sign up": "Sign up",
            "Sign up with google": "Sign up with google",
            "So what do you want to do now?": "So what do you want to do now?",
            "Social Security Number": "Social Security Number",
            "Start": "Start",
            "Start date cannot be after end date": "The start date can't be after the end date. Please review your dates",
            "State": "State",
            "strong password": "strong password",
            "Subtract circle": "Subtract circle",
            "Subtract polygon": "Subtract polygon",
            "System of units": "System of units",
            "There are no properties for this organization": "There are no properties for this organization",
            "There are no seasons for this organization": "There are no seasons for this organization",
            "This image is for internal use only.": "This image is for internal use only.",
            "This login has already been used.": "This login has already been used.",
            "This mobile number already exist in the system.": "This mobile number already exist in the system.",
            "Time zone": "Time zone",
            "To create a new organization": "To create a new organization",
            "To create a new season you must have at least one property": "To create a new season you must have at least one property.",
            "To delete this organization": "To delete this organization, click here",
            "To Edit this Organization": "To Edit this Organization",
            "To redefine your password": "To redefine your password",
            "too short": "too short",
            "Total area": "Total area",
            "Type a name with less than 100 characters": "Type a name with less than 100 characters",
            "type a new password below": "type a new password below",
            "Type here": "Type here",
            "Type of user": "Type of user",
            "Type the name of your property here": "Type the name of your property here",
            "Type your new password here": "Type your new password here",
            "Type your password here": "Type your password here",
            "Unauthorized": "Unauthorized operation",
            "Undo last action": "Undo last action",
            "Upload file": "Upload file",
            "Upload new photo": "Upload new photo",
            "Upload the files containing field`s shapes and names.": "Upload the files containing field's shapes and names.",
            "User account phone number can't be empty": "Mandatory field",
            "User can create new properties?": "User can create new properties?",
            "User Information": "User Information",
            "User Login": "User Login",
            "Username already exists": "This user login already exist. Please try again",
            "Users": "Users",
            "Variety": "Variety",
            "View": "View",
            "We will send you a link to reset your password.": "We will send you a link to reset your password.",
            "weak password": "weak password",
            "Which crop are you going to plant?": "Which crop are you going to plant?",
            "without boundaries": "without boundaries",
            "Wrong Old Password": "Incorrect password",
            "You are deleting": "You are deleting",
            "You can also": "You can also",
            "You can change it in the settings area": "You can change it in the settings area",
            "Your account has been created": "Your account has been created",
            "Your fields": "Your fields",
            "Your Name": "Your Name",
            "Your new password has been created": "Your new password has been created",
            "Your organization`s name is": "Your organization's name is",
            "Zip code": "Zip code",
            "Zip code cannot be longer than {{MAX}} characters": "Zip code cannot be longer than {{MAX}} characters",
            "Organization name cannot be longer than {{MAX}} characters": "Organization name cannot be longer than {{MAX}} characters",
            "Mobile number cannot be longer than {{MAX}} characters": "Mobile number cannot be longer than {{MAX}} characters",
            "Mobile number cannot be smaller than {{MIN}} characters": "Mobile number cannot be smaller than {{MIN}} characters",
            "EIN cannot be longer than {{MAX}} characters": "EIN cannot be longer than {{MAX}} characters",
            "Organization email cannot be longer than {{MAX}} characters": "Organization email cannot be longer than {{MAX}} characters",
            "Address cannot be longer than {{MAX}} characters": "Address cannot be longer than {{MAX}} characters",
            "This field cannot be empty space": "This field cannot be empty space",
            "EXXX000": "Generic unmapped error",
            "EARG001": "Mandatory field",
            "EARG002": "Failed to convert request body",
            "EARG003": "Failed to convert parameter",
            "EINT001": "Unexpected internal server error",
            "EACC001": "Bad Credentials",
            "EACC002": "This account already exists",
            "EACC003": "This email is already registered",
            "EACC004": "Invalid SSN",
            "EACC005": "Incorrect password",
            "EACC006": "Login can't be empty",
            "EACC007": "Password can't be empty",
            "EACC008": "This user login already exist. Please try again",
            "EACC009": "Enter a valid email address",
            "EACC010": "Informe um número de celular válido",
            "EACC011": "weak password",
            "EACC012": "Email address not registered, please sign up",
            "EACC013": "This mobile number already exist in the system. Please try again",
            "EACC014": "Mandatory field",
            "EACC015": "Invitation does not exist",
            "EACC016": "Current and new password should be different. Please type a different one.",
            "EACC017": "Informed account does not match",
            "EACC018": "This link has expired. Please generate another one",
            "EACC019": "This link already been used. Please generate another one ",
            "EFLD001": "Invalid size",
            "EFLD002": "This field name already exist. Please choose another one",
            "EFLD003": "Field does not exist",
            "EFLD004": "Region does not exist",
            "EFLD005": "Parent region does not exist",
            "EGEO000": "Geometries update error",
            "EGEO001": "Parent region does not exist",
            "EGEO002": "Cannot update field. It does not exist",
            "EGEO003": "Field already exist. Please review your fields",
            "EGEO004": "Field geometry is invalid or intersects with itself",
            "EGEO005": "Geometry intersection found",
            "EGEO006": "Cannot change region ID",
            "EGEO007": "Uploaded file must be either one .kml file or a shapefile set (at least .shp, .shx and .dbf)",
            "EORG001": "Invalid currency",
            "EORG002": "Invalid System of units",
            "EORG003": "Invalid Picture URL",
            "EORG004": "Invalid Picture type",
            "EORG005": "Invalid org email",
            "EORG006": "Invalid org phone",
            "EORG007": "Invalid org Zip Code",
            "EORG008": "Invalid org Country",
            "EORG009": "Invalid org State",
            "EORG010": "Invalid org City",
            "EORG011": "Organization does not exist",
            "EORG012": "You're not allow to complete this action. Please check with your admin",
            "EPRO001": "Invalid property Zip Code",
            "EPRO002": "Invalid property Country",
            "EPRO003": "Invalid property State",
            "EPRO004": "Invalid property City",
            "EPRO005": "Invalid property Time Zone",
            "EPRO008": "Invalid Geometry Type",
            "EPRO006": "Invalid coordinates. Please type a valid one",
            "EPRO007": "Property does not exist",
            "ESEA001": "Field must include harvesting and planting dates",
            "ESEA002": "The harvesting date must be after planting date. Please review your dates",
            "ESEA003": "The planting date isn't between season dates. Please review your dates",
            "ESEA004": "The harvesting date isn't between season dates. Please review your dates",
            "ESEA005": "The season name already exist. Please choose another one",
            "ESEA006": "This variety doesn't macth with the season'crop. Please choose another one",
            "ESEA007": "Invalid Start Date",
            "ESEA008": "The start date can't be after the end date. Please review your dates",
            "ESEA009": "Season does not exist",
            "ESEA010": "Id of the field must not be null",
            "ESEA011": "This crop change can't be done if there are fields linked it. Please review your season's field.",
            "ESEA012": "Seasons org and properties org must be the same",
            "ESEA013": "Seasons org and fields org must be the same",
            "ESEA014": "All listed Properties must exist",
            "ESEA015": "All listed Fields must exist",
            "ESEA016": "Crop does not exist",
            "EIAM001": "Unauthorized operation",
            "EIAM002": "Invalid authority",
            "EIAM003": "Invalid context",
            "EIAM004": "Account is not managed by requester",
            "EIAM005": "Permission does not exist in this context",
            "EIAM006": "Invalid authentication",
            "Name code cannot be longer than {{MAX}} characters": "Name code cannot be longer than {{MAX}} characters",
            "Country cannot be longer than {{MAX}} characters": "Country cannot be longer than {{MAX}} characters",
            "State cannot be longer than {{MAX}} characters": "State cannot be longer than {{MAX}} characters",
            "City cannot be longer than {{MAX}} characters": "City cannot be longer than {{MAX}} characters",
            "Field cannot have spaces": "Field cannot have spaces",
            "SSN cannot be longer than {{MAX}} characters": "SSN cannot be longer than {{MAX}} characters",
            "A message has been sent to your email. Please check your inbox.": "A message has been sent to your email. Please check your inbox.",
            "Cancel property edition": "Cancel property edition",
            "Please select time zone": "Please select time zone",
            "Please enter valid Latitude": "Please enter valid Latitude",
            "Please enter valid Longitude": "Please enter valid Longitude",
            "Select crop": "Select crop",
            "Allow numbers and text only": "Allow numbers and text only",
            "Cancel create organization.": "Cancel create organization.",
            "Upload": "Upload",
            "Please enter Number!": "Please enter Number!",
            "There are some problems with your operation.": "There are some problems with your operation.",
            "Delete organization": "Delete organization",
            "Please enter valid Zip code": "Please enter valid Zip code",
            "avatar": "avatar",
            "Image not found": "Image not found",
            "Do you want to remove organization photo?": "Do you want to remove organization photo?",
            "Your password has been changed!": "Your password has been changed!",
            "You have exceeded maximum number of characters allowed.": "You have exceeded maximum number of characters allowed.",
            "Please Enter Mobile number or Email": "Please Enter Mobile number or Email",
            "SignUp": "SignUp",
            "Edit user": "Edit user",
            "Not a valid user login": "Not a valid user login",
            "Password cannot be longer than {{MAX}} characters": "Password cannot be longer than {{MAX}} characters",
            "Cancel add managed user.": "Cancel add managed user.",
            "Cancel invite user.": "Cancel invite user.",
            "Cancel edit user.": "Cancel edit user.",
            "User email": "User email",
            "Please enter valid name!": "Please enter valid name!",
            "Something went wrong": "Something went wrong",
            "Please enter current password.": "Please enter current password.",
            "Please enter new password.": "Please enter new password.",
            "Managed user created successfully.": "Managed user created successfully.",
            "Create boundary": "Create boundary",
            "Delete area": "Delete area",
            "Cancel edit field.": "Cancel edit field.",
            "not valid format": "not valid format",
            "You can only upload JPG/PNG file!": "You can only upload JPG/PNG file!",
            "Cancel edit profile settings.": "Cancel edit profile settings.",
            "Invalid file": "Invalid file",
            "Delete season": "Delete season",
            "You have got new invitation.": "You have got new invitation.",
            "Cancel create season.": "Cancel create season.",
            "Cancel edit season.": "Cancel edit season.",
            "Field is getting intersect with other field": "Overlapping fields are not allowed",
            "Cancel Draw your fields": "Cancel Draw your fields",
            "Cancel Upload Shape files": "Cancel Upload Shape files",
            "Bad credentials": "Bad credentials",
            "Very Strong": "Very Strong",
            "Draw your properties fields with the drawing tools.": "Draw your properties fields with the drawing tools.",
            "Create new Organization": "Create new Organization",
            "Start date": "Start date",
            "End date": "End date",
            "Changes saved successfully": "Changes saved successfully",
            "There are no seasons for this property": "There are no seasons for this property",
            "Cancel edit organization.": "Cancel edit organization.",
            "Draw Another": "Draw Another",
            "Assign field(s)": "Assign field(s)",
            "This field is already been taken for another season": "This field is already been taken for another season",
            "Season name cannot be longer than 22 characters": "Season name cannot be longer than 22 characters",
            "There are no active seasons for this property": "There are no active seasons for this property",
            "There are no active seasons for this organization": "There are no active seasons for this organization",
            "There are no inactive seasons for this property": "There are no inactive seasons for this property",
            "There are no inactive seasons for this organization": "There are no inactive seasons for this organization",
            "Forecast": "Forecast",
            "Historic": "Historic",
            "Date Period": "Date Period",
            "Hi": "Hi",
            "Click and drag to draw circle": "Click and drag to draw circle",
            "Click to start drawing shape": "Click to start drawing shape",
            "No data": "No data",
            "Duplicate field name": "Duplicate field name",
            "Today": "Today",
            "Enter your email below": "Enter your email below",
            "Supported Language": "Supported language",
            "Farm": "Farm",
            "Image must smaller than 2MB": "Image must smaller than 2MB!",
            "Grower": "Grower",
            "Manager": "Manager",
            "Admin": "Admin",
            "Dealer": "Dealer",
            "Consultant": "Consultant",
            "Agronomist": "Agronomist",
            "Other": "Other",
            "Bad creadentials": "Bad creadentials",
            "Unable to load Image": "Unable to load Image",
            "MIDDAY": "MIDDAY",
            "MIDNIGHT": "MIDNIGHT",
            "DATA: METEOBLUE": "DATA: METEOBLUE",
            "Weather Analysis": "Weather Analysis",
            "SUN": "SUN",
            "MON": "MON",
            "TUE": "TUE",
            "WED": "WED",
            "THU": "THU",
            "FRI": "FRI",
            "SAT": "SAT",
            "ESEA017": "Field is already linked to another Season in same period",
            "ESEA018": "Season is not related to field",
            "Generate Management Zone": "Generate Management Zone",
            "Management Zone": "Management Zone",
            "Please select a field": "Please select a field",
            "Filter Images": "Filter Images",
            "Source": "Source",
            "IMAGE LIST": "IMAGE LIST",
            "No.of zones": "No.of zones",
            "Zone number should be between 2 to 15": "Zone number should be between 2 to 15",
            "Zone number should not be a decimal": "Zone number should not be a decimal",
            "Zone number is required": "Zone number is required",
            "Number of pixel": "Number of pixel",
            "Remove outlier": "Remove outlier",
            "Remove outliers":"Remove outlier",
            "Export": "Export",
            "Download": "Download",
            "Year": "Year",
            "Date": "Date",
            "Cloud Cover": "Cloud Cover",
            "SOURCE": "SOURCE",
            "Drone": "Drone",
            "Sentinel": "Sentinel",
            "Landsat8": "Landsat8",
            "Year is required": "Year is required",
            "Date is required": "Date is required",
            "Source is required": "Source is required",
            "No images found": "No images found",
            "Generate zone": "Generate zone",
            "Please select at least one image or shape file attribute": "Please select at least one image or shape file attribute",
            "Start Year": "Start Year",
            "Start date is required": "Start date is required",
            "End date is required": "End date is required",
            "Start and end dates are required": "Start and end dates are required",
            "Start Date": "Start Date",
            "End Date": "End Date",
            "NDVI": "NDVI",
            "RGB": "RGB",
            "Variability View": "Variability View",
            "Select Year": "Select Year",
            "Select Date": "Select Date",
            "Select Source": "Select Source",
            "Can not generate zones for the images selected. Please select a different combination of images and try again.": "Can not generate zones for the images selected. Please select a different combination of images and try again.",
            "Shapefile uploaded successfully.": "Shapefile uploaded successfully.",
            "Continue with SMV": "Continue with SMV",
            "Something went wrong please try again.": "Something went wrong please try again.",
            "Success": "Success",
            "Enter country": "Enter country",
            "Enter city": "Enter city",
            "Enter state": "Enter state",
            "Zip/Postal Code": "Zip/Postal Code",
            "Enter Zip/Postal Code": "Enter Zip/Postal Code",
            "Enter Address": "Enter Address",
            "Enter Property Name": "Enter Property Name",
            "Please input your name": "Please input your name",
            "Please check your internet connection.": "Please check your internet connection.",
            "sentinel (10 m resolution)": "sentinel (10 m resolution)",
            "landsat8 (30 m resolution)": "landsat8 (30 m resolution)",
            "Click on the button to filter images and generate zones for the field": "Click on the button to filter images and generate zones for the field",
            "Outlier pixel is required": "Outlier pixel is required",
            "Outlier pixel should be a non zero positive number": "Outlier pixel should be a non zero positive number",
            "zone": "zone",
            "The file": "The file",
            "was downloaded successfully.": "was downloaded successfully.",
            "Session ID cannot be empty": "Session ID can not be empty",
            "Session ID is required": "Session ID is required",
            "Session is not active": "Session is not active",
            "Nidera access token expired…": "Nidera access token expired…",
            "Invalid Client ID/Client Secret for Nidera": "Invalid Client ID/Client Secret for Nidera",
            "Invalid Client ID/Client Secret for BASE": "Invalid Client ID/Client Secret for BASE",
            "'field_id' is required": "'field_id' is required",
            "'geometry' is required": "'geometry' is required",
            "'fromDate' is required": "'fromDate' is required",
            "'endDate' is required": "'endDate' is required",
            "'sources' is required": "'sources' is required",
            "'export_ids' is required": "'export_ids' is required",
            "'geom_id' is required": "'geom_id' is required",
            "'zone' is required": "'zone' is required",
            "cannot copy sequence with size 4 to array axis with dimension 5": "can not copy sequence with size 4 to array axis with dimension 5",
            "field_id cannot be empty": "field_id can not be empty",
            "geometry cannot be empty": "geometry can not be empty",
            "fromDate cannot be empty": "fromDate can not be empty",
            "endDate cannot be empty": "endDate can not be empty",
            "source cannot be empty": "source can not be empty",
            "Missing Date Parameter : not enough values to unpack (expected 3, got 2)": "Missing Date Parameter : not enough values to unpack (expected 3, got 2)",
            "Missing Date Parameter : not enough values to unpack (expected 3, got 1)": "Missing Date Parameter : not enough values to unpack (expected 3, got 1)",
            "Missing Required Parameter : 'asset_ids'": "Missing Required Parameter : 'asset_ids'",
            "Missing Required Parameter : 'geom_id'": "Missing Required Parameter : 'geom_id'",
            "asset ids cannot be empty": "asset ids can not be empty",
            "geom id cannot be empty": "geom id can not be empty",
            "Email and/or password is incorrect. Cannot authenticate.": "Email and/or password is incorrect. Can not authenticate.",
            "Expecting value: line 1 column 1 (char 0)": "Expecting value: line 1 column 1 (char 0)",
            "Download URL not found for the export ids": "Download URL not found for the export ids",
            "Unsupported grant type: secret": "Unsupported grant type: secret",
            "Unable to queue image download.": "Unable to queue image download.",
            "'geom_id' is required": "'geom_id' is required",
            "'export_ids' is required": "'export_ids' is required",
            "'zone' is required": "'zone' is required",
            "export ids cannot be empty": "export ids can not be empty",
            "number of zones cannot be empty": "number of zones can not be empty",
            "Unable to fetch downloads for user.": "Unable to fetch downloads for user.",
            "'download' is required": "'download' is required",
            "upload_to cannot be empty": "upload_to can not be empty",
            "cannot copy sequence with size 2 to array axis with dimension 3": "can not copy sequence with size 2 to array axis with dimension 3",
            "index 0 is out of bounds for axis 0 with size 0": "index 0 is out of bounds for axis 0 with size 0",
            "could not broadcast input array from shape (50,50) into shape (17,17)": "could not broadcast input array from shape (50,50) into shape (17,17)",
            "Please zoom in to select the fields.": "Please zoom in to select the fields.",
            "Error Detecting Boundaries": "Error Detecting Boundaries",
            "Error in Detecting Boundaries": "Error in Detecting Boundaries",
            "Fields added successfully...": "Fields added successfully...",
            "Detect fields": "Detect fields",
            "No Fields Selected": "No Fields Selectedo",
            "Some fields may not be saved due to overlapping boundarie": "Some fields may not be saved due to overlapping boundarie",
            "Cancel Detect your fields": "Cancel Detect your fields",
            "Drag into parent polygon for subtraction": "Drag into parent polygon for subtraction",
            "Select the appropriate image": "Select the appropriate image",
            "Select the appropriate image from option shown": "Select the appropriate image from option shown",
            "Select shape file": "Select shape file",
            "Upload shape file": "Upload shape file",
            "Uploading": "Uploading",
            "Please select a shape file": "Please select a shape file",
            "Select only Shapefiles": "Select only Shapefiles",
            "Include Elevation": "Include Elevation",
            "ElEVATION": "ElEVATION",
            "No elevation image found": "No elevation image found",
            "Click on the map to update the latitude and longitude":"Click on the map to update the latitude and longitude",
            "The result will contain images from the year selected here to the current year" : "The result will contain images from the year selected here to the current year",
            "Satellite from which the images come." : "Satellite from which the images come.",
            "Include elevation layer derived from satellite to build the ambientation":"Include elevation layer derived from satellite to build the ambientation",
            "The result will contain the range of months to filter images. Recommended images are around the critical period for crops.":"The result will contain the range of months to filter images. Recommended images are around the critical period for crops.",
            "Threshold percentage of the satellite scene covered by clouds. he higher the allowed limit, be careful of clouds that interfere in the field." : "Threshold percentage of the satellite scene covered by clouds. he higher the allowed limit, be careful of clouds that interfere in the field.",
            "Generate management zones only with Shapefiles (elevation maps; yield maps).":"Generate management zones only with Shapefiles (elevation maps; yield maps).",
            "Elevation file. Recommended for field with a high slope." :"Elevation file. Recommended for field with a high slope.",
            "Export management zones to SMV and continue with the recommendation.":"Export management zones to SMV and continue with the recommendation.",
            "Delete small areas within larger areas. The value entered is the minimum area size threshold." : "Delete small areas within larger areas. The value entered is the minimum area size threshold.",
            "Request time out, Please try again.":"Request time out, Please try again.",
            "We are downloading the images for your field please try in a couple of minutes":  "We are downloading the images for your field please try in a couple of minutes",
            "Sign Up is sussceesfully and you will be redirected to login page":  "Sign Up is sussceesfully and you will be redirected to login page",
            "Something wrong for Imagery Sensing Contract": "Something wrong for Imagery Sensing Contract",
            "Something wrong for Nidera Contract": "Something wrong for Nidera Contract",
            "Something wrong for Remote Sensing Contract": "Something wrong for Remote Sensing Contract",
            "We are downloading the images of your lot for the automatic setting option.Please return within 2 hours":"We are downloading the images of your lot for the automatic setting option.Please return within 2 hours"
        }
    },
    "es": {
        "translation": {
            "A message has been sent to your email.": "Un mensaje ha sido enviado a tu email.",
            "Account already exists": "Cuenta ya registrada",
            "Account already registered": "Cuenta ya registrada",
            "Account does not exist": "Email no reconocido, por favor regístrate",
            "Account not registered, please sign up": "Cuenta no registrada, por favor regístrate",
            "Actions": "Acciones",
            "Active seasons": "Cosechas activas",
            "Add managed user": "Agregar usuario administrado",
            "Add New Field": "Crear nuevo campo",
            "Address": "Dirección",
            "Adjusted size": "Tamaño ajustado",
            "All fields": "Todos los campos",
            "All of your progress will be lost.": "¿Estás seguro de que deseas cancelar?",
            "Allow Cropwise to send offers to my email": "Permitir que Cropwise envíe ofertas a mi correo electrónico",
            "Allow Cropwise to send offers to my mobile number": "Acepto recibir ofertas en mi celular",
            "Already have an account?": "¿Ya tienes cuenta?",
            "Already have an account": "¿Ya tienes cuenta",
            "Analytics": "Analítica",
            "Apply": "Aplicar",
            "Apply to all selected fields": "Aplicar a todos los campos",
            "Apr ": "Abr",
            "April": "Abril",
            "Are you sure you want to cancel?": "Todo tu progreso se perderá.",
            "Are you sure you want to delete": "Estás seguro que deseas borrarla",
            "Area": "Área total",
            "At least 8 characters": "Utiliza por lo menos 8 caracteres",
            "At least one season needs to be selected": "Al menos una cosecha debe estar seleccionada",
            "Aug": "Ago",
            "August": "Agosto",
            "Automatic":"automatico",
            "Back": "Volver",
            "Basic info": "Informaciones básicas",
            "Box Plot":"Diagrama de caja",
            "Cancel": "Cancelar",
            "Cancel property creation": "Cancelar creación de propiedad",
            "Cannot change crop if there are fields linked to it": "No se puede cambiar el cultivo si hay campos vinculados a ese. Revisa los campos de tu ciclo.",
            "Cannot create field. It already exist": "Este campo ya existe. Revisa tus campos.",
            "Change the information below to edit your season": "Modificar la información abajo para editar la cosecha",
            "Choose a city": "Seleccionar ciudad",
            "Choose a country": "Seleccionar país",
            "City": "Ciudad",
            "click here": "haga clic aquí",
            "Click the link below to access the page where you can enter a new password of your choice.": "Entra en el enlace para cambiar tu contraseña",
            "Confirm": "Confirma",
            "Confirm new password": "Confirmar nueva contraseña",
            "Continue": "Continuar",
            "Country": "País",
            "Create Account": "Crear cuenta",
            "Create fields": "Crear campos",
            "Create fields to set up your first farm": "Crear campos para configurar tu primera granja",
            "Create new field": "Crear nuevo campo",
            "Create new password": "Crear una nueva contraseña",
            "Create new property": "Crear una nueva propiedad",
            "Create new season": "Crear una nueva cosecha",
            "Create Organization": "Crear Empresa",
            "Create Property": "Crear Propiedad",
            "Create season": "Crear cosecha",
            "Create user": "Crear usuario",
            "Create your account": "Crear cuenta",
            "Creaty boundary": "Crear ímite",
            "crops": "Cultivos",
            "Crops": "Cultivos",
            "Currency & System of units": "Moneda y sistema de unidades",
            "Current and new password should be different": "La contraseña actual y la nueva deben ser diferentes. Elige otra contraseña.",
            "Current password": "Contraseña actual",
            "Data Not Found": "Datos no encontrados",
            "Dec": "Dic",
            "December": "Diciembre",
            "Delete": "Borrar",
            "Delete property": "Eliminar propiedad",
            "Discard": "Borrar",
            "Don't have an account yet?": "¿No tienes cuenta?",
            "Done": "Listo",
            "Drag and drop": "Arrastra y suelta",
            "Drag and drop your files anywhere on the screen": "Arrastra y suelta tus archivos en cualquier parte de la pantalla",
            "Draw circle": "Dibujar círculo",
            "Draw polygon": "Dibujar polígono",
            "Draw your fields": "Dibujar campos",
            "Draw your property`s fields with the drawing tools.": "Dibujar las propiedades de los campos usando las herramientas de dibujo",
            "Drawing tools": "Herramientas de dibujo",
            "Edit": "Editar",
            "Edit fields": "Editar campos",
            "Edit Organization": "Editar organización",
            "Edit Property": "Editar propiedades",
            "Delete Property": "Eliminar Propiedad",
            "Edit Season": "Editar cosecha",
            "Email": "Email",
            "Email address not registered, please sign up": "Email no reconocido, por favor regístrate",
            "Email address or password are incorrect": "Email o contraseña incorrectos",
            "Email already registered": "E-mail ya registrado",
            "Email/login": "Email/login",
            "Employer Identification Number": "Número de identificación de empleado",
            "End": "Fin",
            "Enter a valid email address": "Introduce una dirección de correo electrónico válida",
            "Enter a valid email or mobile number": "Ingresa un email ó un número de celular válido",
            "Enter a valid mobile number": "Ingresa un número de celular válido",
            "Enter your mobile number or email below.": "En la parte de abajo ingresa tu número de celular ó de email",
            "Explore the platform": "Explora la plataforma",
            "fair password": "Nivel de seguridad medio",
            "Feb": "Feb",
            "February": "Febrero",
            "Field name": "Nombre del campo",
            "Field name already exists for this Property": "Este nombre de campo ya existe. Elegir otro nombre.",
            "Field size": "Tamaño del Campo",
            "Fill in the fields below to create your season": "Completa la información abajo para crear una cosecha",
            "Find file": "Buscar archivo",
            "Find the files on your computer": "Buscar archivos en tu computadora",
            "Finish": "Finalizar",
            "For Internal Users Only":"Solo para usuarias internas",
            "Geometry Points regulator": "Regulador de puntos geométricos",
            "Get 1200pts before December 31th to get the Silver level rewards": "Obtenga 1200 puntos antes del 31 de diciembre Obtenga las recompensas de Nivel Plata",
            "Get started and discover what Base can do for you": "Descubre todo lo que Base puede hacer por ti.",
            "Give your season a name": "Nombre que tendrá la cosecha",
            "Harvesting": "Cosecha",
            "Harvesting date is not between season interval": "La fecha de la cosecha está fuera del período del ciclo.",
            "Harvesting date must be after planting date": "La fecha de la cosecha debe ser posterior a la fecha de la siembra.",
            "Hello": "Hola",
            "Hey": "Hola",
            "I have read and agree with the": "He leído y estoy de acuerdo con el",
            "If you no longer want to change your password, just ignore this message.": "Si no estás intentando cambiar tu contraseña, puedes ignorar este mensaje.",
            "Image will be shown in many places of the platform.": "Esta imagen de uso en varios lugares de la plataforma.",
            "Inactive Seasons": "Cosechas Inactivas",
            "Incorrect password": "Contraseña incorrecta",
            "Invalid email": "Introduce una dirección de correo electrónico válida",
            "Invalid phone number": "Ingresa un número de celular válido",
            "Invalid property Coordinates": "Coordenadas inválidas. Escribir las coordenadas válidas.",
            "Invalid variety": "La variedad no corresponde a la cosecha del ciclo.",
            "Invite this user": "Invitar usuario",
            "Invite user": "Invitar usuario",
            "Jan": "Ene",
            "January": "Enero",
            "Jul": "Jul",
            "July": "Julio",
            "Jun": "Jun",
            "June": "Junio",
            "Keep me logged in": "Mantenerme conectado",
            "Language": "Idioma",
            "Latitude": "Latitud",
            "Let us know your name before you continue": "Informa tu nombre antes de continuar",
            "let's create your account": "vamos a crear tu cuenta",
            "Location": "Ubicación",
            "Login": "Iniciar sesión",
            "Login with google": "Iniciar sesión con Google",
            "Logout": "Cerrar sesión",
            "Longitude": "Longitud",
            "Managed users cannot create organization": "usuários gerenciados não pode Criar organização",
            "Please check with your admin.": "Verificar permisos con el administrador de la cuenta.",
            "You're not allow to complete this action.": "No tienes permiso para completar esta acción.",
            "Mar": "Mar",
            "March": "Marzo",
            "Max photo size 2MB.": "Tamaño máx.2 MB.",
            "May": "Mayo",
            "Missing field on request body": "Campo obligatorio",
            "Mobile number": "Celular",
            "Mobile number or email": "Número de Celular o email",
            "more organizations": "Otras empresas",
            "My organizations": "Mis Empresas",
            "My profile": "Mi perfil",
            "Name": "Nombre",
            "new field": "Campo nuevo",
            "New Organization": "Nueva Empresa",
            "New Password": "Nueva contraseña",
            "New password": "Nueva contraseña",
            "No boundary creation": "Creación sin limite",
            "No crops": "Sin cultivos",
            "No organization created": "No hay empresa creada",
            "No properties created": "No hay propiedades creadas",
            "No season created": "No hay cosechas creadas",
            "No spaces": "Sin espacios",
            "Nov": "Nov",
            "November": "Noviembre",
            "Number in Square Meter":"Número en metros cuadrados",
            "Oct": "Oct",
            "October": "Octubre",
            "Oh no, I forgot my password": "Olvidé mi contraseña",
            "One number": "Utiliza números",
            "One upper and lowercase letter": "Utiliza letras mayúsculas y minúsculas",
            "One uppercase": "Una letra mayúscula",
            "One lowercase": "una minúscula",
            "(optional)": "(opcional)",
            "optional": "opcional",
            "Options": "Opciones",
            "Organization": "Empresa",
            "Organization created successfully": "Empresa creada con éxito",
            "Organization email": "Email de la empresa",
            "Organization Image": "Imagen de la empresa",
            "Organization management": "Gestión de la empresa",
            "Organization Name": "Nombre de la empresa",
            "Organization number": "Número de la empresa",
            "Organization updated successfully": "Empresa actualizada con éxito",
            "Organizations": "Empresas",
            "Overlapping vectors": "Campos sobrepuestos",
            "Password": "Contraseña",
            "Password Strength": "Nivel de seguridad de la contraseña",
            "Phone number": "Teléfono celular",
            "Planting": "Siembra",
            "Planting date is not between season interval": "La fecha de la siembra está fuera del período del ciclo.",
            "Platform settings": "Configuraciones de la plataforma",
            "Please check your inbox.": "Revisa tu buzón.",
            "Please enter a valid mobile number": "Por favor, ponga un número de móvil válido",
            "Please enter City": "Por favor escriba el nombre de la ciudad",
            "Please enter Country": "Por favor, introduzca el nombre del país",
            "Please Enter Email address": "Por favor, introduzca la dirección de correo",
            "Please enter Latitude": "Por favor, introduzca la latitud",
            "Please enter Longitude": "Por favor, introduzca la longitud",
            "Please enter Name": "Por favor, introduzca el nombre",
            "Please enter State": "Por favor, introduzca el nombre del estado",
            "Please enter valid email address": "Por favor, introduzca una dirección válida de correo electrónico",
            "Please Enter your name": "Por favor, introduzca su nombre",
            "Please Enter your password": "Por favor, introduzca su contraseña",
            "Please select a currency.": "Por favor, seleccione una moneda.",
            "Please select system of units.": "Seleccione el sistema de unidades.",
            "Cancel create no boundary field.": "Cancelar la creación de campo",
            "This user can": "Este usuario puede,",
            "Create new properties": "Crear nuevas propiedades",
            "Create and edit seasons": "Creación y edición de estaciones",
            "Edit this organization": "Editar esta organización",
            "fixed information": "información fija",
            "User name": "Nombre de usuario",
            "Types of user": "Tipos de usuario",
            "No seasons created": "No hay estaciones creadas",
            "Select a work role": "Seleccione una función de trabajo",
            "not a valid format": "No es un formato válido",
            "Preview": "preestreno",
            "Weak": "débil",
            "Medium": "promedio",
            "Strong": "fuerte",
            "Strong password": "Nivel de seguridad alto",
            "Error": "error",
            "Ok": "Está bien",
            "Please enter valid email address.": "Por favor, introduzca una dirección válida de correo electrónico.",
            "Please try again": "Introduce un número de teléfono válido.",
            "Privacy Policy, Terms and Conditions": "Política de privacidad, términos y condiciones",
            "Profile settings": "Configuraciones de perfil",
            "properties": "propiedades",
            "Properties": "Propiedades",
            "Properties and seasons": "Propiedades y cosechas",
            "Property": "Propiedad",
            "Raise your level": "Eleva tu nivel",
            "Recover your password": "Recuperar contraseña",
            "Recovery link has already been used": "Este link ya fue utilizado. Generar otro link.",
            "Recovery link has expired": "El link para restablecer tu contraseña ha cadudado. Generar otro link",
            "Redefine password": "Crear nueva contraseña",
            "Redefine your password": "Crear nueva contraseña",
            "Reference currency": "Referencia monetaria",
            "Remove drawing": "Borrar dibujo",
            "Remove photo": "Remover imagen",
            "Role": "Cargo",
            "ReGenerate":"regenerar",
            "Save": "Guardar",
            "Save and add fields": "Guardar y crear campos",
            "Save changes": "Guardar cambios",
            "Search by name": "Buscar por nombre",
            "Season duplicated": "Este nombre de ciclo ya existe. Elegir otro.",
            "Season": "Ciclo",
            "Seasons": "Ciclo",
            "seasons": "Ciclo",
            "Select fields": "Seleccionar campos",
            "Select fields on the map": "Seleccionar campos en el mapa",
            "Select one crop": "Seleccionar cultivo",
            "Select one variety": "Seleccionar una variedad",
            "Send": "Enviar",
            "Sep": "Sep",
            "September": "Septiembre",
            "Set the start and end dates": "Ingresar las fechas de inicio y final de cosecha",
            "Set up your fields without boundaries.": "Creación de campos sin limite",
            "Settings": "Configuraciones",
            "Sign in": "Iniciar sesión",
            "Sign in to Base": "Iniciar sesión",
            "Sign up": "Regístrate",
            "Sign up with google": "Regístrate con Google",
            "So what do you want to do now?": "¿Por dónde comenzamos?",
            "Social Security Number": "Número de seguro social",
            "Start": "Inicio",
            "Start date cannot be after end date": "La fecha de la siembra debe ser anterior a la fecha de la cosecha.",
            "State": "Estado / Region",
            "strong password": "Nivel de seguridad alto",
            "Subtract circle": "Sustraer círculo",
            "Subtract polygon": "Sustraer polígono",
            "System of units": "Sistema de unidades",
            "There are no properties for this organization": "No hay propiedades para esta organización.",
            "There are no seasons for this organization": "No hay estaciones para esta organización.",
            "This image is for internal use only.": "Esta imagen es exclusiva para uso interno.",
            "This login has already been used.": "Este login ya está en uso",
            "This mobile number already exist in the system.": "Este número de teléfono ya existe en el sistema.",
            "Time zone": "Huso horario",
            "To create a new organization": "Para crear una nueva organización",
            "To create a new season you must have at least one property": "Para crear una nueva cosecha es necesario tener al menos una propiedad",
            "To delete this organization": "Para eliminar esta organización",
            "To Edit this Organization": "Para editar esta organización",
            "To redefine your password": "Para redefinir tu contraseña",
            "too short": "Contraseña muy corta",
            "Total area": "Área total",
            "Type a name with less than 100 characters": "Escribe tu nombre usando máximo 100 caracteres",
            "type a new password below": "Escribe aqui tu nueva contraseña",
            "Type here": "Escribe aquí",
            "Type of user": "Tipo de usuario",
            "Type the name of your property here": "Escribe el nombre de la propiedad aquí",
            "Type your new password here": "Escribe aqui tu nueva contraseña",
            "Type your password here": "Escribe aquí tu contraseña",
            "Unauthorized": "El usuario no tiene permiso para realizar esta operación.",
            "Undo last action": "Deshacer la última acción",
            "Upload file": "Cargar Archivo",
            "Upload new photo": "Cargar nueva imagen",
            "Upload the files containing field`s shapes and names.": "Cargar documentos con formas y nombres del campo",
            "User account phone number can't be empty": "Campo obligatorio",
            "User can create new properties?": "¿Este usuario puede crear propiedades?",
            "User Information": "Información del usuario",
            "User Login": "Login usuario",
            "Username already exists": "El nombre de usuario ya existe. Intenta nuevamente.",
            "Users": "Usuarios",
            "Variety": "Variedad",
            "View": "Visualizar",
            "We will send you a link to reset your password.": "Te enviaremos un enlace para restablecer tu contraseña",
            "weak password": "Nivel de seguridad bajo",
            "Which crop are you going to plant?": "¿Que cultivo vas a sembrar?",
            "without boundaries": "Sin límites",
            "Wrong Old Password": "Contraseña incorrecta",
            "You are deleting": "Estás borrando.",
            "You can also": "También puedes",
            "You can change it in the settings area": "Puedes modificarlo en las configuraciones",
            "Your account has been created": "Cuenta creada con éxito",
            "Your fields": "Mis campos",
            "Your Name": "Nombre",
            "Your new password has been created": "Nueva contraseña creada con éxito",
            "Your organization`s name is": "Nombre de la empresa",
            "Zip code": "Código Postal",
            "Zip code cannot be longer than {{MAX}} characters": "CEP no puede tener más de {{}} caracteres MAX",
            "Organization name cannot be longer than {{MAX}} characters": "nombre de la organización no puede tener más de {{MAX}} caracteres ",
            "Mobile number cannot be longer than {{MAX}} characters": "El número no puede ser mayor que {{MAX}} caracteres",
            "Mobile number cannot be smaller than {{MIN}} characters": "El número no puede ser inferior a {{MIN}} caracteres",
            "EIN cannot be longer than {{MAX}} characters": "EIN no puede tener más de {{}} caracteres MAX",
            "Organization email cannot be longer than {{MAX}} characters": "organización de correo electrónico no puede tener más de {{}} caracteres MAX",
            "Address cannot be longer than {{MAX}} characters": "Dirección no puede tener más de {{MAX}} caracteres ",
            "This field cannot be empty space": "Este campo no puede estar vacío",
            "EXXX000": "de error genérico sin asignar",
            "EARG001": "Campo obligatorio",
            "EARG002": "No se pudo convertir el cuerpo de la petición",
            "EARG003": "No se pudo convertir parámetro",
            "EINT001": "error interno del servidor inesperada",
            "EACC001": "Credenciales malas",
            "EACC002": "Cuenta ya registrada",
            "EACC003": "E-mail ya registrado",
            "EACC004": "SSN no válido",
            "EACC005": "Contraseña incorrecta",
            "EACC006": "Iniciar sesión no puede estar vacía",
            "EACC007": "La contraseña no puede estar vacía",
            "EACC008": "El nombre de usuario ya existe. Intenta nuevamente. ",
            "EACC009": "Introduce una dirección de correo electrónico válida",
            "EACC010": "Ingresa un número de celular válido",
            "EACC011": "Nivel de seguridad bajo",
            "EACC012": "Email no reconocido, por favor regístrate",
            "EACC013": "Este número de teléfono ya existe en el sistema. Introduce un número de teléfono válido. ",
            "EACC014": "Campo obligatorio",
            "EACC015": "Invitación no existe",
            "EACC016": "La contraseña actual y la nueva deben ser diferentes. Elige otra contraseña.",
            "EACC017": "informó de la cuenta corriente no coincide",
            "EACC018": "El link para restablecer tu contraseña ha cadudado. Generar otro link",
            "EACC019": "Este link ya fue utilizado. Generar otro link. ",
            "EFLD001": "tamaño no válido",
            "EFLD002": "Este nombre de campo ya existe. Elegir otro nombre. ",
            "EFLD003": "El campo no existe",
            "EFLD004": "Región no existe",
            "EFLD005": "región no hay padre",
            "EGEO000": "Actualizar geometrías de error",
            "EGEO001": "región no hay padre",
            "EGEO002": "No se puede actualizar el campo. Eso no existe",
            "EGEO003": "Este campo ya existe. Revisa tus campos. ",
            "EGEO004": "geometría del campo no es válido o cruza con ella misma",
            "EGEO005": "Geometría encontró intersección",
            "EGEO006": "No se puede cambiar la región ID",
            "EGEO007": "El archivo subido debe ser un archivo .kml o un conjunto shapefile (.shp al menos, .dbf y .shx)",
            "EORG001": "moneda no válido",
            "EORG002": "las unidades del sistema no válidos",
            "EORG003": "URL de la imagen no es válida",
            "EORG004": "Inválida tipo de imagen",
            "EORG005": "org email no es válida",
            "EORG006": "teléfono no es válido org",
            "EORG007": "Org inválido Código postal",
            "EORG008": "Inválida org País",
            "EORG009": "org estado no válido",
            "EORG010": "Inválida org City",
            "EORG011": "ninguna organización no existe",
            "EORG012": "No tienes permiso para completar esta acción. Verificar permisos con el administrador de la cuenta. ",
            "EPRO001": "Código postal no válido",
            "EPRO002": "país no válido",
            "EPRO003": "Estado no válido",
            "EPRO004": "ciudad no válida",
            "EPRO005": "zona horaria no válido",
            "EPRO008": "Tipo de geometría no válida",
            "EPRO006": "Coordenadas inválidas. Escribir las coordenadas válidas. ",
            "EPRO007": "Propiedad no existe",
            "ESEA001": "El campo debe incluir las fechas de recolección y plantación",
            "ESEA002": "La fecha de la cosecha debe ser posterior a la fecha de la siembra. ",
            "ESEA003": "La fecha de la siembra está fuera del período del ciclo. ",
            "ESEA004": "La fecha de la cosecha está fuera del período del ciclo. ",
            "ESEA005": "Este nombre de ciclo ya existe. Elegir otro. ",
            "ESEA006": "La variedad no corresponde a la cosecha del ciclo. ",
            "ESEA007": "fecha de inicio no válido",
            "ESEA008": "La fecha de la siembra debe ser anterior a la fecha de la cosecha. ",
            "ESEA009": "Temporada no existe",
            "ESEA010": "Identificación del campo no debe ser nulo",
            "ESEA011": "No se puede cambiar el cultivo si hay campos vinculados a ese. Revisa los campos de tu ciclo.",
            "ESEA012": "org org estaciones y propiedades deben ser los mismos",
            "ESEA013": "org y organigramas estaciones de campo deben ser los mismos",
            "ESEA014": "Todas las propiedades listadas deben existir",
            "ESEA015": "Todos los campos enumerados debe existir",
            "ESEA016": "La cultura no existe",
            "EIAM001": "El usuario no tiene permiso para realizar esta operación. ",
            "EIAM002": "la autoridad no válido",
            "EIAM003": "contexto no válido",
            "EIAM004": "Cuenta no está gestionado por el solicitante",
            "EIAM005": "El permiso no existe en este contexto",
            "EIAM006": "la autenticación no válida",
            "Name code cannot be longer than {{MAX}} characters": "nombre de código no puede tener más de {{MAX}} caracteres",
            "Country cannot be longer than {{MAX}} characters": "País no puede tener más de {{MAX}} caracteres",
            "State cannot be longer than {{MAX}} characters": "Estado no puede tener más de {{MAX}} caracteres",
            "City cannot be longer than {{MAX}} characters": "Ciudad no puede exceder {{MAX}} caracteres ",
            "Field cannot have spaces": "El campo no puede contener espacios",
            "SSN cannot be longer than {{MAX}} characters": "SSN no puede tener más de {{MAX}} caracteres",
            "A message has been sent to your email. Please check your inbox.": "Un mensaje ha sido enviado a tu email. Revisa tu buzón.",
            "Cancel property edition": "Cancelar la edición de propiedad",
            "Please select time zone": "Por favor, seleccione la zona horaria",
            "Please enter valid Latitude!": "Por favor, introduzca Latitud válida!",
            "Please enter valid Longitude!": "Por favor, introduzca Longitud válida!",
            "Select crop": "Seleccione la cosecha",
            "Allow numbers and text only": "Permitir números y sólo texto",
            "Cancel create organization.": "Deshacer Crear organización.",
            "Upload": "despacho",
            "Please enter Number!": "Por favor, introduzca el número!",
            "There are some problems with your operation.": "Hay algunos problemas con su funcionamiento.",
            "Delete organization": "organización de exclusión",
            "Please enter valid Zip code!": "Por favor, introduzca el código postal válido!",
            "avatar": "avatar",
            "Image not found": "La imagen no encontrado",
            "Do you want to remove organization photo?": "Que desea eliminar la organización de fotos?",
            "Your password has been changed!": "La contraseña se ha cambiado!",
            "You have exceeded maximum number of characters allowed.": "Se ha superado el número máximo de caracteres permitidos.",
            "Please Enter Mobile number or Email": "Por favor, introduzca el número de móvil o correo electrónico",
            "SignUp": "suscribir",
            "Edit user": "Editar usuarios",
            "Not a valid user login": "No es un inicio de sesión de usuario válido",
            "Password cannot be longer than {{MAX}} characters": "La contraseña no puede tener más de {{MAX}} caracteres",
            "Cancel add managed user.": "Cancelar Añadir usuario gestionado.",
            "Cancel invite user.": "Cancel invitar usuario.",
            "Cancel edit user.": "Editar usuario cancelar.",
            "User email": "usuario de correo electrónico",
            "Please enter valid name!": "Por favor, introduzca un nombre válido!",
            "Something went wrong": "Algo salió mal",
            "Please enter current password.": "Introduzca su contraseña actual.",
            "Please enter new password.": "Por favor, introduzca la nueva contraseña.",
            "Managed user created successfully.": "De usuario gestionada creado correctamente.",
            "Create boundary": "crear límites",
            "Delete area": "área de exclusión",
            "Cancel edit field.": "Cancelar la edición de campo.",
            "not valid format": "No formato válido",
            "You can only upload JPG/PNG file!": "Sólo se pueden subir archivos JPG / PNG!",
            "Cancel edit profile settings.": "Cancelar los ajustes de edición del perfil.",
            "Invalid file": "archivo no válido",
            "Delete season": "temporada de exclusión",
            "You have got new invitation.": "Tiene nueva invitación.",
            "Cancel create season.": "Cancelar crear temporada.",
            "Cancel edit season.": "Cancelar la edición de la temporada.",
            "Field is getting intersect with other field": "campos superpuestos no están permitidos",
            "Cancel Draw your fields": "Cancelar los campos de dibujo",
            "Cancel Upload Shape files": "Cancelar archivos Shape Subir",
            "Bad credentials": "credenciales incorrectas",
            "Very Strong": "muy fuerte",
            "Draw your properties fields with the drawing tools.": "Dibuja tus campos de propiedades con las herramientas de dibujo.",
            "Create new Organization": "Crear Empresa",
            "Start date": "Fecha de inicio",
            "End date": "Fecha final",
            "Changes saved successfully": "Los cambios realizados con éxito",
            "There are no seasons for this property": "Hay temporadas de este priedade",
            "Cancel edit organization.": "Cancelar la edición de la organización",
            "Draw Another": "Dibujar otro",
            "Assign field(s)": "campo de enlace (s)",
            "This field is already been taken for another season": "Este campo ya está asociada a otro cultivo",
            "Season name cannot be longer than 22 characters": "El nombre del cultivo no puede contener más de 22 caracteres",
            "There are no active seasons for this property": "Ningún cultivo activa esta propiedad",
            "There are no active seasons for this organization": "Ningún cultivo activo de esta organización",
            "There are no inactive seasons for this property": "No se inactiva cultivo en esta propiedad",
            "There are no inactive seasons for this organization": "No se inactiva cultivo en esta organización",
            "Forecast": "pronóstico",
            "Historic": "historia",
            "Date Period": "período",
            "Hi": "¡Hola",
            "Click and drag to draw circle": "Haga clic y arrastre para dibujar un círculo",
            "Click to start drawing shape": "Haga clic para iniciar un dibujo",
            "No data": "No hay datos",
            "Duplicate field name": "Nombre del campo duplicado",
            "Today": "hoy",
            "Enter your email below": "Introduzca su dirección de correo electrónico",
            "Supported Language": "idiomas soportados",
            "Farm": "granja",
            "Image must smaller than 2MB": "La imagen debe ser inferior a 2 MB!",
            "Grower": "productor",
            "Manager": "gerente",
            "Admin": "administrador",
            "Dealer": "comerciante",
            "Consultant": "consultor",
            "Agronomist": "agrónomo",
            "Other": "otro",
            "Bad creadentials": "credenciales incorrectas",
            "Unable to load Image": "No se pudo cargar la imagen",
            "MIDDAY": "MEDIO DIA",
            "MIDNIGHT": "MEDIA NOCHE",
            "DATA: METEOBLUE": "DATOS: Meteoblue",
            "Weather Analysis": "tiempo de análisis",
            "SUN": "SOL",
            "MON": "SEG",
            "TUE": "TER",
            "WED": "MARRY",
            "THU": "QUI",
            "FRI": "FRI",
            "SAT": "SAT",
            "ESEA017": "El campo ya está asociado con otro cultivo en el mismo período",
            "ESEA018": "Safra no está relacionada con el campo",
            "Generate Management Zone": "Generar zonas de manejo",
            "Management Zone": "Zonas de manejos",
            "Please select a field": "Por favor seleccione un campo",
            "Filter Images": "Filtrar imágenes",
            "Source": "Satélite a utilizar",
            "IMAGE LIST": "LISTA DE IMAGEN",
            "No.of zones": "No de zonas",
            "Zone number should be between 2 to 15": "El número de zona debe estar entre 2 y 15",
            "Zone number should not be a decimal": "El número de zona no debe ser decimal",
            "Zone number is required": "Se requiere número de zona",
            "Number of pixel": "Número de píxeles",
            "Remove outlier": "Eliminar pequeñas zonas (n° pixels)",
            "Remove outliers":"eliminar zonas pequeñas",
            "Export": "Exportar a SMV",
            "Download": "Descargar y exportar",
            "Year": "Año",
            "Date": "Fecha",
            "Cloud Cover": "Nubes",
            "SOURCE": "FUENTE",
            "Drone": "Zumbido",
            "Sentinel": "Sentinel",
            "Landsat8": "Landsat8",
            "Year is required": "Año es obligatorio",
            "Date is required": "Se requiere fecha",
            "Source is required": "Se requiere fuente",
            "No images found": "No se encontraron imágenes",
            "Generate zone": "Generación de zonas",
            "Please select at least one image or shape file attribute": "Seleccione al menos una imagen o atributo de archivo de forma",
            "Start Year": "Año de inicio",
            "End Year": "Año final",
            "Start date is required": "Se requiere fecha de inicio",
            "End date is required": "Se requiere fecha de finalización",
            "Start and end dates are required": "Las fechas de inicio y finalización son obligatorias",
            "Start Date": "Fecha de inicio",
            "End Date": "Fecha final",
            "NDVI": "NDVI",
            "RGB": "RGB",
            "Variability View": "Vista de variabilidad",
            "Select Year": "Seleccione año",
            "Select Date": "Seleccione fecha",
            "Select Source": "Seleccionar fuente",
            "Can not generate zones for the images selected. Please select a different combination of images and try again.": "No se pueden generar zonas para las imágenes seleccionadas. Seleccione una combinación diferente de imágenes e intente nuevamente.",
            "Shapefile uploaded successfully.": "Shapefile cargado correctamente.",
            "Continue with SMV": "Continuar con Variable Nidera",
            "Something went wrong please try again.": "Algo salió mal. Por favor, vuelva a intentarlo.",
            "Success": "Éxito",
            "Enter country": "Ingrese país",
            "Enter city": "Entrar ciudad",
            "Enter state": "Ingresar estado",
            "Zip/Postal Code": "Código Postal",
            "Enter Zip/Postal Code": "Ingrese Código Postal",
            "Enter Address": "Ingresa la direccion",
            "Enter Property Name": "Ingrese el nombre de la propiedad",
            "Please input your name": "Por favor ingrese su nombre",
            "Please check your internet connection.": "Por favor revise su conexion a internet.",
            "sentinel (10 m resolution)": "sentinel (resolución de 10 m)",
            "landsat8 (30 m resolution)": "landsat8 (resolución de 30 m)",
            "Click on the button to filter images and generate zones for the field": "Haga clic en el botón para filtrar la imágenes y generar zonas para el campo",
            "Outlier pixel is required": "Se requiere un píxel atípico",
            "Outlier pixel should be a non zero positive number": "El píxel atípico debe ser un número positivo distinto de cero",
            "zone": "ambientacion",
            "The file": "El archivo",
            "was downloaded successfully.": "fue descargado con éxito.",
            "Session ID cannot be empty": "El ID de sesión no puede estar vacío",
            "Session ID is required": "Se requiere ID de sesión",
            "Session is not active": "La sesión no está activa",
            "Nidera access token expired…": "El token de acceso de Nidera venció…",
            "Invalid Client ID/Client Secret for Nidera": "ID de cliente no válida / secreto de cliente para Nidera",
            "Invalid Client ID/Client Secret for BASE": "ID de cliente / secreto de cliente no válido para BASE",
            "'field_id' is required": "'field_id' es obligatorio",
            "'geometry' is required": "se requiere 'geometría'",
            "'fromDate' is required": "Desde la fecha es obligatoria",
            "'endDate' is required": "La fecha de finalización es obligatoria",
            "'sources' is required": "'fuentes' es obligatorio",
            "'geom_id' is required": "geom_id es obligatorio",
            "'export_ids' is required": "los ID de exportación son obligatorios",
            "'zone' is required": "'ambientacion' es obligatorio",
            "cannot copy sequence with size 4 to array axis with dimension 5": "no se puede copiar la secuencia con tamaño 4 al eje de matriz con dimensión 5",
            "field_id cannot be empty": "Field_id no puede estar vacía",
            "geometry cannot be empty": "la geometría no puede estar vacía",
            "fromDate cannot be empty": "from date no puede estar vacío",
            "endDate cannot be empty": "La fecha de finalización no puede estar vacía",
            "source cannot be empty": "la fuente no puede estar vacía",
            "Missing Date Parameter : not enough values to unpack (expected 3, got 2)": "Parámetro de fecha que falta: no hay suficientes valores para descomprimir (esperado 3, obtenido 2)",
            "Missing Date Parameter : not enough values to unpack (expected 3, got 1)": "Parámetro de fecha que falta: no hay suficientes valores para descomprimir (esperado 3, obtuve 1)",
            "Missing Required Parameter : 'asset_ids'": "Falta el parámetro obligatorio: 'asset_ids'",
            "Missing Required Parameter : 'geom_id'": "Falta el parámetro obligatorio: 'geom_id'",
            "asset ids cannot be empty": "Los ID de activos no pueden estar vacíos",
            "geom id cannot be empty": "Geom id no puede estar vacía",
            "Email and/or password is incorrect. Cannot authenticate.": "El correo electrónico y / o la contraseña son incorrectos. No se puede autenticar.",
            "Expecting value: line 1 column 1 (char 0)": "Valor esperado: línea 1 columna 1 (carácter 0)",
            "Download URL not found for the export ids": "No se encontró la URL de descarga para los ID de exportación",
            "Unsupported grant type: secret": "Tipo de subvención no compatible: secreta",
            "Unable to queue image download.": "No se puede poner en cola la descarga de la imagen.",
            "'geom_id' is required": "'geom_id' es obligatorio",
            "'export_ids' is required": "'export_ids' es obligatorio",
            "'zone' is required": "'zona' es obligatorio",
            "export ids cannot be empty": "Los ID de exportación no pueden estar vacíos",
            "number of zones cannot be empty": "número de zonas no puede estar vacío",
            "Unable to fetch downloads for user.": "Incapaz de recuperar descargas para la usuario.",
            "'download' is required": "'descargar' es obligatorio",
            "upload_to cannot be empty": "Upload_to no puede estar vacía",
            "cannot copy sequence with size 2 to array axis with dimension 3": "no se puede copiar la secuencia con tamaño 2 al eje de matriz con dimensión 3",
            "index 0 is out of bounds for axis 0 with size 0": "el índice 0 está fuera de límites para el eje 0 con tamaño 0",
            "could not broadcast input array from shape (50,50) into shape (17,17)": "no se pudo transmitir la matriz de entrada de la forma (50,50) a la forma (17,17)",
            "Please zoom in to select the fields.": "Acerca la imagen para seleccionar los campos.",
            "Error Detecting Boundaries": "Error al detectar límites",
            "Error in Detecting Boundaries": "Error al detectar límites",
            "Fields added successfully...": "Campos agregados correctamente ...",
            "Detect fields": "Detectar campos",
            "No Fields Selected": "Ningún campo seleccionado",
            "Some fields may not be saved due to overlapping boundarie": "Es posible que algunos campos no se guarden debido a límites superpuestos",
            "Cancel Detect your fields": "Cancelar Detecta tus campos",
            "Drag into parent polygon for subtraction": "Arrastre al polígono principal para restar",
            "Select the appropriate image": "Seleccione la imagen apropiada",
            "Select the appropriate image from option shown": "Seleccione la imagen apropiada de la opción que se muestra",
            "Select shape file": "Seleccionar archivo de forma",
            "Upload shape file": "Subir archivo de forma",
            "Uploading": "Subiendo",
            "Please select a shape file": "Seleccione un archivo de forma",
            "Select only Shapefiles": "Seleccionar solo archivos de forma",
            "Include Elevation": "Incluir elevación",
            "ElEVATION": "Elevación",
            "No elevation image found": "No se encontró ninguna imagen de elevación",
            "Click on the map to update the latitude and longitude":"Haga clic en el mapa para actualizar la latitud y longitud",
            "The result will contain images from the year selected here to the current year" : "El resultado contendrá imágenes desde el año seleccionado aquí hasta el año actual",
            "Satellite from which the images come." : "Satélite del cual provienen las imágenes.",
            "Include elevation layer derived from satellite to build the ambientation":"Incluir capa de elevación derivada de satélite para construir la ambientación",
            "The result will contain the range of months to filter images. Recommended images are around the critical period for crops.":"El resultado contendrá el rango de meses para filtrar imágenes. Las imágenes recomendadas están alrededor del período crítico de los cultivos.",
            "Threshold percentage of the satellite scene covered by clouds. he higher the allowed limit, be careful of clouds that interfere in the field." : "Porcentaje tolerable de la escena satelital cubierta por nubes. Cuanto mayor sea el límite permitido, tenga cuidado con las nubes que interfieren en el campo.",
            "Generate management zones only with Shapefiles (elevation maps; yield maps).":"Generar zonas de manejo solo con Shapefiles (mapas de elevación: mapas de rendimiento).",
            "Elevation file. Recommended for field with a high slope." :"Archivo de elevación. Recomendable para lotes con pendiente elevada.",
            "Export management zones to SMV and continue with the recommendation.":"Exportar la ambientación a SMV y seguir con la recomendación.",
            "Delete small areas within larger areas. The value entered is the minimum area size threshold." : "Elimina pequeñas áreas dentro de otras áreas más grandes. El valor introducido es el umbral mínimo de tamaño de áreas.",
            "Request time out, Please try again." : "Solicitar tiempo de espera. Vuelva a intentarlo.",
            "We are downloading the images for your field please try in a couple of minutes":  "Estamos descargando las imágenes para su campo, inténtelo en un par de minutos.",
            "Sign Up is sussceesfully and you will be redirected to login page":  "El registro se realizó correctamente y será redirigido a la página de inicio de sesión",
            "Something wrong for Imagery Sensing Contract": "Algo anda mal con el contrato de detección de imágenes",
            "Something wrong for Nidera Contract": "Algo anda mal con el contrato Nidera",
            "Something wrong for Remote Sensing Contract": "Algo anda mal con el contrato de teledetección",
            "We are downloading the images of your lot for the automatic setting option.Please return within 2 hours": "Estamos descargando las imágenes de tu lote para la opción de ambientación automática. Por favor regresar dentro de 2 horas",
            "Remove small zones in square meters between 100 and 1000":"remover zonas pequeñas en metros cuadrados-valores entre 100 by 1000.",
            "Delete polygons or small areas.The entered value indicates the minimum surface area (in m2) accepted for a polygon or area.":"Eliminar polígonos o áreas pequeñas. El valor introducido indica la superficie mínima(en m2) aceptada para un polígono o área."
        }
    }
}