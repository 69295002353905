import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import HeaderArrow from '../../public/Header_arrow.svg';
import MzmaHeaderArrow from '../../public/Mzma_Header_Arrow.svg';

export const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    .hearder_downArrow{
    position: absolute;
    z-index: 999;
    background: white;
    padding: 6px;
    border-radius: 50%;
    top: -7px;
    cursor: pointer;
    }
`;
export const HeaderDivider = props => {
  return (
    <StyledDiv>
      <input alt="" type="image"
        className="hearder_downArrow"
        style={{ height: '25px', width: '25px' }}
        src={localStorage.getItem('session_id') ? HeaderArrow : MzmaHeaderArrow}
        onClick={() => props.handleClick()}
      />
    </StyledDiv>
  );
};

HeaderDivider.propTypes = {
  handleClick: PropTypes.func.isRequired,
};
