import { combineReducers } from 'redux';
import { orgs } from './orgs';
import { header } from './header';
import { properties } from './properties';
import { user } from './user';

const rootReducer = combineReducers({
  orgs,
  header,
  properties,
  user,
});

export { rootReducer };
