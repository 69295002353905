import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, PageHeader, List, Select, Icon } from 'antd/lib';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { iff } from '../../utils/iff';
import AreaRedIcon from '../../public/area-red-icon.svg';
import AreaWhiteIcon from '../../public/area-white-icon.svg';
import { Maps } from '../Maps/Maps';
import { UploadContainer, FileListContainer, ButtonContainer } from './UploadShapeFiles.style.js';
import { PageLayout } from '../Layout/page-layout';
import { StyledHeader } from '../landingFarm/CreateEditFarmComponent.style';
import Edit from '../../public/edit-glyph.svg';
import Delete from '../../public/bin-outline.svg';
import { createBulkFieldsByPropId } from '../../actions/fields';
import { getPropertyDetailsById, fetchOrgs } from '../../actions';
import { getLocationFromBrowser } from '../../utils/location';
import { Loader } from '../Loader/Loader';
import { ErrorModal } from '../common/Modal/ErrorModal';
import { getErrorMessageByCode } from '../../utils/getErrorMessageByCode';
import { getAreaByUnit, getUnitText } from '../../utils/getAreaByUnit';
import { getOrgDetailsByOrgId } from '../../selectors/organizationSelectors';
import { ConfirmModal } from '../common/ConfirmModal';
import { URL_CONSTANTS } from '../../utils/history';
import {
  StyledInput,
  StyledSelect,
  StyledSaveButton,
  StyleListItem
} from '../../utils/styles.theming';

const DEFAULT_LATLONG = 10.0;

const { Option } = Select;
const turf = require('@turf/turf');

const ShapeFilesList = (props) => {
  const [newFields, setNewFields] = useState(props.location.state.geoJsons);
  const [existingFields] = useState([]);
  const [totalFields, setTotalFields] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [property, setProperty] = useState({});
  const [lat, setLat] = useState(DEFAULT_LATLONG);
  const [long, setLong] = useState(DEFAULT_LATLONG);
  const [isValid, setIsValid] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [loader, setLoader] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { t } = useTranslation();
  const [selectedFieldItem] = useState({});

  useEffect(() => {
    (async () => {
      const propId = props.match.params.farmId;
      const propertyRes = await getPropertyDetailsById(propId);
      const coordinates = get(propertyRes.data, 'reference_point.coordinates');
      if (isEmpty(coordinates)) {
        (async () => {
          try {
            const location = await getLocationFromBrowser();
            setLong(location.longitude);
            setLat(location.latitude);
          } catch (err) {
            setErrorMessage(get(err, 'message'));
          }
        })();
      } else {
        setLat(coordinates[0] || DEFAULT_LATLONG);
        setLong(coordinates[1] || DEFAULT_LATLONG);
      }
      setProperty(propertyRes.data);
    })();
  }, []);

  useEffect(() => {
    const newfieldList = validateDisjointFields();
    setNewFields(newfieldList);
    const total = existingFields.concat(newfieldList);
    setTotalFields(total);
  }, [existingFields]);

  if (isEmpty(get(props, 'location.state.geoJsons'))) {
    props.history.push(
      URL_CONSTANTS.CREATE_FIELD_SHAPE_FILE_UPLOADER({
        orgId: props.match.params.id,
        farmId: props.match.params.farmId
      })
    );
    return null;
  }

  /**
   * This function to validate if any uplaoded field geometry is disjoint with other or not.
   */
  const validateDisjointFields = () => {
    let newfieldList = newFields.map((f) => ({
      ...f,
      color: undefined,
      isInValid: false
    }));
    if (newFields.length < 1) {
      setIsValid(false);
      return newFields;
    }
    setIsValid(true);
    const arr = existingFields.concat(newfieldList);
    for (let i = 0; i < arr.length - 1; i++) {
      for (let j = i + 1; j < arr.length; j++) {
        const field1 = arr[i].geometry;
        const field2 = arr[j].geometry;
        if (!isEmpty(field1) && !isEmpty(field2) && !turf.booleanDisjoint(field1, field2)) {
          newfieldList = newfieldList.map((f) => {
            if (f.id === arr[i].id || f.id === arr[j].id) {
              f.isInValid = true;
              f.color = 'red';
            }
            setIsValid(false);
            return f;
          });
        }
      }
    }
    return newfieldList;
  };

  const handleBackRedirection = () => {
    setNewFields([]);
    props.history.push(
      URL_CONSTANTS.CREATE_FIELD_SHAPE_FILE_UPLOADER({
        orgId: props.match.params.id,
        farmId: props.match.params.farmId
      })
    );
  };
  const onBackClick = () => {
    setShowConfirmModal(true);
  };

  const onSaveButtonClicked = () => {
    const reqobj = {
      parentId: property.root_region_id,
      newFields,
      orgId: props.match.params.id,
      farmId: props.match.params.farmId,
      addNewFieldFrom: props.location.state.addNewFieldFrom
    };
    setLoader(true);
    createBulkFieldsByPropId(reqobj)
      .then(() => {
        setLoader(false);
      })
      .catch((errors) => {
        setLoader(false);
        if (!Array.isArray(errors)) {
          return setErrorMessage(getErrorMessageByCode(get(errors, 'response.data.code')));
        }
        let updatedNewFields = [...newFields];
        updatedNewFields = updatedNewFields.map((field) => {
          const errorred = errors.find((e) => field.id === e.key);
          if (isEmpty(errorred)) {
            field.isInValid = false;
          } else {
            field.isInValid = true;
            field.color = 'red';
          }
          return field;
        });
        setNewFields(updatedNewFields);
        setErrorMessage(getErrorMessageByCode(get(errors, '[0].code')));
      });
  };
  const onEditClicked = (item) => {
    props.history.push({
      pathname: URL_CONSTANTS.EDIT_FIELD_DRAW_UPLOADED({
        orgId: props.match.params.id,
        farmId: props.match.params.farmId
      }),
      state: {
        existingFields,
        newFields: newFields,
        currentField: item
      }
    });
  };

  const onDeleteClicked = (item) => {
    let files = newFields.filter((f) => {
      return item.id !== f.id;
    });
    setNewFields(files);
    const total = existingFields.concat(files);
    setTotalFields(total);
    setIsValid(isEmpty(files.find((f) => f.isInValid)));
    if (files.length < 1) {
      setIsValid(false);
    }
    props.history.push({
      state: {
        geoJsons: files
      }
    });
  };

  const getFilteredFieldByName = () =>
    newFields.filter(
      (field) =>
        (field.name || '').toLocaleLowerCase().indexOf((searchTerm || '').toLocaleLowerCase()) >= 0
    );

  /**
   * On click on the field heighlightin field
   * */

  const handleFieldClick = (selectedFieldId) => {
    if (!selectedFieldId) {
      return;
    }
    newFields.find((field) => {
      if (field.id === selectedFieldId) {
        setselectedFieldItem(field);
      }
    });
  };

  return PageLayout({
    content: (
      <>
        {iff(loader === true, <Loader />)}
        <Row>
          <Col span={8}>
            <StyledHeader>
              <Icon
                data-testid="ShapeFileList-back-btn"
                onClick={onBackClick}
                className='back-button'
                type='left'
                style={{ fontSize: '14px' }}
              />
              <PageHeader>
                <p data-testid="page-header" style={{ color: 'grey', display: 'inline' }}>
                  {t('Create fields')}
                </p>
                {` • `}
                {t('Upload file')}
              </PageHeader>
            </StyledHeader>
            <UploadContainer>
              <div data-testid="page-title" className='header'>{t(`All fields`)}</div>
              <StyledInput
                value={searchTerm}
                size='large'
                placeholder={t('Search by name')}
                onChange={(e) => setSearchTerm(e.target.value)}
                data-testid="search-input"
              />
              <FileListContainer>
                <List
                  dataSource={getFilteredFieldByName()}
                  split={false}
                  renderItem={(item) => (
                    <List.Item>
                      <StyleListItem onClick={() => handleFieldClick(item.id)}>
                        <List.Item.Meta
                          avatar={
                            <div
                              className={`icon-container ${iff(
                                item.isInValid === true,
                                'error',
                                'success'
                              )} ' imgBorder '${item.id === selectedFieldItem.id ? ' activeImage' : ''
                                }`}
                              data-testid={`item-image-${item.name}`}>
                              <img
                                style={{
                                  height: '18px'
                                }}
                                src={iff(item.isInValid === true, AreaRedIcon, AreaWhiteIcon)}
                                alt={iff(item.isInValid === true, "AreaRedIcon", "AreaWhiteIcon")}
                              />
                            </div>
                          }
                          title={item.name}
                          description={
                            <>
                              <div>{`${getAreaByUnit(
                                item.adjustedSize,
                                props.currentOrg.unit_system
                              ) || 0} ${getUnitText(props.currentOrg.unit_system)}`}</div>
                              {iff(
                                item.isInValid === true,
                                <div data-testid={`item-desc-${item.name}`} style={{ color: 'red' }}>{t('Overlapping vectors')}</div>
                              )}
                            </>
                          }
                        />
                        <div>
                          <StyledSelect data-testid={`actions-dd-${item.name}`} value={t('Options')} style={{ width: 94 }}>
                            <Option
                              value='edit'
                              onClick={() => {
                                onEditClicked(item);
                              }}
                              data-testid={`edit-field-btn-${item.name}`}>
                              <img src={Edit} alt="Edit" /> <span>{t('Edit')}</span>
                            </Option>
                            <Option
                              value='delete'
                              onClick={() => {
                                onDeleteClicked(item);
                              }}
                              data-testid={`delete-field-btn-${item.name}`}>
                              <img src={Delete} alt="Delete" />
                              {t('Delete')}
                            </Option>
                          </StyledSelect>
                        </div>
                      </StyleListItem>
                    </List.Item>
                  )}
                />
              </FileListContainer>
              <ButtonContainer>
                <StyledSaveButton
                  htmlType='submit'
                  disabled={!isValid}
                  type='primary'
                  size='large'
                  onClick={onSaveButtonClicked}
                  data-testid="submit-btn">
                  {t('Done')}
                </StyledSaveButton>
                <ErrorModal
                  visible={!!errorMessage}
                  message={errorMessage}
                  okText={t('Ok')}
                  onOk={() => {
                    setErrorMessage('');
                  }}
                />
                <ConfirmModal
                  visible={showConfirmModal}
                  title={t('Cancel Upload Shape files')}
                  onOk={() => {
                    setShowConfirmModal(false);
                    handleBackRedirection();
                  }}
                  onCancel={() => setShowConfirmModal(false)}>
                  <p data-testid="cancel-popup-note">{t('All of your progress will be lost.')}</p>
                  <p data-testid="cancel-popup-confirm">{t('Are you sure you want to cancel?')}</p>
                </ConfirmModal>
              </ButtonContainer>
            </UploadContainer>
          </Col>
          <Col span={16}>
            <Maps
              geoJsons={totalFields}
              reload={true}
              focusLat={lat}
              focusLong={long}
              isDrawable={false}
              selectedFieldItem={selectedFieldItem}
              selectedFieldId={selectedFieldItem.id}
              handleFieldClick={handleFieldClick}
            />
          </Col>
        </Row>
      </>
    )
  });
};

ShapeFilesList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      farmId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired
    })
  }),
  history: PropTypes.object,
  location: PropTypes.object,
  currentOrg: PropTypes.object
};

const mapStateToProps = (state, ownProps) => ({
  currentOrg: getOrgDetailsByOrgId(state, { orgId: get(ownProps, 'match.params.id', null) })
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrgs: () => dispatch(fetchOrgs())
});

export const ShapeFilesListComponent = connect(mapStateToProps, mapDispatchToProps)(ShapeFilesList);
