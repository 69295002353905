import React from 'react';
import { Progress } from 'antd/lib';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledProgress = styled(Progress)`
  width: 55vw;
  top: 20px;
  .ant-progress-inner {
    height: 5px;
    border-radius: 10px;
  }
  .ant-progress-bg {
    height: 5px;
  }
`;
export const ProgressBar = (props) => {
  const progressMap = [
    { color: '#ffffff', per: 0 },
    { color: '#8E9293', per: 1 },
    { color: '#EB8205', per: 33 },
    { color: '#F2CD08', per: 66 },
    { color: '#749204', per: 100 }
  ];
  return (
    <StyledProgress
      percent={progressMap[props.progress].per}
      showInfo={false}
      strokeColor={progressMap[props.progress].color}
    />
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired
};
