const turf = require('@turf/turf');

const convertSqMetersToAcre = (sqMeter) => {
  return sqMeter * 0.00024711;
};

const convertSqMetersToHectare = (sqMeter) => {
  return sqMeter / 10000;
};

export const getAreaInAcreByGeoJson = (geoJson) => {
  const areaInSqMeter = turf.area(geoJson);
  const areaInAcre = convertSqMetersToAcre(areaInSqMeter);
  return Number(areaInAcre).toFixed(2);
};

export const getAreaInHectareByGeoJson = (geoJson) => {
  const areaInSqMeter = turf.area(geoJson);
  const areaInHectare = convertSqMetersToHectare(areaInSqMeter);
  return Number(areaInHectare).toFixed(2);
};
