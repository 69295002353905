import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PropTypes from 'prop-types';
import './style.css';

export const PhoneInputBox = (props) => {
  let containerClass = 'react-tel-input ';
  if (props.error) {
    containerClass = containerClass.concat('react-tel-input-error');
  }
  return (
    <ReactPhoneInput
      {...props}
      inputExtraProps={{
        name: 'phone',
        required: true,
        enableSearch: true
      }}
      inputProps={{
        autoFocus: props.autoFocus,
        tabIndex: 1
      }}
      containerClass={containerClass}
    />
  );
};

PhoneInputBox.propTypes = {
  error: PropTypes.bool,
  autoFocus: PropTypes.bool
};
