import React from 'react';
import { MemoizeRoutes } from '../Routes/Routes';
import { toThemeHoc } from '../styles/Theme';
import { StyledRoot } from './App.style';
import { ErrorModal } from '../common/Modal/ErrorModal';
import { useTranslation } from 'react-i18next';
import { isSessionIDAvailable } from '../../utils/common-methods';

let appTheme = 'managementZoneTheme';
const session_idIndex = location.href.indexOf('session_id');

/**
 * Condition for applying dynamic Theme on app
 */
if (session_idIndex > 0 || isSessionIDAvailable()) {
  appTheme = 'nideraTheme';
}
const App = () => {
  const { t } = useTranslation();
  const [showNetworkErrorModal, setShowNetworkErrorModal] = React.useState(false);
  React.useEffect(() => {
    const networkInterval = setInterval(() => {
      if (navigator && navigator.onLine === showNetworkErrorModal) {
        setShowNetworkErrorModal(!navigator.onLine);
      }
    }, 1000);
    return () => {
      clearInterval(networkInterval);
    };
  }, [showNetworkErrorModal]);

  return (
    <StyledRoot>
      {showNetworkErrorModal && (
        <ErrorModal
          visible={showNetworkErrorModal}
          message={t('Please check your internet connection.')}
          okText={t('Ok')}
          onOk={() => setShowNetworkErrorModal(false)}
        />
      )}
      <MemoizeRoutes />
    </StyledRoot>
  );
};

export const ThemedApp = toThemeHoc(App, appTheme);