import React from 'react';
import styled from 'styled-components';
import baseLogoImg from '../../public/cropwise-logo-border.svg';

const Logo = styled.img`
  height: 95px;
  position: absolute;
  top: 7%;
  left: 34%;
  width: 119px;
`;
export const BaseLogo = (props) => <Logo src={baseLogoImg} {...props} />;
