import React, { useEffect, useState } from 'react';
import { Row, Col, Input, List, Select, PageHeader, Icon } from 'antd/lib';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { getFieldsByPropertyId, deleteFieldByFieldId } from '../../actions/fields';
import { iff } from '../../utils/iff';
import { Maps } from '../Maps/Maps';
import { PageLayout } from '../Layout/page-layout';
import Plus from '../../public/plus.svg';
import PlusWhite from '../../public/plus-white.svg';
import Edit from '../../public/edit-glyph.svg';
import Delete from '../../public/bin-outline.svg';
import { getLatLongByPropId } from '../../utils/getLatLongByPropId';
import { fetchOrgs, getPropertyDetailsById } from '../../actions';
import { ConfirmationModal } from './Modal/ConfirmationModal';
import { CreateBoundaryModal } from './Modal/CreateBoundaryModal';
import { StyledHeader } from '../../components/landingFarm/CreateEditFarmComponent.style';
import '../../components/settings/Component.css';
import WithBoundary from '../../public/icon_withboundary.svg';
import { AppConstants } from '../../utils/app.constants';
import { ErrorModal } from '../common/Modal/ErrorModal';
import { getErrorMessageByCode } from '../../utils/getErrorMessageByCode';
import { Loader } from '../Loader/Loader';
import { getAreaByUnit, getUnitText } from '../../utils/getAreaByUnit';
import { getOrgDetailsByOrgId } from '../../selectors/organizationSelectors';
import { URL_CONSTANTS } from '../../utils/history';
import {
  StyledSelect,
  StyledSaveButton,
  StyleListItem,
  StyledDiv
} from '../../utils/styles.theming';

const StyledAllFields = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #2f3031;
`;
const StyledSearch = styled(Input)`
  font-family: ${(props) => get(props, 'theme.font.family')};
  border: 1px solid #e8eaed;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 12px;
  color: ${(props) => get(props, 'theme.colors.buttonHoverColor')} !important;
  &:focus {
    color: ${(props) => get(props, 'theme.colors.buttonHoverColor')};
    border: ${(props) => get(props, 'theme.colors.inputFocusBorder')};
  }
`;

const StyledNewField = styled.div`
  border: 1px solid #e8eaed;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  margin-top: 12px;
  height: 40px;
  align-items: center;
  &:hover {
    border: 1px solid ${props => get(props, 'theme.colors.scrollbarThumbBgColor')};
  }

  &:active {
    color: #fff;
  }
`;

const StyledAddNew = styled.div`
  font-family: ${(props) => get(props, 'theme.font.family')};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  color: #515253;
  padding-left: 24px;
  cursor: pointer;
  &:hover {
    // color: ${props => get(props, 'theme.colors.primary')};
    color: #f9c43d
  }
  &:active {
    color: #fff;
  }
`;
const StyledImg = styled.img`
  padding-left: 18px;
  cursor: pointer;
`;


const DEFAULT_LAT_LONG = 10;
const AllFields = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [searchingValue, setSearchingValue] = useState('');
  const [lat, setLat] = useState(DEFAULT_LAT_LONG);
  const [long, setLong] = useState(DEFAULT_LAT_LONG);
  const [visible, setVisible] = useState(false);
  const [createBoundaryPopup, setCreateBoundaryPopup] = useState(false);
  const [property, setProperty] = useState({});
  const [fieldToBeDeleted, setFieldToBeDeleted] = useState({});
  const [isAddNewClicked, setIsAddNewClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [selectedFieldItem, setSelectedFieldItem] = useState({});
  const [routerState, setRouterState] = useState({});

  const { Option } = Select;
  const addBoundryFieldId = '';

  useEffect(() => {
    if (localStorage.removeItem('routerState')) {
      localStorage.removeItem('routerState');
    }
  }, []);
  useEffect(() => {
    const farmId = props.match.params.farmId;
    const getFieldDetails = async () => {
      const propertyRes = await getFieldsByPropertyId(farmId);
      let allFieldsRes = propertyRes.data.content || [];
      setData(allFieldsRes);
      setIsDataLoading(false);
    };
    const getDetails = async () => {
      try {
        setIsDataLoading(true);
        props.fetchOrgs().then((response) => {
          getFieldDetails();
        });
      } catch (error) {
        if (!isEmpty(error) && Object.keys(error.response.data).length > 0) {
          const errorMessageByCode = getErrorMessageByCode(error.response.data.code);
          setErrorMessage(errorMessageByCode);
        }
      }
    };
    const getPropDetails = async () => {
      try {
        const propertyRes = await getPropertyDetailsById(farmId);
        setProperty(propertyRes.data);
      } catch (error) {
        if (!isEmpty(error) && Object.keys(error.response.data).length > 0) {
          const errorMessageByCode = getErrorMessageByCode(error.response.data.code);
          setErrorMessage(errorMessageByCode);
        }
      }
    };
    getPropDetails();
    getDetails();
    getLatLongByPropId(farmId, setLat, setLong, DEFAULT_LAT_LONG);
  }, [props.match]);

  const onCancelCreateBoundaryPopup = () => {
    setCreateBoundaryPopup(false);
  };
  const onCancelDeletePopup = () => {
    setVisible(false);
  };

  const onBackButtonClicked = () => {
    const orgId = props.match.params.id;
    const propertyId = props.match.params.farmId;
    props.history.push(URL_CONSTANTS.EDIT_FARM({ orgId, farmId: propertyId }));
  };

  const onFinishClicked = () => {
    const orgs = props.match.params.id;
    props.history.push(URL_CONSTANTS.LANDING_ORGANIZATION({ orgId: orgs }));
  };

  const handleSearch = (e) => {
    const matchedValue = [];
    let counter = 0;
    setSearchingValue(e.target.value);
    data.forEach((value) => {
      if (value.name.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())) {
        matchedValue.push(value);
        counter++;
      }
    });
    if (counter === 0) {
      setSearchedData([]);
    } else {
      setSearchedData(matchedValue);
    }
  };

  const handleEdit = (item) => {
    props.history.push(
      URL_CONSTANTS.EDIT_FIELD_DRAW({
        orgId: props.match.params.id,
        farmId: props.match.params.farmId,
        fieldId: item.id
      })
    );
  };
  const onDeleteClick = (item) => {
    setVisible(true);
    setFieldToBeDeleted(item);
  };
  const handleDelete = async () => {
    try {
      await deleteFieldByFieldId(fieldToBeDeleted.id);
      const nonDeletedData = data.filter((value) => {
        return value.id !== fieldToBeDeleted.id;
      });
      setData(nonDeletedData);
      setVisible(false);
    } catch (error) {
      if (!isEmpty(error) && Object.keys(error.response.data).length > 0) {
        setVisible(false);
        const errorMessageByCode = getErrorMessageByCode(error.response.data.code);
        setErrorMessage(errorMessageByCode);
      }
    }
  };

  const handleFieldClick = (selectedFieldId) => {
    if (!selectedFieldId) {
      return;
    }
    const field = data.find((field) => field.id == selectedFieldId);
    setSelectedFieldItem(field);
  };

  const onAddNewClick = () => {
    setIsAddNewClicked(true);
    setCreateBoundaryPopup(true);
  };


  return PageLayout({
    content: (
      <div>
        {iff(isDataLoading, <Loader />)}
        <Row style={{ backgroundColor: '#fff' }}>
          <Col span={9}>
            <StyledHeader data-testid="your-fields-heading">
              <Icon
                data-testid="all-fields-page-back-btn"
                onClick={onBackButtonClicked}
                className='back-button'
                type='left'
                style={{ fontSize: '14px' }}
              />
              <PageHeader>{t('Your fields')}</PageHeader>
            </StyledHeader>
            <div style={{ margin: '24px' }}>
              <div>
                <StyledAllFields data-testid="all-fields-page-title">{t('All fields')}</StyledAllFields>
                <StyledSearch
                  size='large'
                  placeholder={t('Search by name')}
                  onChange={handleSearch}
                  autoFocus
                  maxLength={AppConstants.ADD_FIELDS.SEARCH_NAME_MAX_LENGTH}
                  data-testid="all-fields-search-input"
                />
                <StyledNewField
                  style={iff(createBoundaryPopup, { backgroundColor: '#f9c43d', color: '#fff' })}
                  onClick={onAddNewClick}>
                  <StyledImg src={iff(createBoundaryPopup === true, PlusWhite, Plus)} />
                  <StyledAddNew data-testid="add-new-fields-btn">{t('Add New Field')}</StyledAddNew>
                </StyledNewField>
              </div>
              <StyledDiv className='allFields' id='scollId'>
                <List
                  style={{ maxHeight: '340px' }}
                  dataSource={iff(searchingValue.length > 0, searchedData, data)}
                  split={false}
                  renderItem={(item) => (
                    <List.Item data-testid={`field-${item.name.replaceAll(" ", "-")}`}>
                      <StyleListItem onClick={() => handleFieldClick(item.id)}>
                        <List.Item.Meta
                          avatar={
                            <img
                              className={
                                'imgBorder ' +
                                (item.id === selectedFieldItem.id ? 'activeImage' : '')
                              }
                              src={WithBoundary}
                              alt="WithBoundary"
                            />
                          }
                          title={item.name}
                          description={
                            <div>{`${getAreaByUnit(
                              item.declared_area,
                              props.currentOrg.unit_system
                            )} ${getUnitText(props.currentOrg.unit_system)}`}</div>
                          }
                        />
                        <div>
                          <StyledSelect
                            value={t('Options')}
                            style={{ width: 150, color: '#2F3031' }}
                            data-testid={`field-action-dd-${item.name.replaceAll(" ", "-")}`}>
                            <Option value='edit' onClick={() => handleEdit(item)} data-testid={`edit-field-${item.name.replaceAll(" ", "-")}`}>
                              <img src={Edit} alt="Edit" /> <span>{t('Edit')}</span>
                            </Option>
                            <Option value='delete' onClick={() => onDeleteClick(item)} data-testid={`delete-field-${item.name.replaceAll(" ", "-")}`}>
                              <img src={Delete} alt="Delete" />
                              <span style={{ marginLeft: '4px' }}>{t('Delete')}</span>
                            </Option>
                          </StyledSelect>
                        </div>
                      </StyleListItem>
                    </List.Item>
                  )}
                />
                {iff(
                  visible === true,
                  <ConfirmationModal
                    visible={visible}
                    deleteProperty={true}
                    onCancel={() => onCancelDeletePopup()}
                    onDelete={() => handleDelete()}
                    name={fieldToBeDeleted.name}
                    title={t('Delete area')}
                  />
                )}
                {iff(
                  createBoundaryPopup === true,
                  <CreateBoundaryModal
                    onCancel={() => onCancelCreateBoundaryPopup()}
                    {...props}
                    routerState={routerState}
                    isAddNewClicked={isAddNewClicked}
                    fieldId={addBoundryFieldId}
                    addNewFieldFrom={'AllFields'}
                  />
                )}
              </StyledDiv>


              <div style={{ float: 'right' }}>
                <StyledSaveButton
                  className='btn-green'
                  style={{ marginLeft: '1em', height: '45px', width: '135px' }}
                  onClick={onFinishClicked}
                  data-testid="finish-btn">
                  {t('Finish')}
                </StyledSaveButton>
              </div>
            </div>
          </Col>
          <Col span={15} data-testid="all-fields-map-container">
            <Maps
              isDrawable={false}
              focusLat={+lat}
              focusLong={+long}
              geoJsons={data}
              selectedFieldItem={selectedFieldItem}
              selectedFieldId={selectedFieldItem.id}
              handleFieldClick={handleFieldClick}
              onMoveEnd={(event) => {
                setRouterState(event);
              }}
            />
          </Col>
          {iff(
            errorMessage.length > 0,
            <ErrorModal
              visible={!!errorMessage}
              message={errorMessage}
              okText={t('Ok')}
              onOk={() => {
                setErrorMessage('');
              }}
            />
          )}
        </Row>
      </div>
    )
  });
};

AllFields.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      farmId: PropTypes.string.isRequired
    })
  })
};

const mapDispatchToProps = (dispatch) => ({
  fetchOrgs: () => dispatch(fetchOrgs())
});

const mapStateToProps = (state, ownProps) => ({
  currentOrg: getOrgDetailsByOrgId(state, { orgId: get(ownProps, 'match.params.id', null) })
});

export const AllFieldsComponent = connect(mapStateToProps, mapDispatchToProps)(AllFields);
