export const FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS';
export const FETCH_ORGANIZATIONS_ERROR = 'FETCH_ORGANIZATIONS_ERROR';
export const FETCH_ORGANIZATIONS_LOADING = 'FETCH_ORGANIZATIONS_LOADING';
export const TOGGLE_HEADER = 'TOGGLE_HEADER';
export const SET_ORGS_DETAILS = 'SET_ORGS_DETAILS';
export const CREATE_PROPERTY_SUCCESS = 'CREATE_PROPERTY_SUCCESS';
export const CREATE_PROPERTY_ERROR = 'CREATE_PROPERTY_ERROR';
export const PROPERTY_LOADING = 'PROPERTY_LOADING';
export const EDIT_PROPERTY_SUCCESS = 'EDIT_PROPERTY_SUCCESS';
export const EDIT_PROPERTY_ERROR = 'EDIT_PROPERTY_ERROR';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_ERROR = 'FETCH_USER_PROFILE_ERROR';
export const FETCH_USER_PROFILE_LOADING = 'FETCH_USER_PROFILE_LOADING';
