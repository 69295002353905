import styled from 'styled-components';
import { Col, Row, Layout } from 'antd/lib';
import get from 'lodash/get';

import { iff } from '../../utils/iff';

export const StyledHeader = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 20px;
  height: ${(props) => iff(props.collapsed, '55px', 'auto')};
  background-color: #ffffff;
`;

export const StyledImage = styled.img`
  height: ${(props) => props.height || '25px'};
  width: ${(props) => props.width || '25px'};
  /* padding: ${(props) => props.padding || '0px'}; */
  cursor: ${(props) => props.cursor || 'pointer'};
`;

export const StyledImageContainer = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  display: inline-table;
`;

export const StyledAnalyticsBtn = styled.button`
  background-color: #749204;
  height: 40px;
  border: 1px solid #749204;
  border-radius: 5px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  width: 100%;
  color: #ffffff;

  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

export const StyledRaiseLevel = styled(Col)`
  display: flex;
  flex-direction: column;
  padding: 32px 16px;

  border: 1px solid #e8eaed;
  border-radius: 4px;

  .raise-level-container {
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 0;
    padding-bottom: 7px;
  }

  .raise-level-description {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-shrink: 0;
  }

  .raise-level-progress {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    flex-grow: 0;

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;

    align-items: center;
    justify-content: space-between;

    .ant-progress-small {
      width: 156px;
    }
  }
`;

export const StyledSettings = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 11px;
  background: #f5f8fc;
  border-radius: 4px;

  .cols {
    margin-bottom: 6px;

    &.btn {
      font-weight: 500;
    }

    &.col-flex {
      max-width: 135px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      flex: 0 0 100%;
    }
  }
`;

export const StyledMenu = styled(Row)`
  height: -webkit-fill-available;

  &.content {
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 3px;
  }
`;

export const StyledMenuItem = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  height: 44px;
  padding: 6px;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  margin-right: 3px;
  width: 234px;

  .title_container {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    width: 70%;

    .title {
      font-family: ${(props) => get(props, 'theme.font.family')};
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #707374;
    }

    .subTitle {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: nowrap;
      font-family: ${(props) => get(props, 'theme.font.family')};
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #2f3031;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 84px;
      display: block;
    }
  }

  .seasons {
    width: 95%;

    .seasonBox {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;

      .subTitle {
        &.inactive {
          color: #8e9293;
        }
      }
    }

    .seperator {
      background-color: #c1c5c8;
    }
  }

  .count {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
    background-color: rgb(255, 255, 255);
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #8e9293;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledListContainer = styled(Col)`
  height: inherit;
  overflow: auto;
`;

export const StyledExpandedList = styled(Row)`
  height: 206px;
`;

export const StyledPropertyLabel = styled.p`
  margin: 6px 3%;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  color: #707374;
`;
export const StyledSeasons = styled(Row)`
  height: 90%;
  background-color: #f5f8fc;
  margin: 3% 1px;
  padding: 3%;

  font-family: Rubik;
  font-style: normal;

  .active-seasons-col {
    height: 40%;
    overflow: auto;
  }

  .inactive-seasons-col {
    height: 21%;
    overflow: auto;
  }

  .seasons-title {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    color: #707374;
  }

  .seasons-status {
    margin: 4px;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #2f3031;
  }

  .seasons-name {
    margin: 1px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: #2f3031;

    &.inactive {
      color: #707374;
      font-weight: normal;
    }
  }
`;

export const StyledExpandedHeader = styled(Layout.Header)`
  background-color: transparent;
  padding: 0px;
  height: 200px;
  overflow: hidden;

  .headerOrgList > .ant-spin-nested-loading {
    overflow-y: auto;
    overflow-x: hidden;
    height: 160px;
  }
  .headerOrgList > .ant-list-header {
    padding: 5px 0;
    border-bottom: 0;
  }

  .headerOrgList
    > .ant-spin-nested-loading
    > .ant-spin-container
    > ul
    > li
    > .ant-list-item-meta
    > .ant-list-item-meta-content
    > .ant-list-item-meta-title {
    margin-bottom: 0px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* line-height: 15px; */
    cursor: pointer;
    padding: 5px 0px;
  }

  .headerOrgList > .ant-spin-nested-loading > .ant-spin-container > ul > .ant-list-item {
    padding: 5px;
    border: 0px solid lightgray;
    border-radius: 8px;
    margin: 4px;
    background-color: #f0f3f7;
    overflow: hidden;
  }
  .headerOrgList
    > .ant-spin-nested-loading
    > .ant-spin-container
    > ul
    > li
    > .ant-list-item-meta
    > .ant-list-item-meta-avatar {
    margin-right: 7px;
  }
  .headerOrgList
    > .ant-spin-nested-loading
    > .ant-spin-container
    > ul
    > li
    > .ant-list-item-meta
    > .ant-list-item-meta-content
    > .ant-list-item-meta-description {
    font-size: 13px;
  }
  .headerOrgList
    > .ant-spin-nested-loading
    > .ant-spin-container
    > ul
    > li
    > .ant-list-item-meta
    > .ant-list-item-meta-content {
    width: 100px;
  }
  .propertiesList {
    width: 99%;
  }
  .propertiesList > .ant-spin-nested-loading {
    overflow-y: auto;
    overflow-x: hidden;
    height: 23vh;
    ::-webkit-scrollbar-thumb {
      background: ${(props) => get(props, 'theme.colors.scrollbarThumbBgColor')};
      -webkit-box-shadow: inset 0 3px 2px 6px
        ${(props) => get(props, 'theme.colors.scrollbarThumbBgColor')} !important;
      cursor: pointer;
    }
  }
  .propertiesList > .ant-list-header {
    padding: 5px 0;
    border-bottom: 0;
  }
  .propertiesList
    > .ant-spin-nested-loading
    > .ant-spin-container
    > ul
    > li
    > .ant-list-item-meta
    > .ant-list-item-meta-content
    > .ant-list-item-meta-title {
    margin-bottom: 0px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 13px;
    line-height: 15px;
    padding: 6px;
  }

  .propertiesList > .ant-spin-nested-loading > .ant-spin-container > ul > .ant-list-item {
    padding: 5px;
    border: 0px solid lightgray;
    border-radius: 8px;
    margin: 4px 4px;
    background-color: #f0f3f7;
  }
  .propertiesList
    > .ant-spin-nested-loading
    > .ant-spin-container
    > ul
    > li
    > .ant-list-item-meta
    > .ant-list-item-meta-content
    > .ant-list-item-meta-description {
    font-size: 13px;
  }

  .season-container {
    overflow: hidden;
    height: 26vh;
    background: #f5f8fc;
    margin-top: 5px;
  }
  .season-header {
    font-size: 12px;
    line-height: 14px;
    color: #707374;
    padding: 5px;
  }
  .seasonsList > .ant-list-header {
    padding: 5px 0;
    border-bottom: 0;
  }
  .seasonsList {
    .ant-divider .ant-divider-horizontal {
      margin: 18px 0 6px;
    }
  }

  .seasonsList
    > .ant-spin-nested-loading
    > .ant-spin-container
    > ul
    > li
    > .ant-list-item-meta
    > .ant-list-item-meta-content
    > .ant-list-item-meta-title {
    margin-bottom: 0px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 13px;
    line-height: 15px;
    padding: 6px;
  }

  .seasonsList > .ant-spin-nested-loading > .ant-spin-container > ul > .ant-list-item {
    padding: 5px;
    border: 0px solid lightgray;
    border-radius: 8px;
    margin: 4px 0;
    background-color: #f0f3f7;
  }
  .seasonsList
    > .ant-spin-nested-loading
    > .ant-spin-container
    > ul
    > li
    > .ant-list-item-meta
    > .ant-list-item-meta-content
    > .ant-list-item-meta-description {
    font-size: 13px;
  }

  .seasonsInActiveList > .ant-spin-nested-loading {
    overflow-y: scroll;
    height: 50px;
  }
  .seasonsInActiveList > .ant-list-header {
    padding: 5px 0;
    border-bottom: 0;
  }
  .seasonsInActiveList
    > .ant-spin-nested-loading
    > .ant-spin-container
    > ul
    > li
    > .ant-list-item-meta
    > .ant-list-item-meta-content
    > .ant-list-item-meta-title {
    margin-bottom: 0px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 13px;
    line-height: 15px;
    padding: 6px;
  }

  .seasonsInActiveList > .ant-spin-nested-loading > .ant-spin-container > ul > .ant-list-item {
    padding: 5px;
    border: 0px solid lightgray;
    border-radius: 8px;
    margin: 4px 0;
    background-color: #f0f3f7;
  }
  .seasonsInActiveList
    > .ant-spin-nested-loading
    > .ant-spin-container
    > ul
    > li
    > .ant-list-item-meta
    > .ant-list-item-meta-content
    > .ant-list-item-meta-description {
    font-size: 13px;
  }

  .analyticsListCard {
    /* width: 300px; */
    margin: 0 0 0 10px;
    height: 21.5vh;
    border-radius: 4px;
  }
  .analyticsListCard > .ant-card-body {
    padding: 10px;
  }
  .analyticsListCardMeta > .ant-card-meta-detail {
    display: contents;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
  .analyticsListCardMeta > .ant-card-meta-detail > .ant-card-meta-description {
    margin: 7% 0 3% 0;
  }
  .analyticsTitle {
    padding: 15px 40px;
    background-color: #749204;
    color: white;
    font-size: 14px;
    font-weight: 500;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .analyticsList > .ant-spin-nested-loading {
    /* overflow-y: scroll; */
    /* height: 160px; */
  }

  .analyticsList > .ant-list-header {
    padding: 5px 0px;
    border-bottom: 0;
  }

  .analyticsList > .ant-spin-nested-loading > .ant-spin-container > ul > .ant-list-item {
    /* padding: 5px 10px; */
    border: 0px solid lightgray;
    border-radius: 8px;
    /* margin: 4px 10px; */
    background-color: transparent;
  }
  .rightMenuUl {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-align: right;
  }
  .rightMenuUl li {
    /* float: left; */
    margin: 0 3px 0 5px;
    display: inline-flex;
  }
  .rightMenuUl li a {
    display: block;
    color: white;
    text-align: center;
    padding: 1vw;
    text-decoration: none;
  }
  .rightMenuLogout {
    padding: 0px 0px;
    /* background-color: #749204; */
    color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .rightMenuLinksBell {
    padding: 10px 20px;
    /* background-color: #749204; */
    color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .rightMenuLinksHelp {
    padding: 10px 20px;
    /* background-color: #749204; */
    color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .popupmenuList {
    background: transparent;
  }
  .popupmenuList > .ant-menu-vertical .ant-menu-item {
    padding: 0 10px;
  }
  .rightMenuPopover {
    display: inline-block;
    cursor: pointer;
    padding: 8px 0 8px 8px;
    /* background-color: #749204; */
    color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .rightMenuPopoverbtn {
    /* height: 5vh; */
    /* padding: 0px 10px; */
    /* background-color: #749204; */
    color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    /* display: block;
    text-align: center;
    padding: 0.5vw 1vw;
    text-decoration: none; */
  }

  .rightMenuLogout > .ant-list-header {
    padding: 0px 0px;
    border-bottom: 0;
  }

  .rightMenuLogout > .ant-spin-nested-loading > .ant-spin-container > ul > .ant-list-item {
    /* padding: 5px 10px; */
    border: 0px solid lightgray;
    border-radius: 8px;
    /* margin: 4px 10px; */
    background-color: transparent;
  }

  /* Media Query for Tablets Ipads portrait mode */
  @media (min-width: 1024px) and (max-width: 1250px) {
    .rightMenuUl {
      margin: 0;
    }
    .rightMenuUl li {
      margin: 0 1px 0 0;
    }
  }
`;


export const StyledInitialName = styled.span`
  height: 40px;
  width: 40px;
  border: 1px solid;
  border-radius: 40px;
  text-align: center;
  line-height: 40px;
  color: #707374;
  display: block;
`;

export const StyledColHeader = styled(Col)`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
`;
