import {
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATIONS_ERROR,
  FETCH_ORGANIZATIONS_LOADING
} from '../actions/types';

const initialState = {
  loading: false,
  data: [],
  error: null,
  selectedOrg: {},
  selectedProperty: {},
  selectedPropFields: [],
  org: {},
  loadingOrg: false,
  orgError: null,
  orgById: {}
};

export const orgs = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORGANIZATIONS_SUCCESS:
      const { payload } = action;
      const selectedOrg = payload?.[0] || {};
      const selectedProperty = selectedOrg?.properties?.[0];

      return {
        ...state,
        data: payload,
        selectedOrg,
        selectedProperty,
        loading: false
      };
    case FETCH_ORGANIZATIONS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case FETCH_ORGANIZATIONS_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
