import React, { useState } from 'react';
import { Modal, Card, Row, Col, Typography } from 'antd/lib';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { iff } from '../../../utils/iff';
import '../../../components/settings/Component.css';
import Files from '../../../public/files.svg';
import NewArea from '../../../public/new-area.svg';
import NewAreaWhite from '../../../public/new-area-white.svg';
import FilesWhite from '../../../public/files-white.svg';
import { URL_CONSTANTS } from '../../../utils/history';
import { AmplitudeInstance } from '../../../utils/amplitude';
import { StyleCancelBtn } from '../../../utils/styles.theming';
import get from 'lodash/get';
import { SvgIcon } from '../IconComponent';
import { icon } from 'leaflet';
import { title } from 'process';

const StyledModal = styled(Modal)`
  width: 400px !important;
  .ant-modal-title {
    font-size: 20px;
    color: #f9c43d;
    font-family: ${(props) => get(props, 'theme.font.family')};
    font-style: normal;
  }

  .ant-modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    border-bottom: 3px solid #f74141;
    border-radius: 8px 4px 0 0;
  }

  .ant-modal-header {
    border-bottom: 2px solid #faad14db;
  }
`;
const StyledCard = styled(Card)`
  margin-right: 10px;
  border-radius: 10px;
  height: 120px;
  text-align: center;
  font-size: 12px;
  font-family: ${(props) => get(props, 'theme.font.family')};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: #faad14db;
    .ant-card-bordered {
      border: 1px solid #faad14db;
    }
  }

  &:active {
    background-color: #f9c43d;
    color: #fff;
  }
  &:focus {
    background-color: #f9c43d;
  }

  .ant-card-body {
    padding: 25% 0%;
  }
`;

export const StyledImage = styled.img`
  height: 32px;
  width: 32px;
  padding: 0px;
  cursor: pointer;
  margin-right: 13px;
  margin-bottom: 10px;

  &:active {
    background-color: #f9c43d;
  }
`;
const HoverableCards = ({ onClick, icon, title }) => {
  const [hover, setHover] = useState(false);
  return (
    <StyledCard
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <div style={{ marginBottom: '10px' }}>
        <SvgIcon name={icon} color={iff(hover, '#faad14db', '#009933')} size={25} />
      </div>
      <Typography.Paragraph
        style={{ color: iff(hover, '#faad14db', '#000000a6') }}
        ellipsis={{ rows: 2, expandable: false }}>
        {title}
      </Typography.Paragraph>
    </StyledCard>
  );
};

export const CreateBoundaryModal = (props) => {
  const { t } = useTranslation();
  const [changeBgColor, setChangeBgColor] = useState(false);

  const onDrawFieldClick = () => {
    setChangeBgColor(true);
    const orgId = props.match.params.id;
    const farmId = props.match.params.farmId;
    if (props.isAddNewClicked) {
      AmplitudeInstance.onAddNewFieldUsingBoundary();
      return props.history.push(URL_CONSTANTS.CREATE_FIELD_DRAW({ orgId, farmId }), {
        addNewFieldFrom: props.addNewFieldFrom
      });
    }
    props.history.push(URL_CONSTANTS.EDIT_FIELD_DRAW({ orgId, farmId, fieldId: props.fieldId }));
  };

  const uploadCreateClick = () => {
    setChangeBgColor(true);
    const orgId = props.match.params.id;
    const farmId = props.match.params.farmId;
    AmplitudeInstance.onAddNewFieldUsingShapeFile();
    props.history.push(URL_CONSTANTS.CREATE_FIELD_SHAPE_FILE_UPLOADER({ orgId, farmId }), {
      addNewFieldFrom: props.addNewFieldFrom
    });
  };

  const onDetectFieldsClick = () => {
    const orgId = props.match.params.id;
    const farmId = props.match.params.farmId;
    return props.history.push(URL_CONSTANTS.DETECT_FIELDS({ orgId, farmId }), {
      ...props.routerState,
      addNewFieldFrom: props.addNewFieldFrom
    });
  };

  return (
    <div>
      <StyledModal
        title={t('Create new field')}
        style={{ top: '30%', color: '#F9C43D' }}
        visible={true}
        onCancel={props.onCancel}
        footer={[
          <StyleCancelBtn
            style={{ color: 'black', right: '3%' }}
            key='cancel'
            onClick={props.onCancel}
            data-testid="create-new-fields-options-cancel-btn">
            {t('Cancel')}
          </StyleCancelBtn>
        ]}>
        <Row data-testid="create-new-fields-options-modal" id="create-new-fields-options-modal">
          <Col span={12}>
            <StyledCard onClick={() => onDrawFieldClick()} data-testid="create-new-fields-draw-option">
              <div>
                <StyledImage
                  width='20px'
                  height='20px'
                  src={iff(changeBgColor === true, NewAreaWhite, NewArea)}
                />
              </div>
              {t('Draw your fields')}
            </StyledCard>
          </Col>
          <Col span={12}>
            <StyledCard onClick={() => uploadCreateClick()} data-testid="create-new-fields-upload-option">
              <div>
                <StyledImage
                  width='20px'
                  height='20px'
                  src={iff(changeBgColor === true, FilesWhite, Files)}
                />
              </div>
              {t('Upload file')}
            </StyledCard>
          </Col>
          <Col style={{ marginTop: '5px' }} span={12} data-testid="create-new-fields-select-option">
            {iff(
              props.isAddNewClicked === true,
              <HoverableCards
                onClick={() => onDetectFieldsClick()}
                icon='map-location'
                title={t('Select fields on the map')}
              />
            )}
          </Col>
        </Row>
      </StyledModal>
    </div>
  );
};

HoverableCards.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
  title: PropTypes.string,
};

CreateBoundaryModal.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      farmId: PropTypes.string.isRequired
    })
  }),
  history: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  isAddNewClicked: PropTypes.bool,
  fieldId: PropTypes.string,
  addNewFieldFrom: PropTypes.string,
  routerState: PropTypes.object
};
