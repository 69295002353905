import React from 'react';
import { Row, Col } from 'antd/lib';
import PropTypes from 'prop-types';
import { LoginCreateAccountLink } from './LoginCreateAccountLink';
import { LanguageDropdown } from '../../localization/LanguageDropdown';

export const RenderGoogleButtonAndLoginLink = (props) => {
  return (
    <Row className='language-dropdown' data-testid="sign-up-link-div">
      <Col span={18}>
        <LoginCreateAccountLink isLogin={props.isLogin} />
      </Col>
      <Col span={6}>
        <LanguageDropdown type='DROPDOWN' />
      </Col>
    </Row>
  );
};

RenderGoogleButtonAndLoginLink.propTypes = {
  isLogin: PropTypes.bool
};
