import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { CardWrapper } from '../common/Cards/CardWrapper';
import EmptyProppertyImg from '../../public/empty_property_gray.svg';
import { fetchOrgs } from '../../actions/index';
import { ConfirmationModal } from '../common/Modal/ConfirmationModal';
import { ErrorModal } from '../common/Modal/ErrorModal';
import { PageLayout } from '../Layout/page-layout';
import { getOrgById, isLoading } from '../../selectors/organizationSelectors';
import { URL_CONSTANTS } from '../../utils/history';
import { iff } from '../../utils/iff';
import { useTranslation } from 'react-i18next';
import { request } from '../../utils/axios';
import { Loader } from '../Loader/Loader';
import { getHeaders } from '../../utils/common-methods';

const LandingOrganizationContainer = props => {
  const history = useHistory();
  const org = props.org || {};
  const properties = org.properties || [];
  const [showDeleteModel, setShowDeleteModel] = React.useState(false);
  const [selectedProperty, setSelectedProperty] = React.useState({});
  const [loader, setLoader] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    props.fetchOrgs();
  }, []);

  const navigateToViewFarm = id => {
    history.push(`${history.location.pathname}/farm/${id}`);
  };
  const navigateToCreateFarm = () => {
    history.push(`${history.location.pathname}/create-farm`);
  };
  const navigateToEditFarm = id => {
    history.push(`${history.location.pathname}/edit-farm/${id}`);
  };
  const onDelete = (eve, property) => {
    eve.stopPropagation();
    setShowDeleteModel(true);
    setSelectedProperty({ ...selectedProperty, ...property });
  };

  const deletePropertyById = () => {
    setLoader(true);
    request
      .delete(`/v2/properties/${selectedProperty.id}`, {
        headers: getHeaders(),
      })
      .then(res => {
        if (res && res.status === 200) {
          setLoader(false);
          setShowDeleteModel(false);
          props.fetchOrgs();
        }
      })
      .catch(error => {
        setShowDeleteModel(false);
        setLoader(false);
        if (error?.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage('Something went wrong');
        }
        console.log(error);
      });
  };

  return PageLayout({
    content: (
      <div className="mainLayout">
        {iff(loader, <Loader />)}
        <CardWrapper
          backgroundImageUrl={EmptyProppertyImg}
          label="Properties"
          data={properties}
          isLoading={props.loadingOrgById}
          handleNavigation={navigateToViewFarm}
          onButtonClick={navigateToCreateFarm}
          handleEdit={navigateToEditFarm}
          unit={org.unit_system}
          numberOfProperties={properties?.length}
          onDelete={onDelete}
        />
        {iff(
          showDeleteModel,
          <ConfirmationModal
            visible={showDeleteModel}
            deleteProperty={true}
            onCancel={() => setShowDeleteModel(false)}
            onDelete={deletePropertyById}
            name={selectedProperty.name}
            title={t('Delete Property')}
          />
        )}
        {iff(
          errorMessage,
          <ErrorModal
            visible={errorMessage}
            message={t(errorMessage)}
            okText={t('Ok')}
            onOk={() => {
              setErrorMessage(null);
            }}
          />
        )}
      </div>
    ),
  });
};

LandingOrganizationContainer.propTypes = {
  org: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const id = get(ownProps, 'match.params.id', null);
  if (id === 'undefined') {
    ownProps.history.push(URL_CONSTANTS.HALL_OF_ORG());
  }

  return {
    org: getOrgById(state, { id }),
    loadingOrgById: isLoading(state),
  };
};
const mapDispatchToProps = dispatch => ({
  fetchOrgs: () => dispatch(fetchOrgs()),
});

export const LandingOrganizationContainerComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingOrganizationContainer);
