import styled from 'styled-components';
import { Input } from 'antd/lib';
import get from 'lodash/get';
import { iff } from '../../utils/iff';

const errorCss = `&:hover {
  border:1px solid #ff4d4f;
};
&:focus {
border-color: #ff4d4f;
border-right-width: 1px!important;
outline: 0;
box-shadow: 0 0 0 2px rgba(245,34,45,.2);
};
border: 1px solid #ff4d4f !important`;

export const InputPassword = styled(Input.Password)`
  input {
    background-color: ${(props) => get(props, 'theme.colors.inputBackground')};
    border: ${(props) => `1px solid ${get(props, 'theme.colors.inputBorder ')}`};
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: ${(props) => get(props, 'theme.fontSizes.medium')};
    line-height: 17px;
    margin: 5px 0 5px;
    height: 40px;
    color: ${(props) => get(props, 'theme.colors.buttonHoverColor')};
    ${(props) => iff(props.error, errorCss)}
    &:hover {
      color: ${(props) => get(props, 'theme.colors.buttonHoverColor')};
      border: ${(props) => get(props, 'theme.colors.inputFocusBorder')} !important;
    }
  }
  .ant-input-suffix i {
    color: ${(props) => get(props, 'theme.colors.primary')};
  }
`;
