import { Input, Button, Spin, Drawer, Select, Checkbox, DatePicker, Alert } from 'antd/lib';
import styled from 'styled-components';
import get from 'lodash/get';
import ReactDatePicker from 'react-datepicker';
const { RangePicker } = DatePicker;

export const StyledButton = styled(Button)`
  font-family: ${props => get(props, 'theme.font.family')};
  background: ${props => get(props, 'theme.colors.buttonBackground')};
  color: ${props => get(props, 'theme.colors.butonColor')};
  height: 40px;
  width: 42%;
  overflow: hidden;
  text-overflow: ellipsis;
  border: none;
  &:hover {
    background: ${props => get(props, 'theme.colors.buttonHoverBgColor')};
    color: ${props => get(props, 'theme.colors.buttonHoverColor')};
    border: ${props => get(props, 'theme.colors.buttonHoverBorderColor')};
  }
  &:active {
    color: ${props => get(props, 'theme.colors.buttonBackground')};
  }
  &:focus {
    color: ${props => get(props, 'theme.colors.buttonHoverColor')};
  }
`;

export const Label = styled.label`
  font-family: ${props => get(props, 'theme.font.family')};
`;
export const StyledSpan = styled.span`
  font-family: ${props => get(props, 'theme.font.family')};
`;
export const StyledP = styled.p`
  font-family: ${props => get(props, 'theme.font.family')};
`;
export const StyledBoldP = styled.p`
  font-family: ${props => get(props, 'theme.font.family')};
  font-weight: 600;
`;
export const StyledDrawer = styled(Drawer)`
  .ant-drawer-title {
    font-family: ${props => get(props, 'theme.font.family')};
  }
  .ant-drawer-close {
    color: ${props => get(props, 'theme.colors.backArrowBgColor')};
    &:hover {
      color: rgba(0, 0, 0, 0.75);
    }
  }
  .ant-upload-list-item:hover .ant-upload-list-item-info{
    background-color: ${props => get(props, 'theme.colors.scrollbarThumbBgColor')};
  }
  .ant-drawer-header {
    background: #f5f8fc;
  }
  .filled_upCircle{
    &:hover {
      color: ${props => get(props, 'theme.colors.primary')};
    }
  }
  .filled_downCircle{
    &:hover {
      color: ${props => get(props, 'theme.colors.primary')};
    }
  }
  .activeImage {
    border: 1px solid ${props => get(props, 'theme.colors.buttonHoverBorderColor')} !important;
    border-radius: 4px !important;
  }
`;
export const StyledInput = styled(Input)`
  font-family: ${props => get(props, 'theme.font.family')};
  color: ${props => get(props, 'theme.colors.primary')} !important;
  &:focus {
    color: ${props => get(props, 'theme.colors.primary')};
    border: ${props => get(props, 'theme.colors.inputFocusBorder')};
  }
`;
export const StyledError = styled.p`
  font-family: ${props => get(props, 'theme.font.family')};
`;
export const StyleCancelBtn = styled(Button)`
  font-family: ${props => get(props, 'theme.font.family')};
  &:hover {
    color: ${props => get(props, 'theme.colors.buttonHoverColor')};
    border-color: ${props => get(props, 'theme.colors.buttonHoverBorderColor')} !important;
  }
  &:active {
    color: ${props => get(props, 'theme.colors.buttonBackground')};
    border-color: ${props => get(props, 'theme.colors.buttonHoverBorderColor')};
  }
  &:focus {
    color: ${props => get(props, 'theme.colors.buttonHoverColor')};
    border-color: ${props => get(props, 'theme.colors.buttonHoverBorderColor')};
  }
`;
export const ButtonFontFamily = styled(Button)`
  font-family: ${props => get(props, 'theme.font.family')};
`;

export const StyledDiv = styled.div`
  ::-webkit-scrollbar-thumb {
    background: ${props => get(props, 'theme.colors.scrollbarThumbBgColor')};
    -webkit-box-shadow: inset 0 3px 2px 6px
      ${props => get(props, 'theme.colors.scrollbarThumbBgColor')} !important;
    cursor: pointer;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: ${props => get(props, 'theme.colors.buttonHoverBorderColor')};
  }
  .ant-radio-inner::after {
    background-color: ${props => get(props, 'theme.colors.scrollbarThumbBgColor')};
  }
  .active_shape_file{
    border          : 1px solid ${props => get(props, 'theme.colors.primary')};
    background-color: ${props => get(props, 'theme.colors.primary')};
  }
`;

export const StyledSpin = styled(Spin)`
  color: ${props => get(props, 'theme.colors.sideBarSpinColor')} !important;
`;
export const StyleRadioButton = styled(Input)`
  font-family: ${props => get(props, 'theme.font.family')};
  ::after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #fff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid ${props => get(props, 'theme.colors.radioButtonBorderColor')};
  }
  :checked::after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: ${props => get(props, 'theme.colors.radioButtonCheckedBgColor')};
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid ${props => get(props, 'theme.colors.radioButtonBorderColor')};
  }
`;

export const StyledDatePickerDiv = styled.div`
  font-family: ${props => get(props, 'theme.font.family')} !important;
  .react-datepicker {
    font-family: ${props => get(props, 'theme.font.family')} !important;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected {
    background: ${props => get(props, 'theme.colors.radioButtonCheckedBgColor')} !important;
    color: ${props => get(props, 'theme.colors.datepikerSelectedDateColor')} !important;
    &:hover {
      background: ${props => get(props, 'theme.colors.datpickerSelectedDateHoverBgColor')} !important;
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range {
    border-radius: 0.3rem;
    background-color: ${props => get(props, 'theme.colors.radioButtonCheckedBgColor')} !important;
    color: ${props => get(props, 'theme.colors.datepikerSelectedDateColor')} !important;
    &:hover {
      background: ${props => get(props, 'theme.colors.datpickerSelectedDateHoverBgColor')} !important;
    }
  }
  .react-datepicker__navigation--next,
  .react-datepicker__navigation--previous {
    outline-color: ${props => get(props, 'theme.colors.datepikerNavigationButtonOutlineColor')} !important;
  }
`;
export const StyledDatePicker = styled(ReactDatePicker)`
  .react-datepicker {
    font-family: ${props => get(props, 'theme.font.family')};
  }
`;
export const StyledFilterPopupButton = styled(Button)`
  font-family: ${props => get(props, 'theme.font.family')};
  background: ${props => get(props, 'theme.colors.buttonBackground')};
  color: ${props => get(props, 'theme.colors.butonColor')};
  right: '0%';
  border: none;
  &:hover {
    background: ${props => get(props, 'theme.colors.buttonHoverBgColor')};
    color: ${props => get(props, 'theme.colors.buttonHoverColor')};
    border: ${props => get(props, 'theme.colors.buttonHoverBorderColor')};
  }
  &:active {
    background: ${props => get(props, 'theme.colors.buttonHoverBgColor')};
    color: ${props => get(props, 'theme.colors.buttonHoverColor')};
  }
  &:focus {
    background: ${props => get(props, 'theme.colors.buttonHoverBgColor')};
    color: ${props => get(props, 'theme.colors.buttonHoverColor')};
  }
`;
export const StyledFilterInput = styled(Input)`
  font-family: ${props => get(props, 'theme.font.family')};
  color: black;
  &:focus {
    color: black;
    border: ${props => get(props, 'theme.colors.inputFocusBorder')};
  }
`;
export const StyledFilterCheckbox = styled(Checkbox)`
  font-family: ${props => get(props, 'theme.font.family')};
  .ant-checkbox-checked .ant-checkbox-inner{
    border-color: ${props => get(props, 'theme.colors.buttonHoverBgColor')};
    background-color: ${props => get(props, 'theme.colors.selectBoxshadowColor')};
  }
`;

export const StyledSelect = styled(Select)`
  box-shadow: none;
  font-family: ${props => get(props, 'theme.font.family')};
  color: ${props => get(props, 'theme.colors.buttonHoverColor')} !important;
  .ant-select-selection {
    &:hover {
      border-color: ${props => get(props, 'theme.colors.buttonHoverBgColor')};
    }
    &:active {
      border-color: ${props => get(props, 'theme.colors.buttonHoverBgColor')};
      box-shadow: 0 0 0 2px ${props => get(props, 'theme.colors.selectBoxshadowColor')};
    }
    &:focus {
      border-color: ${props => get(props, 'theme.colors.buttonHoverBgColor')};
      box-shadow: 0 0 0 2px ${props => get(props, 'theme.colors.selectBoxshadowColor')};
    }
  }
  .ant-select-dropdown-menu-item-active {
    background-color: ${props => get(props, 'theme.colors.selectBoxshadowColor')};
    &:hover {
      background-color: ${props => get(props, 'theme.colors.selectBoxshadowColor')} !important;
    }
  }
`;

export const StyledSaveButton = styled(Button)`
  font-family: ${props => get(props, 'theme.font.family')};
  color: #fff;
  background-color: ${props => get(props, 'theme.colors.saveAndAddFildBgColor')};
  border: 1px solid ${props => get(props, 'theme.colors.saveAndAddFildBgColor')};
  &:hover {
    background-color: ${props => get(props, 'theme.colors.saveAndAddFildBgHoverColor')};
    border: 1px solid ${props => get(props, 'theme.colors.saveAndAddFildBgHoverColor')};
    color: #fff;
  }
  &:active {
    border-color: ${props => get(props, 'theme.colors.buttonHoverBgColor')};
    color: #fff;
  }
  &:focus {
    border-color: ${props => get(props, 'theme.colors.buttonHoverBgColor')};
    color: #fff;
  }
`;
export const StyledDiscardButton = styled(Button)`
  font-family: ${props => get(props, 'theme.font.family')};
  &:active {
    border-color: ${props => get(props, 'theme.colors.buttonHoverBgColor')};
    color: ${props => get(props, 'theme.colors.buttonHoverColor')};
  }
  &:focus {
    border-color: ${props => get(props, 'theme.colors.buttonHoverBgColor')};
    color: ${props => get(props, 'theme.colors.buttonHoverColor')};
  }
`;
export const StyleListItem = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  .activeImage {
    border: 1px solid ${props => get(props, 'theme.colors.buttonHoverBorderColor')} !important;
    border-radius: 4px;
  }

  &:hover .imgBorder {
    border: 1px solid ${props => get(props, 'theme.colors.buttonHoverBorderColor')} !important;
    border-radius: 4px;
  }
`;
export const StyledImageListDropwodn = styled.span`
  float: right;
  margin-top: -10px;
  background: #f2f4f6;
  padding: 2px 10px 2px 10px;
  border-radius: 5px;
  cursor: pointer;
  .anticon{
    font-size:12px;
    margin-right 5px;
  } 
`;

export const StyledDateRangePicker = styled(RangePicker)`
  font-family: ${(props) => get(props, 'theme.font.family')};
  .ant-input {
    height: 39px;
    font-family: ${(props) => get(props, 'theme.font.family')};
    color: ${(props) => get(props, 'theme.colors.datepikerSelectedDateColor')} !important;
  }
  .ant-calendar-range-picker-separator {
    vertical-align: baseline;
  }
  &:focus {
    border-color:${props => get(props, 'theme.colors.buttonHoverBorderColor')}
  }
`;
export const StyledImg = styled.img`
  cursor: pointer;
  height: 42px;
  width : 50px;
`;
export const StyledRadioButtonPopup = styled.div`
::-webkit-scrollbar-thumb {
  background: ${(props) => get(props, 'theme.colors.scrollbarThumbBgColor')};
  -webkit-box-shadow: inset 0 3px 2px 6px
    ${(props) => get(props, 'theme.colors.scrollbarThumbBgColor')} !important;
  cursor: pointer;
}
.ant-radio-wrapper{
  width:100%;
  display: initial;
}
span.ant-radio {
  margin: 0px 2px;
}
.ant-radio-checked .ant-radio-inner{
  border-color: ${(props) => get(props, 'theme.colors.primary')};
}
.ant-radio-inner::after{
  background-color: ${(props) => get(props, 'theme.colors.primary')};
}
.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  border-color: ${(props) => get(props, 'theme.colors.primary')};
}
`;

export const StyledSwitchSection = styled.span`
  font-family: ${(props) => get(props, 'theme.font.family')};
  .ant-switch-checked {
    background-color: ${(props) => get(props, 'theme.colors.primary')} !important;
  }
  .ant-switch {
    right: 20px;
    position: absolute;
  }
`;

export const StyledElevationDiv = styled.div`
.ant-checkbox:hover::after, .ant-checkbox-wrapper:hover .ant-checkbox::after{
  visibility: hidden;
}
.ant-checkbox:hover .ant-checkbox-inner{
  border-color:${(props) => get(props, 'theme.colors.primary')};
}
`
export const StyledAlert = styled(Alert)`
background-color: ${(props) => get(props, 'theme.colors.alertBackground')};
border: 1px solid ${(props) => get(props, 'theme.colors.alertBackground')};
span{
  color:${(props) => get(props, 'theme.colors.alertColor')};
}
`
