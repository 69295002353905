import styled from 'styled-components';
import React, { useState } from 'react';

import get from 'lodash/get';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Layout } from '../Layout/Layout';
import { Input } from '../common/Input';
import { Label } from '../common/Label';
import { WarningBallon, WarningBallonImage } from '../common/warningBallon';
import LampImg from '../../public/lamp.png';
import { ErrorModal } from '../common/Modal/ErrorModal';
import { getErrorMessageByCode } from '../../utils/getErrorMessageByCode';

import { request } from '../../utils/axios';
import { history, URL_CONSTANTS } from '../../utils/history';

import { ErrorMessage } from '../common/ErrorMessage';
import './RecoveryPassword.css';
import { iff } from '../../utils/iff';
import { isEmptyStr } from '../../utils/common-methods';
import { AppConstants } from '../../utils/app.constants';
import { Button } from '../common/Button';
import { LoginCreateAccountLink } from '../common/LoginCreateAccountLink';

const SAVE_DISABLE_TIMING = 10000;

export const StyledButton = styled(Button)`
  border-radius: 4px;
  width: 115px;
  /* width: 147px;
  height: 40px; */
  /* margin: 10px 0; */
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => get(props, 'theme.fontSizes.medium')};
  /* line-height: 17px; */
  margin: 0 0 0 2em;
`;
const StyledLabel = styled(Label)`
  text-align: left;
  margin-top: 3em;
`;
const RPPButtonSection = styled.div`
  button:first-child {
    /* float: left; */
  }
  button:last-child {
    /* float: right; */
  }
`;

export const RecoveryPassword = (props) => {
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [saveDisable, setSaveDisable] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const { t } = useTranslation();

  return (
    <Layout type='central'>
      <div className='RecoveryPasswordPageSection'>
        <h4 className='RPPTitle' data-testid="recover-pwd-page-title">{t('Recover your password')}</h4>
        <label className='RPPText' data-testid="recover-pwd-email-title">{t('Enter your email below.')}</label>
        <label className='RPPText' data-testid="recover-pwd-note">{t('We will send you a link to reset your password.')}</label>
        <Formik
          initialValues={{
            email: get(props, 'location.state.email')
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .max(
                AppConstants.EMAIL_MAX_LENGTH,
                t('You have exceeded maximum number of characters allowed.')
              )
              .email(t('Please enter valid email address'))
              .required(t('Please Enter Email'))
          })}
          onSubmit={(values, formikBag) => {
            setSaveDisable(true);
            let userDetails = {
              email: values.email,
              locale: 'en'
            };
            request
              .post(`/v2/accounts/recovery`, userDetails)
              .then(() => {
                setShowSuccessMsg(true);
                formikBag.setSubmitting(false);
                setTimeout(() => {
                  setSaveDisable(false);
                }, SAVE_DISABLE_TIMING);
              })
              .catch((error) => {
                setErrorCode(get(error, 'response.data.code'));
                setErrorMessage(getErrorMessageByCode(get(error, 'response.data.code')));
                formikBag.setSubmitting(false);
                setSaveDisable(false);
              });
          }}>
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <ErrorModal
                visible={!!errorMessage}
                message={errorMessage}
                okText={iff(errorCode === 'EACC012', t('SignUp'), t('Ok'))}
                cancelText={t('Cancel')}
                onOk={() => {
                  setErrorMessage('');
                  setErrorCode('');
                  if (errorCode === 'EACC012') {
                    history.push(URL_CONSTANTS.SIGNUP_1());
                  }
                }}
                onCancelClick={() => {
                  setErrorMessage('');
                  setErrorCode('');
                }}
              />
              <div className='RPPEmailField' style={{ margin: ' 5px 0 2em' }}>
                <StyledLabel data-testid='label' className='RPPEmailLabel'>
                  {t('Email')}
                </StyledLabel>
                <Input
                  style={{ margin: ' 5px 0 0em' }}
                  className='RPPEmail'
                  id='email'
                  name='email'
                  type='primary'
                  value={formik.values.email.replace(/ /g, '')}
                  onChange={formik.handleChange}
                  onKeyDown={() => formik.setFieldTouched('email', true)}
                  onKeyPress={(e) => e.charCode === 32 && e.preventDefault()}
                  placeholder={t('Email')}
                  autoFocus
                  error={formik.touched.email && isEmptyStr(formik.errors.email)}
                  data-testid="recover-pwd-email-input"
                />
                {iff(
                  formik.touched.email && isEmptyStr(formik.errors.email),
                  <ErrorMessage>{formik.errors.email}</ErrorMessage>
                )}
              </div>

              <RPPButtonSection>
                <StyledButton
                  className='btn-green'
                  type='btnGreen'
                  htmlType='submit'
                  disabled={
                    (formik.touched.email && isEmptyStr(formik.errors.email)) || saveDisable
                  }
                  loading={formik.isSubmitting}
                  data-testid="recover-pwd-send-btn">
                  {t('Send')}
                </StyledButton>
                <StyledButton
                  type='secondary'
                  data-testid='recover-pwd-cancel-btn'
                  onClick={() => {
                    history.push(URL_CONSTANTS.ROOT());
                  }}>
                  {t('Cancel')}
                </StyledButton>
              </RPPButtonSection>
            </form>
          )}
        </Formik>
      </div>
      {iff(
        showSuccessMsg,
        <WarningBallon>
          <div className='box' data-testid='recover-pwd-success-message'>
            <WarningBallonImage src={LampImg} />
            <label>{t('A message has been sent to your email. Please check your inbox.')}</label>
          </div>
        </WarningBallon>
      )}
      <div className='loginCreateAccountLinkDiv'>
        <LoginCreateAccountLink isLogin={false} />
      </div>
    </Layout>
  );
};

RecoveryPassword.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      email: PropTypes.string.isRequired
    })
  })
};
