import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Label, StyledCount, StyledLabelCount, CreatePropertyButton } from './EmptyCard.styles';
import { EmptyCard } from './EmptyCard';
import { iff } from '../../../utils/iff';
import { PropertiesCardComponent } from './PropertiesCard';
import { CardContainer, StyledContainer, StyledPropertySection, StyledInputDiv, StyledButtonSection, StyledButton } from './CardWrappper.styles';
import { SkeletonCard } from '../../common/Cards/SkeletonCard';
import { getAreaByUnit } from '../../../utils/getAreaByUnit';
import { SearchOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Carousel, Input } from 'antd/lib';

export const CardWrapper = (props) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = React.useState(0)
  const [filteredPropertiesData, setFilteredPropertiesData] = React.useState([])
  const [searchedText, setSearchedText] = React.useState('')
  const carouselRef = React.useRef();
  const [slidesPerScreen, setSlidesPerScreen] = React.useState(6);

  React.useEffect(() => {
    setFilteredPropertiesData(props.data);
    if (window && window.screen && window.screen.width >= 1920) {
      setSlidesPerScreen(8);
    }
  }, [props.data.length])

  React.useEffect(() => {
    if (searchedText) {
      onSearch(null, searchedText)
    }
  }, [filteredPropertiesData.length])

  const onSearch = (eve, prevSearchedData) => {
    let searchedData = eve?.target ? eve.target.value.toLowerCase() : prevSearchedData
    setSearchedText(searchedData)
    let searchedResultsData = props.data?.filter(property => property.name.toLowerCase().includes(searchedData))
    setFilteredPropertiesData(searchedResultsData)
    if (eve?.target?.value === '') {
      setFilteredPropertiesData(props.data)
    }
  }

  const handleNext = () => carouselRef.current.next();
  const handlePrev = () => carouselRef.current.prev();

  const getActiveIndex = (index) => {
    setActiveIndex(index)
  }


  let carouselResponsiveSettings = {
    responsive: [
      {
        breakpoint: 2561,
        settings: {
          rows: 2,
          slidesPerRow: 4
        },
      },
      {
        breakpoint: 1920,
        settings: {
          rows: 2,
          slidesPerRow: 3
        },
      },
      {
        breakpoint: 1441,
        settings: {
          rows: 2,
          slidesPerRow: 3
        },
      },
      {
        breakpoint: 1024,
        settings: {
          rows: 2,
          slidesPerRow: 3
        },
      }
    ]
  };
  const renderCards = () => {
    const label = 'Property';
    return iff(
      props.data.length === 0,
      <EmptyCard
        backgroundImageUrl={props.backgroundImageUrl}
        onButtonClick={props.onButtonClick}
        label={props.label}
        numberOfProperties={props.numberOfProperties}
      />,
      <div>
        {iff(
          props.numberOfProperties > 0,
          <CreatePropertyButton onClick={props.onButtonClick} data-testid="create-propert-button">
            + {t(`Create ${label}`)}
          </CreatePropertyButton>
        )}
        <CardContainer>
          <Carousel
            dots={false}
            ref={carouselRef}
            draggable
            {...carouselResponsiveSettings}
            afterChange={getActiveIndex}
          >
            {Array.isArray(filteredPropertiesData) && filteredPropertiesData.map((property) =>
              <PropertiesCardComponent
                key={property.id}
                {...property}
                totalArea={getAreaByUnit(property.total_area, props.unit)}
                unit={props.unit}
                isLoading={props.isLoading}
                onCardClick={props.handleNavigation}
                editCardClick={props.handleEdit}
                onDelete={props.onDelete}
              />
            )}
          </Carousel>
          {filteredPropertiesData.length === 0 && <div style={{ textAlign: 'center' }}>No Data Found</div>}
        </CardContainer>
      </div>
    );
  };
  return (
    <StyledContainer>
      <StyledPropertySection>
        <StyledLabelCount>
          <Label>{t(props.label)}</Label>
          <StyledCount>({props.data.length})</StyledCount>
        </StyledLabelCount>
        <StyledInputDiv>
          <Input placeholder="Search properties" id="searchProperties" size="large" onChange={onSearch} prefix={<SearchOutlined style={{ color: '#707374' }} />} />
        </StyledInputDiv>
      </StyledPropertySection>
      {iff(
        props.isLoading === true,
        <SkeletonCard width={'310px'} height={'30vh'} />,
        renderCards()
      )}
      {iff(
        !props.isLoading && filteredPropertiesData.length > 0,
        <StyledButtonSection>
          <StyledButton disabled={activeIndex === 0} onClick={handlePrev}><ArrowLeftOutlined /></StyledButton>
          <StyledButton disabled={(activeIndex + 1) === (Math.ceil(filteredPropertiesData.length / slidesPerScreen))} onClick={handleNext}><ArrowRightOutlined /></StyledButton>
        </StyledButtonSection>
      )}
    </StyledContainer>
  );
};

CardWrapper.defaultProps = {
  count: 0,
  backgroundImageUrl: '',
  label: '',
  onButtonClick: () => null,
  isPropertyCreated: false,
  data: [],
  isLoading: false,
  handleNavigation: () => null,
  numberOfProperties: 0
};
CardWrapper.propTypes = {
  label: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  count: PropTypes.number,
  onButtonClick: PropTypes.func,
  isPropertyCreated: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  numberOfProperties: PropTypes.number,
  handleNavigation: PropTypes.func,
  handleEdit: PropTypes.func,
  unit: PropTypes.string,
  onDelete: PropTypes.func
};
