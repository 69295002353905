import isEmpty from 'lodash/isEmpty';

const turf = require('@turf/turf');

/**
 *
 * @param {*} propertyField - it is field object which has geometry object inside it and which is need to check with other fields
 * @param {*} propertyFields - list of field object which has geometry object inside it
 */

export const doesFieldOverlappingToOtherFields = (propertyField = {}, propertyFields = []) => {
  let isOverlapping = false;
  for (let field of propertyFields) {
    if (!isEmpty(field.geometry)) {
      if (turf.intersect(field.geometry, propertyField.geometry)) {
        isOverlapping = true;
        break;
      }
    }
  }
  return isOverlapping;
};
