import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { AnchorLink } from './AnchorLink';
import { iff } from '../../utils/iff';
import { history, URL_CONSTANTS } from '../../utils/history';
import { Label } from '../common/Label';

const Container = styled.div`
  font-weight: normal;
  font-size: 12px;
  text-align: right;
  padding: 0 1em;
  /* line-height: 167%; */
  /* top: 70vh;
  left: 0%;
  position: absolute;
  width: 100%;
   */
`;

export const LoginCreateAccountLink = (props) => {
  const { t } = useTranslation();
  return (
    <Container>
      <span data-testid="sign-up-link-note">{iff(props.isLogin, t('Already have account?'), t(`Don't have an account yet?`))}</span>
      <AnchorLink
        onClick={() => {
          if (props.isLogin) {
            history.push(URL_CONSTANTS.LOGIN());
          } else {
            history.push(URL_CONSTANTS.SIGNUP_1());
          }
        }}
        data-testid="sign-up-link">
        {iff(props.isLogin, t('Sign in'), t('Create your account'))}
      </AnchorLink>
      <Label data-testid="sign-up-link-note-2">{t('For Internal Users Only')} </Label>
    </Container>
  );
};

LoginCreateAccountLink.propTypes = {
  isLogin: PropTypes.bool.isRequired
};
