import timezones from './timezones.json';
import { iff } from '../../utils/iff';
import { isEmptyStr } from '../../utils/common-methods';

export const DEFAULT_LOCATION = {
  LAT: -3.1855786,
  LONG: -52.22320109,
  UTC: -4
};
export const DEFAULT_LATLONG = 10.0;
export const MAP_REFRESH_DELAY = 200;

export const apiParams = {
  zipCode: 'zip_code',
  timeZone: 'time_zone',
  orgId: 'org_id',
  referencePoint: 'reference_point',
  administrativeAreaLevel1: 'administrative_area_level_1',
  postalCode: 'postal_code',
  streetNumber: 'street_number',
  sublocalityLevel1: 'sublocality_level_1',
  sublocalityLevel2: 'sublocality_level_2'
};

export const defaultForm = {
  locality: 'long_name',
  [apiParams.administrativeAreaLevel1]: 'short_name',
  country: 'long_name',
  [apiParams.postalCode]: 'short_name',
  political: 'short_name'
};

export const streetAddressForm = {
  [apiParams.streetNumber]: 'short_name',
  premise: 'short_name',
  route: 'short_name',
  political: 'short_name',
  sublocality: 'short_name',
  [apiParams.sublocalityLevel1]: 'short_name',
  [apiParams.sublocalityLevel2]: 'short_name'
};

export const calculateOffset = (utcOffset) => {
  let offset;
  const DIVIDE_BY = 60;
  if (utcOffset === undefined) {
    offset = DEFAULT_LOCATION.UTC;
  } else {
    offset = utcOffset / DIVIDE_BY;
  }

  return timezones.filter((time) => {
    return time.offset === offset;
  });
};
/**
 * Get each component of the address from the place details and fill the corresponding field on the form.
 * @param {*} place - Google place address response
 */
export const transformAddress = (place) => {
  try {
    let finalAddress = {
      address: ''
    };
    let addressText = '';
    const addressComponents = place.address_components;
    for (let addressComponent of addressComponents) {
      for (let addressType of addressComponent.types) {
        let val;
        if (defaultForm[addressType]) {
          val = addressComponent[defaultForm[addressType]];
          finalAddress[addressType] = val;
        } else if (streetAddressForm[addressType]) {
          val = addressComponent[streetAddressForm[addressType]];
          addressText = `${iff(isEmptyStr(addressText), `${addressText}, `, '')}${val}`;
          finalAddress.address = addressText;
        }
      }
    }
    finalAddress.timeZone = calculateOffset(place.utc_offset_minutes);
    return finalAddress;
  } catch (ex) {
    console.error('Exception - transformAddress -', ex);
    return null;
  }
};
