import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import find from 'lodash/find';
import { drawingControls } from './DrawingControls';
import {
  geojsonCordinateToGooglemap,
  geojsonCordinatePtsToGooglemap
} from './geojsonCordinateToGooglemap';
import { doesFieldOverlappingToOtherFields } from '../../utils/doesFieldOverlappingToOtherFields';
import '../common/Modal/filterModel.css';
import { useLocation } from 'react-router';
import { getAllFields } from '../../services/cropioService';
import { notification } from 'antd/lib';

const params = {
  latLong: '_latlng',
  mRadius: '_mRadius',
  tooltip: '_tooltip',
  layers: '_layers'
};
let turf = require('@turf/turf');
let isSessionId;
let drawnItems = {};
let map = {};
let fieldsDrawingLayer = {};
let geoJsons = [];
let CERULEAM_COLOR;
const MERCURY_COLOR = '#E5E5E5';
const WHITE_COLOR = '#FFFFFF';
const TRANSPARENT_COLOR = 'transparent';
let circleDraw = {};
let polygonDraw = {};
let ndvirgbImageLayer = {};
let legend;
let isSubtractPolygon = false;
let isSubtractCircle = false;
let isFinishedLoading = false;
let detectedFields = [];
let selectedDetectedFields = [];
let selectedDetectedFieldsDrawingLayer = {};
let oldDrawnItems = {};
let countForDrawPolygonFunCall = 0;
let shapeFileColorPallet = [];
let polygn;
let backUpDrawnItems = [];
let drawnVertexLayers = {};

export const Maps = (props) => {
  useEffect(() => {
    isSessionId = localStorage.getItem('session_id');
    CERULEAM_COLOR = `${isSessionId ? '#FFD100' : '#00b5d8'}`;
  }, []);
  const location = useLocation();
  const { t } = useTranslation();
  const checkOverlapping = (layer) => {
    const isOverlapping = doesFieldOverlappingToOtherFields(layer.toGeoJSON(), geoJsons);
    if (isOverlapping) {
      layer.setStyle({
        fillColor: CERULEAM_COLOR,
        color: 'red'
      });
      const popupLayer = layer.getTooltip();

      if (!popupLayer) {
        layer.bindTooltip(t('Field is getting intersect with other field'), {
          className: 'intersectionFieldTooltipError',
          permanent: true
        });
        layer.openTooltip();
      }
    } else {
      layer.setStyle({
        fillColor: CERULEAM_COLOR,
        color: CERULEAM_COLOR
      });
      const popupLayer = layer.getTooltip();
      if (popupLayer) {
        layer.unbindTooltip();
      }
    }
  };

  const onPolygonEdit = (e, isSubtractedPolygonOutside) => {
    let layer;
    if (e.type === 'editable:editing' || e.type === 'dragend') {
      layer = e.layer;
    } else {
      layer = e.poly;
    }
    checkOverlapping(layer);
    if (isSubtractedPolygonOutside) {
      checkSubtractPolygonOverlapping(layer);
      let newLayer = layer;
      let newGeom = turf.getGeom(newLayer.toGeoJSON());
      let isPolygonContain = false;
      oldDrawnItems.eachLayer(function(layer) {
        let geojson = layer.toGeoJSON();
        if (geojson.type == 'FeatureCollection') {
          geojson = geojson.features[0];
        }
        let geom = turf.getGeom(geojson);
        isPolygonContain = true;
        newGeom = turf.difference(geom, newGeom);
        newLayer = L.geoJSON(newGeom);
        newLayer.setStyle({
          fillColor: CERULEAM_COLOR,
          color: CERULEAM_COLOR
        });
        drawnItems.removeLayer(layer);
      });
      if (isPolygonContain) {
        map.removeLayer(drawnItems);
        drawnItems = new L.FeatureGroup().addTo(map);
        oldDrawnItems = new L.FeatureGroup().addTo(map);
        drawnItems.addLayer(newLayer);
        oldDrawnItems.addLayer(newLayer);
        const newFeatures = newLayer.toGeoJSON()?.features?.[0];
        props.onDrawComplete(newFeatures?.geometry);
      }
    } else {
      const geo = layer.toGeoJSON();
      props.onDrawComplete(geo.geometry);
    }
  };

  const checkSubtractPolygonOverlapping = (layer) => {
    layer.setStyle({
      fillColor: CERULEAM_COLOR,
      color: 'red'
    });
    const popupLayer = layer.getTooltip();

    if (!popupLayer) {
      layer.bindTooltip(t('Drag into parent polygon for subtraction'), {
        className: 'intersectionFieldTooltipError',
        permanent: true
      });
      layer.openTooltip();
    }
  };

  const showErrorDetectBoundaries = () => {
    const args = {
      message: t('Error Detecting Boundaries'),
      description: t('Error in Detecting Boundaries'),
      duration: 5
    };
    notification.error(args);
  };
  const drawPolygon = (e, isSubtractedPolygonOutside = false) => {
    if (!isSubtractCircle && !isSubtractPolygon) {
      let geometry = {};
      if (e.layerType === 'polygon') {
        const geo = e.layer.toGeoJSON();
        geometry = geo.geometry;
      } else {
        const center = [e.layer[params.latLong].lng, e.layer[params.latLong].lat];
        const radius = e.layer[params.mRadius];
        const options = { steps: 20, units: 'meters' };
        const circleToPolygonGeometry = turf.circle(center, radius, options);
        geometry = circleToPolygonGeometry.geometry;
      }

      polygn = new L.polygon(geojsonCordinateToGooglemap(geometry.coordinates[0]), {
        fill: true,
        fillColor: CERULEAM_COLOR,
        color: CERULEAM_COLOR,
        fillOpacity: 0.3,
        draggable: true
      });
      backUpDrawnItems.push(polygn);
      drawnItems.addLayer(polygn);
      if (countForDrawPolygonFunCall === 0) {
        oldDrawnItems.addLayer(polygn);
      }
      polygn.enableEdit();
      checkOverlapping(polygn);
      polygn.on('dragend', onPolygonEdit);
      props.onDrawComplete(geometry);
      if (isSubtractedPolygonOutside) {
        checkSubtractPolygonOverlapping(polygn);
        polygn.on('dragend', (e) => onPolygonEdit(e, isSubtractedPolygonOutside));
      }
    } else {
      let newLayer = e.layer;
      let newGeom;
      if (e.layerType === 'polygon') {
        newGeom = turf.getGeom(newLayer.toGeoJSON());
      } else {
        const center = [e.layer[params.latLong].lng, e.layer[params.latLong].lat];
        const radius = e.layer[params.mRadius] ?? 0;
        const options = { steps: 20, units: 'meters' };
        const circleToPolygonGeometry = turf.circle(center, radius, options);
        newGeom = circleToPolygonGeometry.geometry;
      }
      let isPolygonContain = false;
      drawnItems.eachLayer(function(layer) {
        let geojson = layer.toGeoJSON();
        if (geojson.type == 'FeatureCollection') {
          geojson = geojson.features[0];
        }
        let geom = turf.getGeom(geojson);
        isPolygonContain = true;
        newGeom = turf.difference(geom, newGeom);
        newLayer = L.geoJSON(newGeom);
        newLayer.setStyle({
          fillColor: CERULEAM_COLOR,
          color: CERULEAM_COLOR
        });
        drawnItems.removeLayer(layer);
      });
      backUpDrawnItems.push(newLayer);
      if (isPolygonContain) {
        map.removeLayer(drawnItems);
        drawnItems = new L.FeatureGroup().addTo(map);
        oldDrawnItems = new L.FeatureGroup().addTo(map);
        const newFeatures = newLayer.toGeoJSON()?.features?.[0];
        props.onDrawComplete(newFeatures?.geometry);
        drawnItems.addLayer(newLayer);
        oldDrawnItems.addLayer(newLayer);
      } else {
        countForDrawPolygonFunCall = countForDrawPolygonFunCall + 1;
        isSubtractPolygon = false;
        isSubtractCircle = false;
        drawPolygon(e, true);
      }
    }
  };

  useEffect(() => {
    if (
      window.location.href.indexOf('all-fields') != -1 ||
      window.location.href.indexOf('create-fields') != -1
    ) {
      detectedFields = [];
      selectedDetectedFields = [];
    }
  }, [props.match]);

  useEffect(() => {
    selectedDetectedFields = props.detectedFields;

    addDetectSelectedFieldLayer();
  }, [props.detectedFields]);

  const addDetectSelectedFieldLayer = () => {
    if (!isEmpty(selectedDetectedFieldsDrawingLayer)) {
      map.removeLayer(selectedDetectedFieldsDrawingLayer);
      selectedDetectedFieldsDrawingLayer = {};
    }
    if (isEmpty(map)) {
      initMap();
    }

    if (!isEmpty(selectedDetectedFields)) {
      selectedDetectedFieldsDrawingLayer = new L.FeatureGroup().addTo(map);
      map.addLayer(selectedDetectedFieldsDrawingLayer);
      selectedDetectedFields.forEach((element) => {
        if (element.geometry) {
          let p = new L.polygon(geojsonCordinatePtsToGooglemap(element.geometry.coordinates[0]), {
            fill: true,
            fillColor: '#1040d8',
            color: '#1040d8',
            fillOpacity: 0.4,
            myFieldId: element.id
          });
          selectedDetectedFieldsDrawingLayer.addLayer(p);
          p.on('click', function(e) {
            const clickedField = detectedFields.find((x) => x.id === e.target.options.myFieldId);
            selectedDetectedFields.push(clickedField);
            props.handelDetectSelectedField(clickedField);
          });
          p.bindTooltip(`${element.properties.crop} ${element.properties.area_ha.toFixed(2)} ha`, {
            opacity: 1,
            myFieldId: element.id
          });
        }
      });
    }
  };

  const initDrawControls = () => {
    drawnItems = new L.FeatureGroup().addTo(map);
    oldDrawnItems = new L.FeatureGroup().addTo(map);
    const drawControl = new L.Control.Draw(drawingControls);
    map.addControl(drawControl);
    map.on('draw:created', drawPolygon);
    map.on('draw:editvertex', onPolygonEdit);
    map.on('editable:editing', onPolygonEdit);
    map.on('draw:drawvertex', (event) => {
      drawnVertexLayers = event.layers._layers;
      props.handleDrawStart(true);
    });
  };

  const colors = [
    '#FF0000',
    '#FF2300',
    '#FF4600',
    '#FF6900',
    '#FF8C00',
    '#FFAF00',
    '#FFD300',
    '#FFF600',
    '#E5FF00',
    '#C2FF00',
    '#9FFF00',
    '#7CFF00',
    '#58FF00',
    '#35FF00',
    '#00FF00'
  ];

  /**
   * It will return diffenent-different color for each zones
   */
  const getColor = (zone) => {
    switch (zone) {
      case 0:
        return '#FF0000';
      case 1:
        return '#FF2300';
      case 2:
        return '#FF4600';
      case 3:
        return '#FF6900';
      case 4:
        return '#FF8C00';
      case 5:
        return '#FFAF00';
      case 6:
        return '#FFD300';
      case 7:
        return '#FFF600';
      case 8:
        return '#E5FF00';
      case 9:
        return '#C2FF00';
      case 10:
        return '#9FFF00';
      case 11:
        return '#7CFF00';
      case 12:
        return '#58FF00';
      case 13:
        return '#35FF00';
      case 14:
        return '#00FF00';
      default:
        return '#00FF00';
    }
  };

  const createLegend = (colourPallet = {}) => {
    if (!isEmpty(legend)) {
      map.removeControl(legend);
    }

    legend = L.control({ position: 'bottomleft' });

    legend.onAdd = function(map) {
      let div = L.DomUtil.create('div', 'info legend legend_section');
      for (let i = 0; i <= 30; i++) {
        if (i <= 14) {
          div.innerHTML += '<i style="background:' + getColor(14 - i) + '"></i> ';
        }
        if (i > 15) {
          div.innerHTML += '<i style="background:#bcb8b8"></i> ';
        }
      }
      div.innerHTML += `<span class="first_lable" >${colourPallet.max ? colourPallet.max : 1}</span>
        <span class="middle_lable">${
          colourPallet.min ? colourPallet.min : 0
        }</span> <span class="last_lable" >-1 </span>`;
      return div;
    };
    legend.addTo(map);
  };

  /**
   * It will apply the style for each zones
   */
  const applyStyleToZones = (feature) => {
    if (feature) {
      return {
        fillColor: getColor(Math.round(props.colorMultiplier * feature.properties.zone)),
        weight: 2,
        opacity: 1,
        color: WHITE_COLOR,
        dashArray: '3',
        fillOpacity: 0.5
      };
    }
  };
  const applyStyleToAutomaticZones = (feature) => {
    if (feature) {
      return {
        fillColor: getColor(Math.round(props.colorMultiplier * (feature.properties.group - 1))),
        weight: 2,
        opacity: 1,
        color: WHITE_COLOR,
        dashArray: '3',
        fillOpacity: 0.5
      };
    }
  };

  const initMap = () => {
    setTimeout(() => {
      try {
        map = L.map(document.getElementById('mapId'), {
          center: [props.focusLat, props.focusLong],
          zoom: props.zoomLevel,
          maxZoom: props.maxZoomLevel,
          minZoom: props.minZoomLevel,
          editable: true,
          measureControl: true
        });
        L.gridLayer
          .googleMutant({
            type: 'hybrid'
          })
          .addTo(map);
        if (props.isDrawable) {
          initDrawControls();
        }
        map.on('click', (event) => props.onMapClicked(event.latlng));
        map.on('moveend', (event) => {
          if (props.onMoveEnd) {
            props.onMoveEnd({
              latLng: map.getCenter(),
              zoomLevel: map.getZoom()
            });
          }

          if (
            props.detectFields &&
            isFinishedLoading &&
            window.location.href.indexOf('detect-fields') != -1
          ) {
            props.getCurrentZoomLevel(map.getZoom());
            if (map.getZoom() >= 13) {
              getBoundaries();
            }
          } else {
            isFinishedLoading = true;
          }
        });
        if (props.zoom === false) {
          map.on('dblclick', () => map.doubleClickZoom.disable());
        }
      } catch (err) {
        return err;
      }
    }, 10);
  };

  useEffect(() => {
    if (props.detectFields && window.location.href.indexOf('detect-fields') != -1) {
      if (!isEmpty(map)) {
        props.getCurrentZoomLevel(map.getZoom());
        if (map.getZoom() >= 13) {
          getBoundaries();
        }
      }
    }
  }, [props.detectFields]);
  useEffect(() => {
    if (props.reload) {
      initMap();
    }
  }, [props.reload, props.clusterRes, props.productivityZonesAPIResponse]);

  useEffect(() => {
    if (!isEmpty(map) && props.isDrawPolygon.polygon) {
      if (polygonDraw.disable) {
        polygonDraw.disable();
      }
      polygonDraw = new L.Draw.Polygon(map);
      polygonDraw.enable();
      if (circleDraw.disable) {
        circleDraw.disable();
      }
    }
  }, [props.isDrawPolygon]);

  useEffect(() => {
    if (!isEmpty(map) && polygonDraw.deleteLastVertex && props.isDeleteLastVertex) {
      if (Object.keys(drawnVertexLayers).length === 1) {
        if (polygonDraw.disable) {
          polygonDraw.disable();
        }
        polygonDraw = new L.Draw.Polygon(map);
        polygonDraw.enable();
      } else {
        polygonDraw.deleteLastVertex();
      }
    }
  }, [props.isDeleteLastVertex]);

  /**
   * undo last subtracted polygons
   */
  useEffect(() => {
    if (!isEmpty(map) && backUpDrawnItems.length >= 1 && props.isDeleteLastDrawnPolygon >= 1) {
      let color = `${isSessionId ? '#FFD100' : '#00b5d8'}`;
      let fillOpacity = 0.3;
      backUpDrawnItems[0].setStyle({
        color,
        fillOpacity,
        fillColor: CERULEAM_COLOR
      });
      if (backUpDrawnItems.length === 1) {
        map.removeLayer(drawnItems);
        drawnItems = new L.FeatureGroup().addTo(map);
        if (location.state && location.state.addNewFieldFrom === 'MapsContainer') {
          backUpDrawnItems.pop();
          props.setDisableDrawPolygonAndCircle(false);
          props.setAdjustedfieldSize(undefined);
          props.setFieldSize(undefined);
          props.setClearDrawedElement({ polygon: true, circle: true });
          props.setIsElementDrawed(false);
          props.setGeometry(null);
        } else {
          drawnItems.addLayer(backUpDrawnItems[0]);
        }
      } else {
        backUpDrawnItems.pop();
        map.removeLayer(drawnItems);
        drawnItems = new L.FeatureGroup().addTo(map);
        drawnItems.addLayer(backUpDrawnItems[backUpDrawnItems.length - 1]);
      }
    }
  }, [props.isDeleteLastDrawnPolygon]);

  useEffect(() => {
    isSubtractPolygon = props.isSubtractPolygon?.polygon;
    if (!isEmpty(map) && props.isSubtractPolygon?.polygon) {
      if (polygonDraw.disable) {
        polygonDraw.disable();
      }
      polygonDraw = new L.Draw.Polygon(map);
      polygonDraw.enable();
    }
  }, [props.isSubtractPolygon]);

  const getBoundaries = () => {
    if (map.getZoom() >= 13) {
      let boundingBox = map.getBounds();

      map.closePopup();

      getAllDetectedFields(
        /* eslint-disable no-underscore-dangle */
        boundingBox._southWest.lng,
        boundingBox._northEast.lng,
        boundingBox._southWest.lat,
        boundingBox._northEast.lat
      );
    }
  };
  const getAllDetectedFields = async (lngMin, lngMax, latMin, latMax) => {
    /* eslint-disable array-callback-return */
    try {
      await getAllFields(lngMin, lngMax, latMin, latMax).then((response) => {
        if (response?.data?.features?.length > 0) {
          let tempObj = [];
          response.data.features.map((f, i) => {
            f.id = i + new Date().getTime();
            if (!detectedFields.includes(f)) {
              detectedFields.push(f);
              tempObj.push(f);
            }
          });
          loadDetectedLayers(tempObj);
        } else {
          showNoBoundaries();
        }
      });
    } catch (e) {
      showErrorDetectBoundaries();
    }
  };

  const loadDetectedLayers = (geoJson) => {
    if (isEmpty(map)) {
      initMap();
    }
    if (!isEmpty(fieldsDrawingLayer) && props.detectFields !== 'detectFields') {
      map.removeLayer(fieldsDrawingLayer);
      fieldsDrawingLayer = {};
    }
    setTimeout(() => {
      fieldsDrawingLayer = new L.FeatureGroup().addTo(map);

      map.addLayer(fieldsDrawingLayer);
      geoJson.forEach((element, i) => {
        if (element.geometry) {
          let p = new L.polygon(geojsonCordinatePtsToGooglemap(element.geometry.coordinates[0]), {
            fill: true,
            fillColor: element.color || MERCURY_COLOR,
            color: element.borderColor || WHITE_COLOR,
            fillOpacity: 0.3,
            myFieldId: element.id
          });

          fieldsDrawingLayer.addLayer(p);
          p.on('click', function(e) {
            const clickedField = detectedFields.find((x) => x.id === e.target.options.myFieldId);
            selectedDetectedFields.push(clickedField);
            props.handelDetectSelectedField(clickedField);
          });
          p.bindTooltip(`${element.properties.crop} ${element.properties.area_ha.toFixed(2)} ha`, {
            opacity: 1,
            myFieldId: i
          });
        }
      });
      addDetectSelectedFieldLayer();
    }, 200);

    setFieldCenter(geoJson);
  };

  useEffect(() => {
    if (props.drawGeometryBackupObj?.draw && props.drawGeometryBackupObj?.geometry) {
      if (props.drawGeometryBackupObj.geometry.coordinates.length > 1) {
        let geojsonFeature = {
          type: 'Feature',
          geometry: props.drawGeometryBackupObj.geometry
        };
        let multiLayer = L.geoJSON().addTo(map);
        multiLayer.addData(geojsonFeature);
        multiLayer.setStyle({
          fillColor: CERULEAM_COLOR,
          color: CERULEAM_COLOR
        });
        drawnItems.addLayer(multiLayer);
      } else {
        let p = new L.polygon(
          geojsonCordinateToGooglemap(props.drawGeometryBackupObj.geometry.coordinates[0]),
          {
            fill: true,
            fillColor: CERULEAM_COLOR,
            color: CERULEAM_COLOR,
            fillOpacity: 0.3
          }
        );
        backUpDrawnItems.pop();
        backUpDrawnItems.push(p);
        drawnItems.addLayer(p);
        p.enableEdit();
        p.on('dragend', onPolygonEdit);
      }
    }
  }, [props.drawGeometryBackupObj]);

  useEffect(() => {
    if (!isEmpty(map) && props.isDrawcircle.circle) {
      if (circleDraw.disable) {
        circleDraw.disable();
      }
      circleDraw = new L.Draw.Circle(map);
      circleDraw.enable();
      if (polygonDraw.disable) {
        polygonDraw.disable();
      }
    }
  }, [props.isDrawcircle]);
  useEffect(() => {
    isSubtractCircle = props.isSubtractCircle?.circle;
    if (!isEmpty(map) && props.isSubtractCircle?.circle) {
      if (polygn) {
        polygn.disableEdit();
      }
      if (circleDraw.disable) {
        circleDraw.disable();
      }
      circleDraw = new L.Draw.Circle(map);
      circleDraw.enable();
      if (polygonDraw.disable) {
        polygonDraw.disable();
      }
    }
  }, [props.isSubtractCircle]);

  useEffect(() => {
    if (
      !isEmpty(drawnItems) &&
      (props.clearDrawedElement.polygon || props.clearDrawedElement.circle)
    ) {
      if (polygonDraw.disable) {
        polygonDraw.disable();
      }
      if (circleDraw.disable) {
        circleDraw.disable();
      }
      map.removeLayer(drawnItems);
      drawnItems = new L.FeatureGroup().addTo(map);
      countForDrawPolygonFunCall = 0;
      isSubtractPolygon = false;
      isSubtractCircle = false;
    }
  }, [props.clearDrawedElement]);

  useEffect(() => {
    if (isEmpty(map)) {
      initMap();
    }
    if (!isEmpty(fieldsDrawingLayer)) {
      map.removeLayer(fieldsDrawingLayer);
      fieldsDrawingLayer = {};
    }
    setTimeout(() => {
      fieldsDrawingLayer = new L.FeatureGroup().addTo(map);
      map.addLayer(fieldsDrawingLayer);
      geoJsons = props.geoJsons;
      props.geoJsons.forEach((element) => {
        if (element.geometry) {
          if (element.geometry.coordinates.length > 1) {
            let geojsonFeature = {
              type: 'Feature',
              geometry: element.geometry
            };
            let multiLayer = L.geoJSON().addTo(map);
            multiLayer.options = {
              myFieldId: element.id
            };
            multiLayer.addData(geojsonFeature);
            fieldsDrawingLayer.addLayer(multiLayer);
            multiLayer.setStyle({
              fill: true,
              fillColor: element.color || MERCURY_COLOR,
              color: element.borderColor || WHITE_COLOR,
              fillOpacity: 0.3,
              myFieldId: element.id
            });
            multiLayer.bindTooltip(`${element.name} ${element.declared_area} ha`, {
              opacity: 0.3,
              myFieldId: element.id
            });
            multiLayer.on('click', function(e) {
              e.originalEvent.stopPropagation();
              props.handleFieldClick(e.target[params.tooltip].options.myFieldId);
            });
          } else {
            let p = new L.polygon(geojsonCordinateToGooglemap(element.geometry.coordinates[0]), {
              fill: true,
              fillColor: element.color || MERCURY_COLOR,
              color: element.borderColor || WHITE_COLOR,
              fillOpacity: 0.3,
              myFieldId: element.id
            });
            fieldsDrawingLayer.addLayer(p);
            p.bindTooltip(`${element.name} ${element.declared_area} ha`, {
              opacity: 0.3,
              myFieldId: element.id
            });
            p.on('click', function(e) {
              e.originalEvent.stopPropagation();
              props.handleFieldClick(e.target[params.tooltip].options.myFieldId);
            });
          }
        }
      });
    }, 200);
  }, [props.geoJsons]);

  useEffect(() => {
    if (!isEmpty(fieldsDrawingLayer)) {
      for (let index in fieldsDrawingLayer[params.layers]) {
        const layer = fieldsDrawingLayer[params.layers][index];
        let color = WHITE_COLOR;
        let fillOpacity = 0.3;
        if (props.selectedFieldId === layer.options.myFieldId) {
          color = `${isSessionId ? '#FFD100' : '#00b5d8'}`;
          if (backUpDrawnItems.length >= 1) {
            backUpDrawnItems = [];
            backUpDrawnItems.push(layer);
          } else {
            backUpDrawnItems.push(layer);
          }
        }
        layer.setStyle({
          color,
          fillOpacity
        });
      }
    }
  }, [props.selectedFieldId]);

  useEffect(() => {
    if (!isEmpty(fieldsDrawingLayer)) {
      for (let index in fieldsDrawingLayer[params.layers]) {
        const layer = fieldsDrawingLayer[params.layers][index];
        const foundElement = find(props.selectFieldsByOrg, {
          id: layer.options.myFieldId
        });
        if (foundElement) {
          layer.setStyle({
            fillColor: WHITE_COLOR,
            color: `${isSessionId ? '#FFD100' : '#00b5d8'}`,
            fillOpacity: 0.3
          });
        } else {
          layer.setStyle({
            fillColor: MERCURY_COLOR,
            fillOpacity: 0.3,
            color: WHITE_COLOR
          });
        }
      }
    }
  }, [props.selectFieldsByOrg]);

  useEffect(() => {
    if (!isEmpty(map)) {
      if (props.hasFocusBounds && !isEmpty(props.focusBounds)) {
        map.fitBounds(props.focusBounds);
      } else if (!isEmpty(props.geoJsons) && props.geoJsons.length > 0 && !props.hasFocusBounds) {
        let featCollection = {
          type: 'FeatureCollection',
          features: props.geoJsons.map((geo) => {
            geo.type = 'Feature';
            return geo;
          })
        };
        const multipolygon = L.geoJson(featCollection);
        map.fitBounds(multipolygon.getBounds());
      } else if (detectedFields.length > 0) {
        setFieldCenter(detectedFields);
      } else {
        map.fitBounds([[props.focusLat, props.focusLong]], { maxZoom: 17 });
      }
    }
  }, [props.focusLong, props.focusLat, props.focusBounds, props.geoJsons, detectedFields]);

  const setFieldCenter = (json) => {
    if (json.length > 0) {
      let featCollection = {
        type: 'FeatureCollection',
        features: json.map((geo) => {
          geo.type = 'Feature';
          return geo;
        })
      };
      const multipolygon = L.geoJson(featCollection);
      map.fitBounds(multipolygon.getBounds());
      isFinishedLoading = false;
    }
  };

  useEffect(() => {
    if (!isEmpty(props.clusterRes)) {
      L.geoJson(props.clusterRes, {
        style: applyStyleToZones,
        onEachFeature: function(feature, layer) {
          layer.__tagName = 'clusterGeoJSON';
        }
      }).addTo(map);
      const multipolygon = L.geoJson(props.clusterRes);
      map.fitBounds(multipolygon.getBounds());
      createLegend();
    }
  }, [props.clusterRes]);

  useEffect(() => {
    if (!isEmpty(props.productivityZonesAPIResponse)) {
      L.geoJson(props.productivityZonesAPIResponse, {
        style: applyStyleToAutomaticZones,
        onEachFeature: function(feature, layer) {
          layer.__tagName = 'clusterGeoJSON';
        }
      }).addTo(map);
      const multipolygon = L.geoJson(props.productivityZonesAPIResponse);
      map.fitBounds(multipolygon.getBounds());
      createLegend();
    }
  }, [props.productivityZonesAPIResponse]);

  const getColorForShapeFile = (colorValue) => {
    let color = '00FF00';
    shapeFileColorPallet.forEach((colorItem) => {
      if (colorValue >= colorItem.min && colorValue <= colorItem.max) {
        color = colorItem.color;
      }
    });
    return color;
  };

  /**
   * It will apply the style for each zones
   */
  const applyColorsToShapeFile = (feature) => {
    const selectedAttribute = props.selectedShapeFileGeoJson.selectedAttribute;
    if (feature) {
      return {
        fillColor:
          selectedAttribute === 'tiff'
            ? getColorForShapeFile(
                feature.properties[props.selectedShapeFileGeoJson.selectedTiffAttribute]
              )
            : getColorForShapeFile(feature.properties[selectedAttribute]),
        weight: 2,
        opacity: 1,
        color: TRANSPARENT_COLOR,
        dashArray: '3',
        fillOpacity: 0.5
      };
    }
  };

  const getMinMaxValue = (shapeFileGeoJsonData, selectedAttribute) => {
    if (shapeFileGeoJsonData?.features) {
      let min = shapeFileGeoJsonData.features[0].properties[selectedAttribute];
      let max = min;
      shapeFileGeoJsonData.features.map((file) => {
        if (file.properties[selectedAttribute] <= min) {
          min = file.properties[selectedAttribute];
        } else if (file.properties[selectedAttribute] >= max) {
          max = file.properties[selectedAttribute];
        }
      });

      const shapeFileColorPalletMinMax = {
        min,
        max
      };

      let shapeFileColorPalletRange = max - min;
      let shapeFileEachColorRange = shapeFileColorPalletRange / 15;

      shapeFileColorPallet = [];

      colors.forEach((color, i) => {
        let colorItem = {
          min: min + i * shapeFileEachColorRange,
          max: min + (i + 1) * shapeFileEachColorRange,
          color: color
        };
        shapeFileColorPallet.push(colorItem);
      });

      return shapeFileColorPalletMinMax;
    }
  };

  const clearMapLayers = () => {
    if (!isEmpty(map)) {
      map.eachLayer(function(layer) {
        if (
          layer.__tagName &&
          (layer.__tagName === 'clusterGeoJSON' || layer.__tagName === 'shapeFileGeoJSON')
        ) {
          map.removeLayer(layer);
        }
        if (layer._url) {
          layer.remove();
        }
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(legend)) {
      map.removeControl(legend);
    }
    clearMapLayers();
  }, [props.clearMap]);

  const setSelectedField = () => {
    if (!isEmpty(map)) {
      clearMapLayers();
      const multipolygon = L.geoJson(props.selectedFieldItem);
      map.fitBounds(multipolygon.getBounds());
    }
  };

  useEffect(() => {
    if (!isEmpty(props.selectedFieldItem)) {
      setSelectedField();
    }
  }, [props.selectedFieldItem]);

  useEffect(() => {
    if (
      !isEmpty(props.selectedNdviRgbVariabilitImages) &&
      !isEmpty(props.selectedNdviRgbVariabilitImages.image_urls)
    ) {
      if (!isEmpty(ndvirgbImageLayer)) {
        ndvirgbImageLayer.remove();
      }

      let image_urls = props.selectedNdviRgbVariabilitImages.image_urls;
      image_urls.map((imageURl_item) => {
        let image_url = getImageUrl(props.activeImage, imageURl_item);
        if (!isEmpty(fieldsDrawingLayer)) {
          for (let index in fieldsDrawingLayer[params.layers]) {
            const layer = fieldsDrawingLayer[params.layers][index];
            let color = WHITE_COLOR;
            let fillOpacity = 0.3;
            if (props.selectedFieldId === layer.options.myFieldId) {
              color = `${isSessionId ? '#FFD100' : '#00b5d8'}`;
              fillOpacity = 0;
            }
            layer.setStyle({
              color,
              fillOpacity
            });
          }
        }

        ndvirgbImageLayer = L.tileLayer(image_url, {
          attribution: '',
          maxZoom: 18
        }).addTo(map);
        ndvirgbImageLayer.addTo(map);
      });
      createLegend();
    } else {
      if (!isEmpty(ndvirgbImageLayer)) {
        ndvirgbImageLayer.remove();
      }
      if (!isEmpty(legend)) {
        map.removeControl(legend);
      }
      if (props.selectedNdviRgbVariabilitImages === null) {
        clearMapLayers();
      }
    }
  }, [props.selectedNdviRgbVariabilitImages, props.activeImage]);

  useEffect(() => {
    if (!isEmpty(props.selectedShapeFileGeoJson)) {
      if (!isEmpty(map)) {
        map.eachLayer(function(layer) {
          if (
            layer.__tagName &&
            (layer.__tagName === 'clusterGeoJSON' || layer.__tagName === 'shapeFileGeoJSON')
          ) {
            map.removeLayer(layer);
          }
        });
      }
      let shapeFileColourPalletData;
      if (props.selectedShapeFileGeoJson.selectedAttribute === 'tiff') {
        shapeFileColourPalletData = getMinMaxValue(
          props.selectedShapeFileGeoJson.geoJson,
          props.selectedShapeFileGeoJson.selectedTiffAttribute
        );
      } else {
        shapeFileColourPalletData = getMinMaxValue(
          props.selectedShapeFileGeoJson.geoJson,
          props.selectedShapeFileGeoJson.selectedAttribute
        );
      }
      L.geoJson(props.selectedShapeFileGeoJson.geoJson, {
        style: applyColorsToShapeFile,
        onEachFeature: function(feature, layer) {
          layer.__tagName = 'shapeFileGeoJSON';
        }
      }).addTo(map);
      const multipolygon = L.geoJson(props.selectedShapeFileGeoJson.geoJson);
      map.fitBounds(multipolygon.getBounds());
      createLegend(shapeFileColourPalletData);
    } else {
      if (!isEmpty(map)) {
        map.eachLayer(function(layer) {
          if (
            layer.__tagName &&
            (layer.__tagName === 'clusterGeoJSON' || layer.__tagName === 'shapeFileGeoJSON')
          ) {
            map.removeLayer(layer);
          }
        });
      }
      if (!isEmpty(legend)) {
        map.removeControl(legend);
      }
    }
  }, [props.selectedShapeFileGeoJson]);

  const getImageUrl = (activeImage, imageURl_item) => {
    switch (activeImage) {
      case 'NDVI':
        return imageURl_item.ndvi_image_url;
      case 'RGB':
        return imageURl_item.png_image_url;
      default:
        return imageURl_item.variability_view_url;
    }
  };

  return (
    <div data-testid='map-parent-div' style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
      <div data-testid='mapID-div' id='mapId' style={{ width: '100%', height: '94vh' }} />
    </div>
  );
};

Maps.propTypes = {
  isDrawable: PropTypes.bool,
  onDrawComplete: PropTypes.func,
  focusLat: PropTypes.number,
  focusLong: PropTypes.number,
  zoomLevel: PropTypes.number,
  maxZoomLevel: PropTypes.number,
  minZoomLevel: PropTypes.number,
  colorMultiplier: PropTypes.number,
  productivityZonesAPIResponse: PropTypes.func,
  zoom: PropTypes.bool,
  geoJsons: PropTypes.arrayOf(
    PropTypes.shape({
      borderColor: PropTypes.string,
      fillColor: PropTypes.string
    })
  ),
  reload: PropTypes.bool.isRequired,
  onMapClicked: PropTypes.func,
  isDrawPolygon: PropTypes.shape({
    polygon: PropTypes.bool
  }),
  isDrawcircle: PropTypes.shape({
    circle: PropTypes.bool
  }),
  clearDrawedElement: PropTypes.shape({
    polygon: PropTypes.bool,
    circle: PropTypes.bool
  }),
  drawGeometryBackupObj: PropTypes.object,
  handleFieldClick: PropTypes.func,
  focusBounds: PropTypes.object,
  hasFocusBounds: PropTypes.bool,
  handleDrawStart: PropTypes.func,
  selectFieldsByOrg: PropTypes.array,
  selectedFieldId: PropTypes.string,
  match: PropTypes.object,
  detectedFields: PropTypes.array,
  handelDetectSelectedField: PropTypes.func,
  onMoveEnd: PropTypes.func,
  detectFields: PropTypes.func,
  getCurrentZoomLevel: PropTypes.func,
  clusterRes: PropTypes.object,
  isDeleteLastVertex: PropTypes.bool,
  isDeleteLastDrawnPolygon: PropTypes.bool,
  setDisableDrawPolygonAndCircle: PropTypes.func,
  setAdjustedfieldSize: PropTypes.func,
  setFieldSize: PropTypes.func,
  setClearDrawedElement: PropTypes.func,
  setIsElementDrawed: PropTypes.func,
  setGeometry: PropTypes.func,
  isSubtractPolygon: PropTypes.shape({
    polygon: PropTypes.bool
  }),
  isSubtractCircle: PropTypes.shape({
    circle: PropTypes.bool
  }),
  selectedShapeFileGeoJson: PropTypes.object,
  clearMap: PropTypes.func,
  selectedFieldItem: PropTypes.object,
  selectedNdviRgbVariabilitImages: PropTypes.object,
  activeImage: PropTypes.string
};

Maps.defaultProps = {
  isDrawable: true,
  onDrawComplete: () => null,
  focusLat: 18.565266338242346,
  focusLong: 73.75885963439941,
  zoomLevel: 15,
  maxZoomLevel: 20,
  minZoomLevel: 1,
  geoJsons: [],
  focusBounds: null,
  hasFocusBounds: false,
  onMapClicked: () => null,
  isDrawPolygon: { polygon: false },
  isDrawcircle: { circle: false },
  clearDrawedElement: { circle: false, polygon: false },
  drawGeometryBackupObj: {},
  handleFieldClick: () => null,
  handleDrawStart: () => null,
  zoom: true,
  selectFieldsByOrg: [],
  selectedFieldId: ''
};
