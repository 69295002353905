import styled from 'styled-components';
import { Input as AntdInput } from 'antd/lib';
import get from 'lodash/get';

export const Input = styled(AntdInput)`
  background-color: ${(props) => get(props, 'theme.colors.inputBackground')};
  border: ${(props) => `1px solid ${get(props, 'theme.colors.inputBorder')}`};
  box-sizing: border-box;
  border-radius: 4px;
  color: ${(props) => get(props, 'theme.colors.buttonHoverColor')};
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => get(props, 'theme.fontSizes.medium')};
  line-height: 17px;
  margin: 5px 0 2em;
  height: 40px;
  ${(props) => {
    if (props.error) {
      return `border: 1px solid #ff4d4f;
              &:hover {
                border-color: #ff4d4f;
                }
              &:focus {
              border-color: #ff4d4f;
              border-right-width: 1px!important;
              outline: 0;
              box-shadow: 0 0 0 2px rgba(245,34,45,.2);
          }`;
    }
  }}
`;
