import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Radio, Switch, Button, Tooltip, ConfigProvider } from 'antd/lib';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../../components/settings/Component.css';
import 'react-datepicker/dist/react-datepicker.css';
import './filterModel.css';
import { ExclamationCircleFilled, DownOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import {
  Label, StyledP, StyledError, StyleCancelBtn, StyledFilterPopupButton, StyledFilterInput, StyledFilterCheckbox, StyledDateRangePicker, StyledRadioButtonPopup, StyledSwitchSection
} from '../../../utils/styles.theming';
import locale_es from 'antd/lib/locale/es_ES';
import locale_en from 'antd/lib/locale/en_US';
import moment from 'moment';
import "./es"
import "moment/locale/en-gb";

const dateFormat = 'YYYY-MM-DD';

const StyledButton = styled.div`
   width   : 97%;
   position: relative;
`;

const StyledButtonPopUp = styled.div`
    position: absolute;
    z-index : 9;
    width   : 97%;
    top     : 0px;
`;
const StyledModal = styled(Modal)`
  .ant-input-number {
    font-family: ${(props) => get(props, 'theme.font.family')};
    color: ${(props) => get(props, 'theme.colors.buttonHoverColor')};
  }
  .ant-input-number:focus {
    border-color: ${(props) => get(props, 'theme.colors.buttonHoverBgColor')} !important;
    box-shadow: 0 0 0 2px ${(props) => get(props, 'theme.colors.selectBoxshadowColor')} !important;
  }
  .ant-input-number:hover {
    border-color: ${(props) => get(props, 'theme.colors.buttonHoverBgColor')} !important;
  }
  .ant-input-number-focused {
    border-color: ${(props) => get(props, 'theme.colors.buttonHoverBgColor')} !important;
    box-shadow: 0 0 0 2px ${(props) => get(props, 'theme.colors.selectBoxshadowColor')} !important;
  }
  .ant-input-number-input{
    background-color: #f5f8fc;
  }
  .percentag {
    color: ${(props) => get(props, 'theme.colors.buttonHoverColor')};
    background: #f5f8fc;
  }

  .rangeslider {
    display: block;
    box-shadow: none;
    font-family: ${(props) => get(props, 'theme.font.family')};
  }
  .rangeslider-horizontal .rangeslider__handle {
    width: 25px;
    height: 25px;
    border-color: ${(props) => get(props, 'theme.colors.primary')};
    box-shadow: 0 0 0 2px ${(props) => get(props, 'theme.colors.selectBoxshadowColor')};
    border: 3px solid;
  }
  .rangeslider-horizontal .rangeslider__handle:focus {
    border-color: ${(props) => get(props, 'theme.colors.primary')} !important;
    box-shadow: 0 0 0 2px ${(props) => get(props, 'theme.colors.selectBoxshadowColor')} !important;
  }
  .rangeslider-horizontal .rangeslider__handle:after {
    background-color: transparent;
    box-shadow: none;
  }
  .rangeslider__handle:focus {
    outline: none;
  }
  .rangeslider-horizontal .rangeslider__fill {
    background-color: ${(props) => get(props, 'theme.colors.primary')};
  }

  .rangeslider .rangeslider__handle {
    box-shadow: none;
    border: 2px solid ${(props) => get(props, 'theme.colors.primary')}; !important;
  }
  .rangeslider .rangeslider__fill {
    box-shadow: none;
  }
  .rangeslider-horizontal {
    height: 4px;
  }
  .ant-modal-title {
    font-size: 20px;
    color: #f9c43d;
    font-family: ${(props) => get(props, 'theme.font.family')};
    font-style: normal;
  }

  .ant-modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    border-bottom: 3px solid #f74141;
    border-radius: 8px 4px 0 0;
    background:#F2F4F6;
  }

  .ant-modal-header {
    border-bottom: 2px solid #faad14db;
  }
  .ant-modal-body {
    padding-top:5px !important;
  }
  .cloudCover_value{
    color: ${(props) => get(props, 'theme.colors.primary')} !important;
  }
  .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: #CCD0D7;
`;
const StyledDownOutlined = styled(DownOutlined)`
  cursor: pointer;
  color: ${(props) => get(props, 'theme.colors.primary')};
  position: absolute;
  bottom: 31%;
  right: 3%;
  font-size: 12px;
  z-index: 1;
  display: block;
`;


let errors = {};
export const FilterPopup = (props) => {
  const { t } = useTranslation();
  const [showSourceDropdown, setShowSourceDropdown] = useState(false);
  const [showYearDropdown, setShowYearDropdown] = useState(false);
  const [isError, setIsError] = useState(false);
  const [activeElement, setActiveElement] = useState('');
  const [yearOptions, setYearOptions] = useState([]);
  const [cloudCoverValue, setCloudCoverValue] = useState(props.filterPopupData.cloudCoverValue);
  /**
   * Dynamically Generating YearOptions ArrayObject it contains "CurrentYear" to 2012 Data
   */
  const generateYearOptions = () => {
    let currentYear = new Date().getFullYear();
    let yearOptionsData = [];
    for (let year = 2012; year <= currentYear; year++) {
      yearOptionsData.push({ value: `${year}`, label: `${year}` });
    }
    setYearOptions(yearOptionsData);
  };

  const selectedLang = localStorage.getItem('i18nextLng')
  /**
   * for dispalying the SourcePopup in filter popup.
   */
  const viewSourceDropdown = (event) => {
    event.stopPropagation();
    setShowSourceDropdown(!showSourceDropdown);
    setShowYearDropdown(false);
  };

  /**
   * for dispalying the YearPopup in filter popup.
   */
  const viewYearDropdown = (event) => {
    event.stopPropagation();
    setShowSourceDropdown(false);
    setShowYearDropdown(!showYearDropdown);
  };
  /**
   * for getting the selected Source from Source selection list in filter popup.
   */
  const getSourceSelectionValue = (event) => {
    let { filterPopupData } = props;
    event.stopPropagation();
    let data = filterPopupData.selectedSource;
    if (data.includes(event.target.name)) {
      let index = data.indexOf(event.target.name);
      data.splice(index, 1);
      filterPopupData.selectedSource = data;
    } else {
      data.push(event.target.name);
      filterPopupData.selectedSource = data;
    }
    if (filterPopupData && filterPopupData.selectedSource.length > 0) {
      delete errors.selectedSource;
    }
    setActiveElement('selectedSource');
  };

  /**
   * for getting the selected year from year selection list in filter popup.
   */
  const getSelectedYearValue = (event, value) => {
    event.stopPropagation();
    if (props.filterPopupData.selectedYear !== '' && props.filterPopupData.selectedYear !== value) {
      props.filterPopupData.setSelectedDate = '';
      props.filterPopupData.setSelectedEndDate = '';
      setValue([]);
      setDates([]);
    }
    props.filterPopupData.selectedYear = value;
    setShowSourceDropdown(false);
    setShowYearDropdown(false);
    setActiveElement('selectedYear');
  };

  const closeDropDownsOnOutsideClick = (event) => {
    if (showSourceDropdown || showYearDropdown) {
      setShowSourceDropdown(false);
      setShowYearDropdown(false);
    }
  };

  const stopPropagationOnSource = (event) => {
    event.stopPropagation();
  };

  const validateFilterPopup = (value) => {
    const { filterPopupData } = props;
    if (value === 'selectedYear' || value === 'All') {
      delete errors.selectedYear;
      if (!filterPopupData.selectedYear || filterPopupData.selectedYear === '') {
        errors.selectedYear = 'Year is required';
      }
    }
    if (value === 'selectedDates' || value === 'All') {
      delete errors.selectedDates;
      if (!filterPopupData.isImgListToggleOn &&
        (!filterPopupData.selectedEndDate ||
          filterPopupData.selectedEndDate === '' ||
          !filterPopupData.selectedDate ||
          filterPopupData.selectedDate === '')
      ) {
        errors.selectedDates = 'Start and end dates are required';
      }
    }
    if (value === 'selectedSource' || value === 'All') {
      delete errors.selectedSource;
      if (filterPopupData && filterPopupData.selectedSource.length === 0) {
        errors.selectedSource = 'Source is required';
      }
    }
    setIsError(!isError);
    return Object.keys(errors).length;
  };
  useEffect(() => {
    generateYearOptions();
    return () => {
      errors = {};
    };
  }, []);

  useEffect(() => {
    if (activeElement) {
      validateFilterPopup(activeElement);
    }
  }, [
    props.filterPopupData.selectedSource.length,
    props.filterPopupData.selectedDate,
    props.filterPopupData.selectedYear,
    props.filterPopupData.selectedEndDate
  ]);

  const getCloudCoverValue = (value) => {
    if (isNaN(value)) {
      return;
    }
    setCloudCoverValue(value);
    props.filterPopupData.cloudCoverValue = value;
  };
  let { filterPopupData } = props;

  /**
 * Date Range picker changes
 */
  const [dates, setDates] = useState([]);
  const [value, setValue] = useState(props.filterPopupData.selectedDate ? [props.filterPopupData.selectedDate, props.filterPopupData.selectedEndDate] : []);
  const disabledDate = current => {
    const selectedYear = props.filterPopupData?.selectedYear;
    const minDate = `${selectedYear - 1}-08-01`;
    if (!dates || dates.length === 0) {
      return !current.isSameOrAfter(minDate);
    }

    let maxEndDate = new Date(dates[0]);
    maxEndDate.setFullYear(maxEndDate.getFullYear() + 1);
    maxEndDate.setDate(maxEndDate.getDate() - 1);
    maxEndDate = maxEndDate < new Date() ? maxEndDate : new Date();
    props.filterPopupData.maxEndDate = maxEndDate;
    return !current.isBetween(minDate, maxEndDate, undefined, []);
  };

  const onOpenChange = open => {
    setActiveElement('selectedDates');
  };


  const getDefaultCalanderValues = () => {
    const selectedYear = props.filterPopupData?.selectedYear;
    const minDate = `${selectedYear}-01-01`;
    const minMaxdefault = [moment(minDate, dateFormat), undefined];
    return minMaxdefault;
  }
  return (
    <Button onClick={closeDropDownsOnOutsideClick} >
      <StyledModal
        data-testid='add-new-filter-model'
        id='add-new-filter-model'
        title={t('Management Zone')}
        style={{ top: '20%', color: '#F9C43D' }}
        visible={true}
        onCancel={props.onCancel}
        closable={false}
        footer={[
          <StyleCancelBtn
            data-testid='cancelBtn'
            style={{ color: 'black', right: '1%' }}
            key='cancel'
            onClick={props.onCancel}>
            {t('Cancel')}
          </StyleCancelBtn>,
          <StyledFilterPopupButton
            data-testid='applyBtn'
            key='apply'
            onClick={() =>
              props.onApplyFilter({
                validateFilterPopup
              })
            }>
            {t('Apply')}
          </StyledFilterPopupButton>
        ]}>
        <Row>
          <Col span={12}>
            <div className='mzma-source_box m-t-0'>
              <StyledButton data-testid="years-dropdown" onClick={viewYearDropdown}>
                <Tooltip
                  data-testid='year-label-hover'
                  title={t('The result will contain images from the year selected here to the current year')}
                  placement='top'
                  trigger='hover'>
                  <StyledP data-testid='year-label' className='lable_text'>{t('Year')} </StyledP>
                </Tooltip>
                <StyledFilterInput
                  type='text'
                  className={
                    errors?.selectedYear ? 'mzma-Source_Input_error' : 'mzma-Source_Input'
                  }
                  placeholder={t('Select Year')}
                  readOnly
                  value={filterPopupData.selectedYear}
                  data-testid="select-year-input"
                />
                <StyledDownOutlined />
              </StyledButton>
              {errors?.selectedYear && (
                <StyledError className='error'>
                  <ExclamationCircleFilled className='exclamation_alignment' />
                  {t(errors.selectedYear)}
                </StyledError>
              )}
              {showYearDropdown && (
                <StyledButtonPopUp
                  onClick={(event) => stopPropagationOnSource(event)}>
                  <div className='mzma-source_popup_content'>
                    <StyledRadioButtonPopup className='mzma-source_selection_content'>
                      {yearOptions.map((yearData, index) => (
                        <div key={`year-option-${yearData.value}-${index}`} className='mzma-section_option'>
                          <Radio data-testid={`year-${yearData.value}`} onChange={(event) => getSelectedYearValue(event, yearData.value)} checked={filterPopupData.selectedYear === yearData.value}>
                            {yearData.value}
                          </Radio>
                        </div>
                      ))}
                    </StyledRadioButtonPopup>
                  </div>
                </StyledButtonPopUp>
              )}
            </div>
          </Col>
          <Col span={12}>
            <div className='mzma-source_box'>
              <div className='mzma-Source_Input_section'>
                <Tooltip
                  data-testid='date-label-hover'
                  title={t('The result will contain the range of months to filter images. Recommended images are around the critical period for crops.')}
                  placement='topLeft'
                  trigger='hover'>
                  <StyledP data-testid='date-label' style={{ marginLeft: 10 }} className='lable_text'>
                    {t('Date')}{' '}
                  </StyledP>
                </Tooltip>
                <ConfigProvider locale={selectedLang === 'en' ? locale_en : locale_es}>
                  <StyledDateRangePicker
                    className={
                      errors?.selectedDates ? 'm-l-10 dateRange_picker_Error' : 'm-l-10'
                    }
                    // className="m-l-10"
                    key={props.filterPopupData.selectedYear}
                    value={value}
                    disabledDate={disabledDate}
                    onCalendarChange={val => {
                      setDates(val)
                    }
                    }
                    onChange={(onChangeDates, dateStrings) => {
                      props.filterPopupData.selectedDate = onChangeDates[0];
                      props.filterPopupData.selectedEndDate = onChangeDates[1];
                      setValue(onChangeDates)
                    }
                    }
                    defaultValue={value}
                    onOpenChange={onOpenChange}
                    placeholder={[`${t('Start date')}`, `${t('End date')}`]}
                    format={['MMM DD YYYY', 'MMM DD YYYY']}
                    defaultPickerValue={getDefaultCalanderValues()}
                    suffixIcon={<StyledDownOutlined />}
                  />
                </ConfigProvider>
              </div>
            </div>
            {errors?.selectedDates && (
              <StyledError className='error date_error'>
                <ExclamationCircleFilled className='exclamation_alignment' />
                {t(errors.selectedDates)}
              </StyledError>
            )}
          </Col>
          <Col span={12}>
            <div className='mzma-source_box'>
              <StyledButton data-testid="source-dropdown" onClick={viewSourceDropdown}>
                <Tooltip
                  title={t('Satellite from which the images come.')}
                  placement='left'
                  trigger='hover'>
                  <StyledP data-testid='source-label' className='lable_text'>{t('Source')} </StyledP>
                </Tooltip>
                <StyledFilterInput
                  type='text'
                  className={
                    errors?.selectedSource
                      ? 'mzma-Source_Input_error'
                      : 'mzma-Source_Input'
                  }
                  placeholder={t('Select Source')}
                  readOnly
                  value={filterPopupData.selectedSource.toString()}
                  data-testid="select-source-input"
                />
                <StyledDownOutlined />
              </StyledButton>
              {errors?.selectedSource && (
                <StyledError className='error'
                  data-testid="source-sentinel-error"
                >
                  <ExclamationCircleFilled className='exclamation_alignment' />
                  {t(errors.selectedSource)}
                </StyledError>
              )}
              {showSourceDropdown && (
                <div
                  className='mzma-source_popup'
                  style={{ width: '105%' }}
                  onClick={(event) => stopPropagationOnSource(event)}>
                  <div className='mzma-source_popup_content'>
                    <div className='mzma_source_section'>
                      <div className='mzma-section_option'>
                        <Label className='mzma-radio_options'>
                          <StyledFilterCheckbox
                            className='mzma-source_checkbox'
                            name='sentinel'
                            defaultChecked={filterPopupData.selectedSource.includes('sentinel')}
                            onChange={getSourceSelectionValue}
                            data-testid="source-sentinel"
                          />
                          {t('sentinel (10 m resolution)')}
                        </Label>
                      </div>
                      <div className='mzma-section_option'>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col span={12}>
            <div className='mzma-source_box'>
              <div className='mzma-Source_Input_section m-l-10'>
                <Tooltip
                  title={t('Threshold percentage of the satellite scene covered by clouds. he higher the allowed limit, be careful of clouds that interfere in the field.')}
                  placement='right'
                  trigger='hover'>
                  <StyledP data-testid='cloud-label' className='lable_text'>{t('Cloud Cover')} </StyledP>
                </Tooltip>
                <StyledP className='lable_text cloudCover_value'>{cloudCoverValue}%</StyledP>
                <Row>
                  <Col span={24}>
                    <Slider
                      min={0}
                      max={100}
                      step={1}
                      value={cloudCoverValue}
                      onChange={getCloudCoverValue}
                      data-testid="select-cloud-cover-input"
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
          </Col>
          <Col span={12}>
            <div className='mzma-source_box m-l-10'>
              <Tooltip
                title={t('Generate management zones only with Shapefiles (elevation maps; yield maps).')}
                placement='bottomLeft'
                trigger='hover'>
                <StyledP data-testid="select-radio-label" style={{ width: '190px' }} className='lable_text'>{t('Select only Shapefiles')} </StyledP>
              </Tooltip>
              <StyledSwitchSection>
                <Switch
                  className='mzma-img_toggle'
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={filterPopupData.isImgListToggleOn}
                  size="small"
                  onChange={(event) => { filterPopupData.isImgListToggleOn = event }}
                  data-testid="select-radio-input"
                />
              </StyledSwitchSection>
            </div>
          </Col>
        </Row>
        <Row />
      </StyledModal>
    </Button>
  );
};

FilterPopup.propTypes = {
  onApplyFilter: PropTypes.func,
  onCancel: PropTypes.func,
  filterPopupData: PropTypes.object,
};
