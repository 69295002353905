import React, { useState, useEffect } from 'react';
import { Card, Row, Col, PageHeader, Icon } from 'antd/lib';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import NewArea from '../../../public/new-area.svg';
import Files from '../../../public/files.svg';
import { Maps } from '../../Maps/Maps';
import { PageLayout } from '../../Layout/page-layout';
import { getLatLongByPropId } from '../../../utils/getLatLongByPropId';
import { fetchOrgs } from '../../../actions/index';
import { URL_CONSTANTS } from '../../../utils/history';
import { AmplitudeInstance } from '../../../utils/amplitude';
import { StyledHeader } from '../../landingFarm/CreateEditFarmComponent.style';
import get from 'lodash/get';
import { SvgIcon } from '../IconComponent';
import { iff } from '../../../utils/iff';
import { title } from 'process';
import { icon } from 'leaflet';

const StyledCard = styled(Card)`
  width: 215px;
  height: 175px;
  text-align: center;
  padding: 20px;
  &:hover {
    color: #faad14db;
  }
  cursor: pointer;
`;
const StyledTitle = styled.h3`
  font-family: ${(props) => get(props, 'theme.font.family')};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #515253;
  margin-top: 24px;
  &:hover {
    color: #faad14db;
  }
`;
const StyledBody = styled.p`
  font-family: ${(props) => get(props, 'theme.font.family')};
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
`;

const StyledPagedHeader = styled(PageHeader)`
  .ant-page-header-content {
    padding-top: 0px;
  }
`;
const HoverableCard = ({ title, subtitle, onClick, icon }) => {
  const [hover, setHover] = useState(false);

  return (
    <StyledCard
      className='create-field-options'
      bodyStyle={{ padding: '0' }}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <SvgIcon name={icon} color={iff(hover, '#fff', '#009933')} size={25} />
      <StyledTitle color={iff(hover, '#fff', '#009933')} ellipsis={{ rows: 2, expandable: false }}>
        {title}
      </StyledTitle>
      <StyledBody color={iff(hover, '#fff', '#009933')} ellipsis={{ rows: 3, expandable: false }}>
        {subtitle}
      </StyledBody>
    </StyledCard>
  );
};
const DEFAULT_LAT_LONG = 10;

const CreateFieldsCard = (props) => {
  const { t } = useTranslation();
  const [lat, setLat] = useState(DEFAULT_LAT_LONG);
  const [long, setLong] = useState(DEFAULT_LAT_LONG);
  const [routerState, setRouterState] = useState({});

  useEffect(() => {
    const farmId = props.match.params.farmId;
    getLatLongByPropId(farmId, setLat, setLong, DEFAULT_LAT_LONG);
    props.fetchOrgs();
  }, [props.match]);
  const onBackButtonClicked = () => {
    const orgId = props.match.params.id;
    const farmId = props.match.params.farmId;
    props.history.push(URL_CONSTANTS.EDIT_FARM({ orgId, farmId }));
  };

  const uploadCreateClick = () => {
    const orgId = props.match.params.id;
    const farmId = props.match.params.farmId;
    AmplitudeInstance.onAddNewFieldUsingShapeFile();
    props.history.push(URL_CONSTANTS.CREATE_FIELD_SHAPE_FILE_UPLOADER({ orgId, farmId }));
  };

  const onDrawFieldClick = () => {
    const orgId = props.match.params.id;
    const farmId = props.match.params.farmId;
    AmplitudeInstance.onAddNewFieldUsingBoundary();
    props.history.push(URL_CONSTANTS.CREATE_FIELD_DRAW({ orgId, farmId }));
  };

  const onDetectFieldsClick = () => {
    const orgId = props.match.params.id;
    const farmId = props.match.params.farmId;
    return props.history.push({
      pathname: URL_CONSTANTS.DETECT_FIELDS({ orgId, farmId }),
      state: routerState
    });
  };

  return PageLayout({
    content: (
      <Row style={{ backgroundColor: '#fff' }}>
        <Col span={9}>
          <StyledHeader>
            <Icon
              onClick={onBackButtonClicked}
              className='back-button'
              type='left'
              style={{ fontSize: '14px' }}
            />
            <StyledPagedHeader data-testid="create-fields-page-title">{t('Create fields')}</StyledPagedHeader>
          </StyledHeader>
          <Row style={{ margin: '12px 12px' }} gutter={[12, 12]}>
            <Col span={12}>
              <StyledCard bodyStyle={{ padding: '0' }} onClick={onDrawFieldClick}>
                <img src={NewArea} alt="NewArea" />
                <StyledTitle data-testid="draw-fields-title">{t('Draw your fields')}</StyledTitle>
                <StyledBody>
                  {t(`Draw your properties fields with the drawing tools.`)}
                </StyledBody>
              </StyledCard>
            </Col>
            <Col span={12}>
              <StyledCard bodyStyle={{ padding: '0' }} onClick={uploadCreateClick}>
                <img src={Files} alt="Files" />
                <StyledTitle data-testid="upload-fields-title">{t('Upload file')}</StyledTitle>
                <StyledBody>
                  {t('Upload the files containing field`s shapes and names.')}
                </StyledBody>
              </StyledCard>
            </Col>
            <Col span={12} data-testid="select-fields-title">
              <HoverableCard
                onClick={onDetectFieldsClick}
                title={t('Select fields on the map')}
                icon='map-location'
                subtitle={t('The fields are already detected on the map and can be edited.')}
              />
            </Col>
          </Row>
        </Col>
        <Col span={15}>
          <Maps
            isDrawable={false}
            focusLat={+lat}
            focusLong={+long}
            onMoveEnd={(event) => {
              setRouterState(event);
            }}
            data-testid="create-fields-page-map"
          />
        </Col>
      </Row>
    )
  });
};

CreateFieldsCard.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      farmId: PropTypes.string.isRequired
    })
  }),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
};

HoverableCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string
}

const mapDispatchToProps = (dispatch) => ({
  fetchOrgs: () => dispatch(fetchOrgs())
});

export const CreateFieldsCardComponent = connect(null, mapDispatchToProps)(CreateFieldsCard);
