export const getParamValue = (parameterName) => {
  let result = null;
  let items = location.search.substring(1).split('&');
  for (const item of items) {
    const [key, value] = item.split('=');
    if (key === parameterName) {
      result = decodeURIComponent(value);
      break;
    }
  }
  return result;
};
