import styled from 'styled-components';
import get from 'lodash/get';
import { Button } from 'antd/lib';
export const CardContainer = styled.div`
  width: 97%;
  position: relative;
  margin-left: 20px;
  .ant-carousel .slick-slide {
    overflow: hidden;
  }

`;
export const StyledPropertySection = styled.div`
  background: #ffffff;
  position: relative;
`;
export const StyledContainer = styled.div`
   border-radius: 8px;
   height: calc(100vh - 85px);
   background-color: white;
   display: block;
   position: relative;
   padding-top: 10px;
   padding-left: 4px;
   width: 85%;
   margin: 15px auto;
   overflow-y: auto;
  ::-webkit-scrollbar-thumb {
    background: ${(props) => get(props, 'theme.colors.scrollbarThumbBgColor')};
    -webkit-box-shadow: inset 0 3px 2px 6px
      ${(props) => get(props, 'theme.colors.scrollbarThumbBgColor')} !important;
    cursor: pointer;
  }
`;

export const StyledInputDiv = styled.div`
width: 310px;
margin-left: 22px;
span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg{
  margin-top: 40px;
  color:${props => get(props, 'theme.colors.primary')};
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  background: #F5F8FC;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled){
  border-color: ${props => get(props, 'theme.colors.primary')};
  color:${props => get(props, 'theme.colors.primary')};
}
`;

export const StyledButtonSection = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-right: 20px !important;

`;
export const StyledButton = styled(Button)`
height: 50px;
width: 50px;
border-radius: 50%;
margin-right: 20px;
margin-bottom: 20px;
color:${props => get(props, 'theme.colors.fontSecondaryColor')};
border: 1px solid ${props => get(props, 'theme.colors.fontSecondaryColor')};
&:hover {
  border: 1px solid ${props => get(props, 'theme.colors.saveAndAddFildBgHoverColor')};
  color:${props => get(props, 'theme.colors.primary')};
}
&:active {
  border-color: ${props => get(props, 'theme.colors.primary')};
  color:${props => get(props, 'theme.colors.primary')};
}
&:focus {
  border-color: ${props => get(props, 'theme.colors.primary')};
  color:${props => get(props, 'theme.colors.primary')};
}

.anticon{
  font-size: 15px;
}
`;
