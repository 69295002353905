import { TOGGLE_HEADER } from '../actions/types';

const initialState = {
  collapsed: true,
  selectedOrganization: null,
  selectedProperty: null
};

export const header = (state = initialState, action) => {
  if (action.type === TOGGLE_HEADER) {
    return {
      ...state,
      collapsed: (action.payload !== null && action.payload) || !state.collapsed
    };
  } else {
    return state;
  }
};
