/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { Row, Col, PageHeader, Icon, Modal } from 'antd/lib';
import styled from 'styled-components';
import { AmplitudeInstance } from '../../utils/amplitude';

import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { geojsonCordinateToGooglemap } from '../Maps/geojsonCordinateToGooglemap';

import { Maps } from '../Maps/Maps';
import { StyledButtonsCancelSubmit } from '../Maps/Maps.styles';

import { PageLayout } from '../Layout/page-layout';
import { ErrorModal } from '../common/Modal/ErrorModal';
import { ConfirmModal } from '../common/ConfirmModal';

import {
  StyledHeader,
  DetectFarmContainer,
  StyledCol
} from '../../components/landingFarm/CreateEditFarmComponent.style';
import { getLocationFromBrowser } from '../../utils/location';
import { iff } from '../../utils/iff';
import { StyledSaveButton } from '../../utils/styles.theming';
import { getFieldsByPropertyId, getFieldById } from '../../actions/fields';

import { getPropertyDetailsById } from '../../actions';
import { getErrorMessageByCode } from '../../utils/getErrorMessageByCode';
import { URL_CONSTANTS } from '../../utils/history';
import { Loader } from '../Loader/Loader';
import { addDetectedFields } from '../../services/detectedFieldsService';

const DEFAULT_LAT_LONG = 10;
let unit_system;
let detectedField = [];
let isSessionId;
export const SpinnerStyle = styled.div`
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 40px;
  border-radius: 2px;
`;

const { confirm } = Modal;

const DetectFields = (props) => {
  const stateLat = get(props, 'location.state.latLng.lat', null);
  const stateLng = get(props, 'location.state.latLng.lng', null);
  const stateZoom = get(props, 'location.state.zoomLevel', null);
  const [lat, setLat] = useState(DEFAULT_LAT_LONG);
  const [long, setLong] = useState(DEFAULT_LAT_LONG);
  const [property, setProperty] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isDirty,] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [showSpiner, setShowSpiner] = useState(false);
  const [isLoading,] = useState(false);
  const [focusBounds, setFocusBounds] = useState(null);
  const [currentZoomLevel, setCurrentZoomLevel] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSave,] = useState(false);
  const [routerState, setRouterState] = useState({});
  const [detectedFields, setDetectedFields] = useState([]);
  let { fieldId } = props.match.params;
  const { t } = useTranslation();

  useEffect(() => {
    if (!isEmpty(props.error) && Object.keys(props.error.response.data).length > 0) {
      const errorMessageByCode = getErrorMessageByCode(props.error.response.data.code);
      setErrorMessage(errorMessageByCode);
      setIsDataLoading(false);
    }
  }, [props.error]);

  const getMapBounds = (obj) => {
    return L.geoJson({ geometry: obj, type: 'Feature' }).getBounds();
  };

  useEffect(() => {
    const propId = props.match.params.farmId;
    const getPropDetails = async () => {
      const propertyRes = await getPropertyDetailsById(propId);
      const coordinates = get(propertyRes.data, 'reference_point.coordinates');
      if (stateLng && stateLat) {
        setLong(stateLng);
        setLat(stateLat);
        setRouterState(props.location.state);
        localStorage.setItem('routerState', JSON.stringify(props.location.state));
      } else {
        if (isEmpty(coordinates)) {
          (async () => {
            try {
              const location = await getLocationFromBrowser();
              setLong(location.longitude);
              setLat(location.latitude);
            } catch (err) {
              console.error('err', err);
            }
          })();
        } else {
          setTimeout(() => {
            setLat(coordinates[1] || DEFAULT_LAT_LONG);
            setLong(coordinates[0] || DEFAULT_LAT_LONG);
          }, 2000);
        }
      }
      setProperty(propertyRes.data);
    }
    getPropDetails();
  }, [props.match]);

  useEffect(() => {
    const propId = props.match.params.farmId;
    const getFieldDetails = async (allfields) => {
      const response = await getFieldById(fieldId);
      const fieldDetails = response.data;
      if (!isEmpty(fieldDetails.geometry)) {
        setFocusBounds(getMapBounds(fieldDetails.geometry));
      }
      setIsDataLoading(false);
    };

    const getFieldFromRouteState = async () => {
      const { currentField } = props.location.state;
      setIsDataLoading(false);
      setFocusBounds(getMapBounds(currentField.geometry));
    };

    const getDetails = async () => {
      if (fieldId && fieldId === 'upload') {
        setTimeout(getFieldFromRouteState, 200);
        return;
      }
      const propertyRes = await getFieldsByPropertyId(propId);
      let allFieldsRes = propertyRes.data.content || [];
      if (fieldId) {
        getFieldDetails(allFieldsRes);
      } else {
        setIsDataLoading(false);
      }
    };

    getDetails();
  }, [props.match]);

  const handleRedirectionForCancel = () => {
    if (isEmpty(fieldId)) {
      return props.history.push({
        pathname: URL_CONSTANTS.CREATE_FIELDS({
          orgId: props.match.params.id,
          farmId: props.match.params.farmId
        }),
        state: routerState
      });
    }

    if (fieldId === 'upload') {
      let { newFields } = props.location.state;
      return props.history.push({
        pathname: URL_CONSTANTS.CREATE_FIELD_SHAPE_FILE_LIST({
          orgId: props.match.params.id,
          farmId: props.match.params.farmId
        }),
        state: {
          geoJsons: newFields
        }
      });
    }
    props.history.push(
      URL_CONSTANTS.ALL_FIELDS({ orgId: props.match.params.id, farmId: props.match.params.farmId })
    );
  };
  const onCancelClick = () => {
    setDetectedFields([]);
    detectedField = [];
    if (isDirty) {
      setShowConfirmModal(true);
    } else if (props.location.state && props.location.state.addNewFieldFrom === 'MapsContainer') {
      props.history.push(
        URL_CONSTANTS.LANDING_FARM({
          orgId: props.match.params.id,
          farmId: property.id
        })
      );
    } else {
      handleRedirectionForCancel();
    }
  };

  useEffect(() => { }, [detectedField]);

  const handleFieldClick = (field) => {
    detectedField.push(field);
    detectedField = detectedField.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
    setDetectedFields(detectedField);
  };

  const removeDetectedFields = (e) => {
    detectedField = detectedField.filter((f) => {
      return f.id !== e.id;
    });
    setDetectedFields(detectedField);
  };

  useEffect(() => {
    if (window.location.href.indexOf('detect-fields') != -1) {
      setDetectedFields([]);
      detectedField = [];
    }
  }, [props.match]);

  const convertFieldsIntoGeoJson = (fieldList) => {
    const fieldObjectList = [];
    /* eslint-disable array-callback-return*/
    fieldList.map((field) => {
      const fieldName = `field_${field.id}`;
      const fieldObject = {
        id: uuidv4(),
        name: fieldName,
        geometry: {
          type: 'Polygon',
          coordinates: geojsonCordinateToGooglemap(field.geometry.coordinates[0])
        },
        adjustedSize: field.properties.area_ha
      };
      fieldObjectList.push(fieldObject);
    });

    return fieldObjectList;
  };
  useEffect(() => {
    isSessionId = localStorage.getItem('session_id');
  }, []);

  const addDetectFields = async () => {
    setShowSpiner(true);
    if (detectedField.length > 0) {
      const detectFieldsGeoJson = convertFieldsIntoGeoJson(detectedField);
      addDetectedFields(detectFieldsGeoJson, props.match.params.farmId)
        .then((res) => {
          if (res.status != 207) {
            setShowSpiner(false);
            detectedField.forEach((field) => {
              AmplitudeInstance.detectFieldEvents({
                event: 'detect_fields_added',
                org_id: get(props, 'match.params.id'),
                property_id: property.id,
                property_name: property.name,
                name: field.name,
                area: get(field, 'properties.area_ha'),
                geometry: field.geometry,
                page: 'Detect field'
              });
            });
            confirm({
              content: t('Fields added successfully...'),
              onOk() {
                setDetectedFields([]);
                detectedField = [];
                if (
                  props.location.state &&
                  props.location.state.addNewFieldFrom === 'MapsContainer'
                ) {
                  props.history.push(
                    URL_CONSTANTS.LANDING_FARM({
                      orgId: props.match.params.id,
                      farmId: property.id
                    })
                  );
                } else {
                  props.history.push(
                    URL_CONSTANTS.ALL_FIELDS({ orgId: props.match.params.id, farmId: property.id })
                  );
                }
              },
              zIndex: 99999,
              cancelButtonProps: {
                style: { display: 'none' }
              },
              okButtonProps: {
                style: {
                  background: `${isSessionId ? '#424242' : '#00B5D8'}`,
                  color: '#fff',
                  border: `1px solid ${isSessionId ? '#424242' : '#00B5D8'}`
                }
              }
            });
          } else {
            AmplitudeInstance.detectFieldEvents({
              event: 'detect_fields_error',
              org_id: get(props, 'match.params.id'),
              property_id: property.id,
              property_name: property.name,
              error: t('Some fields may not be saved due to overlapping boundaries'),
              page: 'Detect field'
            });
            confirm({
              content: t('Some fields may not be saved due to overlapping boundaries'),
              onOk() {
                setDetectedFields([]);
                detectedField = [];
                props.history.push(
                  URL_CONSTANTS.ALL_FIELDS({ orgId: props.match.params.id, farmId: property.id })
                );
              },
              zIndex: 99999,
              cancelButtonProps: {
                style: { display: 'none' }
              },
              okButtonProps: {
                style: {
                  background: `${isSessionId ? '#424242' : '#00B5D8'}`,
                  color: '#fff',
                  border: `1px solid ${isSessionId ? '#424242' : '#00B5D8'}`
                }
              }
            });
          }
        })
        .catch((error) => {
          AmplitudeInstance.detectFieldEvents({
            event: 'detect_fields_error',
            org_id: get(props, 'match.params.id'),
            property_id: property.id,
            property_name: property.name,
            error: error,
            page: 'Detect field'
          });
          setShowSpiner(false);
          let errorMsg =
            error.response && error.response.status === 500 && error.response.data
              ? error.response.data.error
              : '';
          if (errorMsg === '') {
            errorMsg = error.errors && error.errors instanceof Array ? error.errors[0].message : '';
          }
          if (errorMsg === '') {
            errorMsg = error && error instanceof Array ? error[0].message : '';
          }
          Modal.error({
            title: errorMsg,
            zIndex: 99999,
            okButtonProps: {
              style: {
                background: `${isSessionId ? '#424242' : '#00B5D8'}`,
                color: '#fff',
                border: `1px solid ${isSessionId ? '#424242' : '#00B5D8'}`
              }
            }
          });
        });
    }
  };

  const getCurrentZoomLevel = (zoomLevel) => {
    setCurrentZoomLevel(zoomLevel);
  };

  return PageLayout({
    content: (
      <div>
        {showSpiner ? <Loader /> : null}
        <ErrorModal
          visible={!!errorMessage}
          message={errorMessage}
          okText={t('Ok')}
          onOk={() => {
            setErrorMessage('');
          }}
        />
        {iff(isDataLoading, <Loader />)}
        {iff(
          showConfirmModal === true,
          <ConfirmModal
            visible={showConfirmModal}
            title={t('Cancel Detect your fields')}
            onOk={() => {
              setShowConfirmModal(false);
              handleRedirectionForCancel();
              AmplitudeInstance.detectFieldEvents({
                event: 'cancel_detect_fields_clicked',
                page: 'Detect field'
              });
            }}
            onCancel={() => setShowConfirmModal(false)}>
            <p>{t('All of your progress will be lost.')}</p>
            <p>{t('Are you sure you want to cancel?')}</p>
          </ConfirmModal>
        )}
        <Row>
          <StyledCol md={10} lg={10} xl={8} className='content-container gutter-row'>
            <StyledHeader>
              <Icon
                data-testid="detect-field-back-btn"
                onClick={onCancelClick}
                className='back-button'
                type='left'
                style={{ fontSize: '14px' }}
              />
              <PageHeader style={{ paddingTop: '0px', color: '#2F3031' }}>
                <p style={{ color: 'grey', display: 'inline' }}>{t('Detect fields')}</p>
              </PageHeader>
            </StyledHeader>
            <DetectFarmContainer style={{ minHeight: '80vh', overflowY: 'auto' }}>
              <Row>
                {detectedFields && detectedFields.length > 0 && (
                  <div id='detectedFieldList' data-testid="detectedFieldList">
                    <div>
                      {detectedFields.map((field, i) => (
                        <div
                          key={field.id}
                          style={{ border: '8px solid #E8EAED', padding: '12px' }}
                          data-testid={`detectedFieldListField-${i}`}>
                          <div
                            style={{
                              float: 'left'
                            }}>
                            Field Name: field_{field.id}
                          </div>
                          <br />
                          <div
                            style={{
                              float: 'left'
                            }}>
                            Area: {field.properties.area_ha.toFixed(2)} ha
                          </div>
                          <div
                            tabIndex="0"
                            role="button"
                            style={{ float: 'right', marginTop: '-2%', cursor: 'pointer' }}
                            onClick={() => removeDetectedFields(field)}
                            data-testid={`delete-detectedFieldListField-${i}`}>
                            x
                          </div>
                          <br />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {currentZoomLevel <= 12 && (
                  <div style={{ textAlign: 'center', color: 'red', fontSize: '14px' }}>
                    <span data-testid="zoom-in-selected-warning">{t('Please zoom in to select the fields.')}</span>
                  </div>
                )}
                {detectedFields && detectedFields.length == 0 && (
                  <div style={{ textAlign: 'center' }}>
                    <span data-testid="no-field-selected-warning">{t('No Fields Selected')}</span>
                  </div>
                )}
              </Row>
              <Row
                type='flex'
                justify='end'
                align='bottom'
                style={{ marginRight: '10px', marginTop: '10px' }}>
                <Col span={6} style={{ marginRight: '10px' }}>
                  <StyledButtonsCancelSubmit type='secondary' onClick={onCancelClick} data-testid="cancel-btn">
                    {t('Cancel')}
                  </StyledButtonsCancelSubmit>
                </Col>
                <Col span={7} style={{ marginRight: '10px' }}>
                  <StyledSaveButton
                    style={{ width: '100%', height: '40px', marginLeft: '15px' }}
                    className='btn-green'
                    onClick={addDetectFields}
                    loading={isSave && isLoading}
                    disabled={detectedFields.length <= 0}
                    data-testid="save-btn">
                    {t('Save')}
                  </StyledSaveButton>
                </Col>
              </Row>
            </DetectFarmContainer>
          </StyledCol>
          <Col md={14} lg={14} xl={16} className='gutter-row'>
            <Maps
              focusBounds={focusBounds}
              hasFocusBounds={true}
              reload={true}
              zoomLevel={stateZoom ? +stateZoom : 14}
              focusLat={+lat}
              focusLong={+long}
              detectFields={'detectFields'}
              detectedFields={detectedFields}
              getCurrentZoomLevel={getCurrentZoomLevel}
              handelDetectSelectedField={handleFieldClick}
            />
          </Col>
        </Row>
      </div>
    )
  });
};

DetectFields.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      farmId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired
    })
  }),
  history: PropTypes.object
};

const mapStateToProps = (state, ownProps) => ({
  state
});

export const DetectFieldsComponent = connect(mapStateToProps, null)(DetectFields);
