import { notification } from 'antd/lib';

export const openNotificationWithIcon = (type, message, placement) => {
  notification[type]({
    message: message,
    style: {
      height: '100%'
    },
    placement
  });
};
