import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

import { WrapperCreateEditFarmComponent } from './CreateEditFarmComponent';
import {
  getPropertyById,
  getLandingFarmLoading,
  getLandingFarmError,
  isLandingFarmSuccess
} from './selectors';

import { saveEditedProperty, saveCreatedProperty, fetchOrgs } from '../../actions';

const Wrapper = (props) => {
  const { id, farmId } = useParams();
  return (
    <WrapperCreateEditFarmComponent
      {...props}
      history={useHistory()}
      orgId={id}
      farmId={farmId}
      property={farmId && getPropertyById(props.state, id, farmId)}
    />
  );
};
Wrapper.propTypes = {
  state: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    isLoading: getLandingFarmLoading(state),
    error: getLandingFarmError(state),
    state,
    success: isLandingFarmSuccess(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveEditedProperty: (property) => dispatch(saveEditedProperty(property)),
  saveCreatedProperty: (property) => dispatch(saveCreatedProperty(property)),
  fetchOrgs: () => dispatch(fetchOrgs())
});

export const WrapperComponent = connect(mapStateToProps, mapDispatchToProps)(Wrapper);
