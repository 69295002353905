export const getLocationFromBrowser = async () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      // navigator.geolocation.getCurrentPosition((position) => {
      //   resolve(position.coords);
      // }, reject);
    } else {
      reject('Geolocation is not supported by this browser!');
    }
  });
};
