import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Tooltip, Button } from 'antd/lib';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  StyledButton,
  StyledSpan,
  StyledDrawer,
  StyledInput,
  StyledError,
} from '../../../utils/styles.theming';
import TipIcon from '../../../public/Tip.svg';
import '../../../components/settings/Component.css';
import '../Modal/filterModel.css';

export const NewSideBarFilterModel = (props) => {
  const { t } = useTranslation();
  const [selectedMonthYear, setSelectedMonthYear] = useState('');
  const [selectedSource, setSelectedSource] = useState([]);
  const [zoneNumber, setZoneNumber] = useState('');
  const [removeOutlierPixel, setRemoveOutlierPixel] = useState('');
  const [zonesErrorMsg, setZonesErrorMsg] = useState(null);
  const [removeOutlierPixelErrorMsg, setRemoveOutlierPixelErrorMsg] = useState(null);
  const checkActivationAPICallRequest = useRef(null);

  useEffect(() => {
    if (checkActivationAPICallRequest?.current) {
      checkActivationAPICallRequest.current.cancel();
    }
    if (props.filterPopupData) {
      let startDateYear = '2013';
      let startDateMonth = 'July'
      let endDateYear = 2023;
      let endDateMonth = 'May';
      if (endDateMonth !== 'Invalid Date') {
        setSelectedMonthYear(`${t(startDateMonth)} ${startDateYear} - ${t(endDateMonth)} ${endDateYear}`);
      } else {
        setSelectedMonthYear(`- -  - -`);
      }
      setSelectedSource(props.filterPopupData.selectedSource);
      defaultInitialization();
    }
  }, []);

  /**
   * Reinitialization with default value when navigating 2nd time without refresh
   */
  const defaultInitialization = () => {
    setZoneNumber('');
    setRemoveOutlierPixel('');
    setZonesErrorMsg(null);
    setRemoveOutlierPixelErrorMsg(null);
  };

  const getZoneNumber = (event) => {
    setZoneNumber(event.target.value);
    isValidImageAndZoneData(event.target.value);
  };

  const isValidImageAndZoneData = (zoneNo) => {
    const latestZoneNumber = zoneNo ? Number(zoneNo) : Number(zoneNumber);
    if (
      latestZoneNumber === 0 ||
      latestZoneNumber < 2 ||
      latestZoneNumber > 7 ||
      isNaN(latestZoneNumber)
    ) {
      setZonesErrorMsg('Zone number should be between 2 to 7');
      return false;
    }
    if (latestZoneNumber % 1 !== 0) {
      setZonesErrorMsg('Zone number should not be a decimal');
      return false;
    }
    setZonesErrorMsg(null);
    return true;
  };

  const isValidRemoveOutlierPixel = (removeOutlier, eventType) => {
    const latestRemoveOutlierPixel = removeOutlier
      ? Number(removeOutlier)
      : Number(removeOutlierPixel);
    if (latestRemoveOutlierPixel === 0 && eventType !== undefined) {
      setRemoveOutlierPixelErrorMsg('Outlier pixel is required');
      return false;
    }
    if (
      latestRemoveOutlierPixel % 1 !== 0 ||
      latestRemoveOutlierPixel < 0 ||
      (latestRemoveOutlierPixel === 0 && eventType !== undefined) ||
      isNaN(latestRemoveOutlierPixel)
    ) {
      setRemoveOutlierPixelErrorMsg('Outlier pixel should be a non zero positive number');
      return false;
    }
    if (latestRemoveOutlierPixel < 100 || latestRemoveOutlierPixel > 1000) {
      setRemoveOutlierPixelErrorMsg(t('Remove small zones in square meters between 100 and 1000'));
      return false;
    }
    setRemoveOutlierPixelErrorMsg(null);
    return true;
  };

  const onCreateZones = () => {
    if (isValidImageAndZoneData()) {
      props.getproductivityZonesAPIResponse(props.imageSelected.history, Number(zoneNumber), Number(removeOutlierPixel));
    }
  };

  const onShowBoxPlot = () => {
    props.showBoxPlot();
  }

  const getRemoveOutlierPixel = (event) => {
    setRemoveOutlierPixel(event.target.value);
    isValidRemoveOutlierPixel(event.target.value);
  };

  const onRemoveOutlierPixel = () => {
    if (isValidRemoveOutlierPixel(removeOutlierPixel, 'RemoveOutlier')) {
      setRemoveOutlierPixelErrorMsg(null);
      props.getproductivityZonesAPIResponse(props.imageSelected.history, Number(zoneNumber), Number(removeOutlierPixel));
    }
  };

  const downloadZonesData = () => {
    let payload = {
      zone: Number(zoneNumber),
      removeOutlier: Number(removeOutlierPixel),
      outputFormat: "shapefile"
    }
    props.downloadZonesData(payload);
  };

  const closeImageListPopup = () => {
    setActiveDropDownFile('');
  };

  return (
    <Button className='sidebar_filter' onClick={closeImageListPopup}>
      <StyledDrawer
        title={props.selectedFieldItemName ?? ''}
        placement='right'
        onClose={props.closeSideBar}
        closable={true}
        keyboard={false}
        maskClosable={false}
        visible={props.isNewSidebarVisible}>
        <Row>
          <Col span={24}>
            <div className='mzma-map_box_left mzma-p-t-10'>
              <div className='mzma-selected_fields'>
                <div className='mzma-border_bootom mzma-p-l-20'>
                  <div className='mzma_filter_Section'>
                  </div>
                  <div className='mzma-selected_date_source'>
                    <div className='mzma-selectd_source mzma-top_border'>
                      <StyledSpan data-testid="source-label">{t('Source')}</StyledSpan>
                      <StyledSpan data-testid="source-value">{selectedSource?.toString()}</StyledSpan>
                    </div>
                    <div className='mzma-selectd_date mzma-bottom_border'>
                      <StyledSpan data-testid="source-date-label">{t('Date')}</StyledSpan>
                      <StyledSpan data-testid="source-date-value">{selectedMonthYear}</StyledSpan>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className={zonesErrorMsg ? 'export_Remove_section_zone_errorMsg' : 'export_Remove_section'}>
          <StyledButton
            title={t('Box Plot')}
            style={{ width: '150px', margin: '10px' }}
            onClick={onShowBoxPlot}
            data-testid="box-plot-btn">
            {t('Box Plot')}
          </StyledButton>
          <div className={'export_Remove_btns'}>
            <div className='num_zones'>
              <StyledInput
                type='text'
                className={
                  zonesErrorMsg?.includes('Zone')
                    ? 'num_zone_input outlayerPixe_error'
                    : 'num_zone_input'
                }
                placeholder={t('No.of zones')}
                onChange={getZoneNumber}
                value={zoneNumber}
                data-testid="no-of-zones-input"
              />
              {zonesErrorMsg && (
                <div className='error_tip'>
                  <img className='mzma-img_size' alt="mzma-img_size" src={TipIcon} />
                  <StyledError data-testid="validation-error">{t(zonesErrorMsg)}</StyledError>
                </div>
              )}
            </div>
            <StyledButton
              title={t('Regenerate')}
              style={{ marginRight: '7px', marginBottom: '10px' }}
              onClick={onCreateZones}
              data-testid="regenerate-btn">
              {t('ReGenerate')}
            </StyledButton>
          </div>
          <div>
            <div className='export_Remove_btns'>
              <div className='num_zones'>
                <StyledInput
                  type='text'
                  className={
                    removeOutlierPixelErrorMsg
                      ? 'num_zone_input outlayerPixe_error'
                      : 'num_zone_input'
                  }
                  placeholder={t('Number in Square Meter')}
                  onChange={getRemoveOutlierPixel}
                  value={removeOutlierPixel}
                  data-testid="no-in-sq-meter"
                />
                {removeOutlierPixelErrorMsg && (
                  <div className='mzma-m-t-neg50 error_tip '>
                    <img className='mzma-img_size' alt="mzma-img_size" src={TipIcon} />
                    <StyledError data-testid="remove-validation-error">{t(removeOutlierPixelErrorMsg)}</StyledError>
                  </div>
                )}
              </div>
              <Tooltip
                data-testid="tooltip-show"
                title={t(
                  'Delete polygons or small areas.The entered value indicates the minimum surface area (in m2) accepted for a polygon or area.'
                )}
                placement='topRight'
                trigger='hover'>
                <StyledButton
                  className='shapefile_btn'
                  title={t('Remove outlier')}
                  style={{ marginRight: '7px' }}
                  onClick={onRemoveOutlierPixel}
                  data-testid="remove-outlier-btn">
                  {t('Remove outliers')}
                </StyledButton>
              </Tooltip>
            </div>
            <div className='export_Remove_btns exort_btn_group'>
              <StyledButton onClick={downloadZonesData} data-testid="download-btn">{t('Download')}</StyledButton>
            </div>
          </div>
        </div>
      </StyledDrawer>
    </Button>
  );
};

NewSideBarFilterModel.propTypes = {
  selectedFieldItemName: PropTypes.string.isRequired,
  isNewSidebarVisible: PropTypes.bool.isRequired,
  filterPopupData: PropTypes.shape({
    selectedYear: PropTypes.string.isRequired,
    selectedSource: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedDate: PropTypes.any.isRequired,
    isImgListToggleOn: PropTypes.bool.isRequired
  }),
  filterResponseData: PropTypes.shape({
    assets: PropTypes.arrayOf(PropTypes.object)
  }),
  imageSelected: PropTypes.shape({
    history: PropTypes.any.isRequired
  }),
  getproductivityZonesAPIResponse: PropTypes.func.isRequired,
  showBoxPlot: PropTypes.func.isRequired,
  downloadZonesData: PropTypes.func.isRequired,
  closeSideBar: PropTypes.func.isRequired
};
