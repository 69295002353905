import styled from 'styled-components';
import { Input, Col } from 'antd/lib';
import get from 'lodash/get';

export const StyledCol = styled(Col)`
  height: calc(100vh - 64px);
  overflow-y: auto;
  ::-webkit-scrollbar-thumb {
    background: ${(props) => get(props, 'theme.colors.scrollbarThumbBgColor')};
    -webkit-box-shadow: inset 0 3px 2px 6px
      ${(props) => get(props, 'theme.colors.scrollbarThumbBgColor')} !important;
    cursor: pointer;
  }
`;

export const StyledHeader = styled.div`
  height: 56px;
  background: #f5f8fc;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 20px;
  font-weight: 500;
  padding: 15px;
  cursor: pointer;

  .back-button {
    border-radius: 20px;
    color: ${(props) => get(props, 'theme.colors.backArrowColor')};
    padding: 10px;
    /* margin-right: 29px; */
    cursor: pointer;
    /* width: 40px;
    height: 40px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center; */
    &:hover {
      background-color: ${(props) => get(props, 'theme.colors.backArrowBgColor')};
      color: white;
      text-align: center;
      /* border: 1px solid #00a0be; */
      border-radius: 50%;
    }
  }

  .ant-page-header {
    font-size: 20px;
    font-weight: 500;
    padding: 0;
  }
  .ant-page-header-content {
    padding: 0;
  }
`;

export const FarmContainer = styled.div`
  background-color: #fff;
  padding: 20px 24px 30px 24px;
  min-height: 91.5vh;
`;

export const ErrorMessage = styled.div`
  float: right;
  color: red;
  margin: 24px;
  .icon {
    height: 16px;
    width: 16px;
    border: 1px solid red;
    background-color: red;
    border-radius: 20px;
    color: white;
    text-align: center;
    font-weight: 600;
    line-height: 1.3;
    float: left;
    margin: 3 20px;
  }
`;

export const StyledDrawingTools = styled.p`
  font-family: ${(props) => get(props, 'theme.font.family')};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #2f3031;
  margin-bottom: 0px;
`;

export const StyledAdjustedSize = styled(Input)`
  .ant-input-group-addon {
    color: #2f3031;
    background: #e8eaed;
  }
  .ant-input-group .ant-input {
    color: ${(props) => get(props, 'theme.colors.buttonHoverColor')} !important;
    &:focus {
      color: ${(props) => get(props, 'theme.colors.buttonHoverColor')};
      border: ${(props) => get(props, 'theme.colors.inputFocusBorder')};
    }
  }
`;

export const StyledInputFildName = styled(Input)`
  font-family: ${(props) => get(props, 'theme.font.family')};
  color: ${(props) => get(props, 'theme.colors.buttonHoverColor')} !important;
  &:focus {
    color: ${(props) => get(props, 'theme.colors.buttonHoverColor')};
    border: ${(props) => get(props, 'theme.colors.inputFocusBorder')};
  }
`;

export const StyledCount = styled.span`
  color: #c6cacc;
  font-weight: normal;
  font-style: normal;
  padding-left: 5px;
`;
export const DetectFarmContainer = styled.div`
  background-color: #fff;
  padding: 20px 24px 30px 24px;
  min-height: 91.5vh;
`;
