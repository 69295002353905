import { getOrgById } from '../../selectors/organizationSelectors';

export const getProperties = (state) => state.properties || {};

export const getLandingFarmLoading = (state) => {
  return getProperties(state).loading;
};

export const getLandingFarmError = (state) => {
  return getProperties(state).error;
};

export const isLandingFarmSuccess = (state) => {
  return getProperties(state).success;
};

export const getPropertyById = (state, id, farmId) => {
  const org = getOrgById(state, { id });
  let property = {};
  property = org?.properties?.find((prop) => prop.id === farmId) || null;
  return property;
};
